import htmlparser from 'html-react-parser';

const IntroText = ({ assignment }: { assignment: any }) => {
  return (
    <>
      <div className='space-y-4 text-sm mt-4 md:mt-10'>
        <div className='md:flex md:justify-between  font-semibold '>
          <p className=' text-lg'> {assignment.title}</p>
          <div className='text-primary flex flex-wrap md:space-x-1 '>
            {assignment.skills}
          </div>
        </div>
      </div>

      <div className='space-y-4 mt-4 md:mt-10'>
        <p className='font-semibold sm:text-lg'>Assignment Guidelines</p>
        <div>
          {assignment.guideline && (
            <div className='px-4 prose prose-sm max-w-none '>
              {htmlparser(assignment.guideline)}
            </div>
          )}
        </div>

        <p className='font-semibold'>Resources</p>
        <div>
          {assignment.resources && (
            <div className='px-4 prose prose-sm max-w-none '>
              {htmlparser(assignment.resources)}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default IntroText;
