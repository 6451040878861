import { AiOutlineArrowLeft } from 'react-icons/ai';

const DomainLeftArrow = ({ onClick }) => {
  return (
    <button
      onClick={() => onClick()}
      className='bg-primary-light hover:bg-primary h-10 w-10 rounded-full absolute left-3 md:left-1 lg:left-0 flex items-center justify-center cursor-pointer transition-all ease-in-out duration-300'
    >
      <AiOutlineArrowLeft />
    </button>
  );
};

export default DomainLeftArrow;
// lg: left - 3;
