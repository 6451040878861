import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { supabase } from 'config/supabaseCOnfig';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Question = ({ number, userId }: { number: number; userId: string }) => {
  const [submissionData, setSubmissionData] = useState<any>(null);
  useEffect(() => {
    async function getStatus() {
      const { data, status } = await supabase
        .from('submissions')
        .select()
        .match({ user_id: userId, exercise_id: number, bootcamp_id: 2 });
      if (status === 200 && data?.length) {
        setSubmissionData(data);
      }
    }
    getStatus();
  }, [number, userId]);

  return (
    <div>
      {submissionData?.length ? (
        <CheckBadgeIcon className='w-8 text-primary font-bold inline-block' />
      ) : (
        <div className='text-right'>
          <Link
            to={`/analytics-lab/python/ide/${number}`}
            className='bg-primary text-white shadow rounded px-4 py-2'
          >
            Solve Question
          </Link>
        </div>
      )}
    </div>
  );
};

export default Question;
