import React from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';

const TaskCard = ({
  setTaskView,
  assignment,
  teamId,
}: {
  setTaskView: any;
  assignment: any;
  teamId: any;
}) => {
  const isLocked = !(assignment.task_no === 1 || teamId);
  return (
    <div>
      <div
        onClick={() => setTaskView(assignment)}
        className={classNames(
          'col-span-12 mt-4 cursor-pointer border-2 hover:border-primary bg-white pb-4 hover:bg-primary-lightest',
          { 'grayscale pointer-events-none': isLocked }
        )}
      >
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670239433/new%20b2b/image_323_lt9pjm.svg'
          alt='banner'
          className='object-cover w-full border-l-4 border-yellow-400'
        />
        <div className=' p-4 grid grid-cols-12 gap-x-4 gap-y-2'>
          <div className='col-span-12 md:col-span-8'>
            <p className='text-primary font-semibold'>
              Due Date:{' '}
              {assignment.deadline &&
                format(new Date(assignment.deadline), 'PPpp')}
            </p>
            <div className='flex items-center space-x-2 mt-2'>
              <div className='h-3 w-3 bg-primary rounded-full '></div>
              <p className='text-sm  font-medium w-[90%] md:w-full mx-auto'>
                {assignment.title}
              </p>
            </div>
            {assignment.skills}
          </div>

          <div className='col-span-12 md:col-span-4 md:text-right'>
            <button className=' text-primary border border-primary bg-white rounded-lg px-2 py-2  mx-auto  text-center text-xs font-semibold mt-4 sm:mt-0 '>
              {assignment.teamSubmission?.id ? 'Completed' : 'Complete Now'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskCard;
