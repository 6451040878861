import HowTo from './HowTo';

const infos = [
  {
    id: 1,
    heading: 'Sign up and Create Your Profile',
    image:
      'https://res.cloudinary.com/belong/image/upload/v1658213085/uploaded_resources/person_orlaoo.png',
  },
  {
    id: 2,
    heading: ' Build your skill proof with Capstones',
    image:
      'https://res.cloudinary.com/belong/image/upload/v1658213114/uploaded_resources/star_ug2ayx.png',
  },
  {
    id: 3,
    heading: 'Get Your Belong Score',
    image:
      'https://res.cloudinary.com/belong/image/upload/v1658213144/uploaded_resources/file_hb4dsa.png',
  },
  {
    id: 4,
    heading: 'Secure Internships & Jobs',
    image:
      'https://res.cloudinary.com/belong/image/upload/v1658213167/uploaded_resources/check-mark_cxa4jc.png',
  },
];

const HowToInfo = () => {
  return (
    <div className='space-y-8 '>
      {infos.map(({ id, heading, image }) => {
        return <HowTo key={id} heading={heading} image={image} />;
      })}
    </div>
  );
};

export default HowToInfo;
