import FetchExercise from 'features/skillLab/ide';

const Index = () => {
  return (
    <div className='grid grid-cols-12 gap-6'>
      <div className='col-span-4'>
        <p className='text-xl'>Practice Lab</p>
        <p className='text-primary text-sm font-semibold my-2'>Questions</p>
        <div className='space-y-4 mt-6'>
          <p className='bg-primary-light p-4 font-semibold rounded'>
            <span>1. </span>
            Reverse harry Potter
          </p>
          <p className='p-4'>
            <span>2. </span>
            Round Round Circle
          </p>
          <p className='p-4'>
            <span>3. </span>
            Sum Them!!!
          </p>
          <p className='p-4'>
            <span>4. </span>
            Even Or Odd
          </p>
          <p className='p-4'>
            <span>5. </span>
            Series
          </p>
          <p className='p-4'>
            <span>6. </span>
            Expressions
          </p>
          <p className='p-4'>
            <span>7. </span>
            Which is Greater??
          </p>
          <p className='p-4'>
            <span>8. </span>
            Mathematics All Together!!
          </p>
        </div>
      </div>
      <div className='col-span-8'>
        <FetchExercise show={false} />
      </div>
    </div>
  );
};

export default Index;
