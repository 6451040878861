import SkillCard from 'components/bootcamp-home/layout/SkillCard';

const skillData = [
  {
    id: 1,
    skill: 'Python  programming',
    question: '0/15 Questions',
    level: 'NO BADGES',
    barColor: '#F5844C',
    link: '/analytics-lab/python',
    logo: (
      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M13.0164 2C10.8193 2 9.03825 3.72453 9.03825 5.85185V8.51852H15.9235V9.25926H5.97814C3.78107 9.25926 2 10.9838 2 13.1111V18.8889C2 21.0162 3.78107 22.7407 5.97814 22.7407H8.27322V19.4815C8.27322 17.3542 10.0543 15.6296 12.2514 15.6296H19.5956C21.4547 15.6296 22.9617 14.1704 22.9617 12.3704V5.85185C22.9617 3.72453 21.1807 2 18.9836 2H13.0164ZM12.0984 6.74074C12.8589 6.74074 13.4754 6.14378 13.4754 5.40741C13.4754 4.67103 12.8589 4.07407 12.0984 4.07407C11.3378 4.07407 10.7213 4.67103 10.7213 5.40741C10.7213 6.14378 11.3378 6.74074 12.0984 6.74074Z'
          fill='url(#paint0_linear_5700_9339)'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M18.9834 30.0005C21.1805 30.0005 22.9616 28.276 22.9616 26.1487V23.482H16.0763V22.7413H26.0217C28.2188 22.7413 29.9998 21.0167 29.9998 18.8894V13.1116C29.9998 10.9843 28.2188 9.25977 26.0217 9.25977H23.7266V12.519C23.7266 14.6464 21.9455 16.3709 19.7485 16.3709H12.4042C10.5451 16.3709 9.03809 17.8301 9.03809 19.6301V26.1487C9.03809 28.276 10.8192 30.0005 13.0162 30.0005H18.9834ZM19.9015 25.2598C19.1409 25.2598 18.5244 25.8567 18.5244 26.5931C18.5244 27.3295 19.1409 27.9264 19.9015 27.9264C20.662 27.9264 21.2785 27.3295 21.2785 26.5931C21.2785 25.8567 20.662 25.2598 19.9015 25.2598Z'
          fill='url(#paint1_linear_5700_9339)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_5700_9339'
            x1='12.4809'
            y1='2'
            x2='12.4809'
            y2='22.7407'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#327EBD' />
            <stop offset='1' stop-color='#1565A7' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_5700_9339'
            x1='19.519'
            y1='9.25977'
            x2='19.519'
            y2='30.0005'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#FFDA4B' />
            <stop offset='1' stop-color='#F9C600' />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  {
    id: 2,
    skill: 'Data Structures',
    question: '0/15 Questions',
    level: 'NO BADGES',
    barColor: '#F5844C',
    link: '/analytics-lab/ds',
    logo: (
      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M20 12H12V20H20V12Z' fill='#6E9CE7' />
        <path
          d='M17.3332 2.66699H14.6665V8.00033H17.3332V2.66699Z'
          fill='#4C7FD1'
        />
        <path
          d='M11.9997 2.66699H9.33301V8.00033H11.9997V2.66699Z'
          fill='#4C7FD1'
        />
        <path d='M22.6667 2.66699H20V8.00033H22.6667V2.66699Z' fill='#4C7FD1' />
        <path d='M17.3332 24H14.6665V29.3333H17.3332V24Z' fill='#325691' />
        <path d='M11.9997 24H9.33301V29.3333H11.9997V24Z' fill='#325691' />
        <path d='M22.6667 24H20V29.3333H22.6667V24Z' fill='#325691' />
        <path d='M24 14.6673V17.334H29.3333V14.6673H24Z' fill='#325691' />
        <path d='M24 20.0003V22.667H29.3333V20.0003H24Z' fill='#325691' />
        <path d='M24 9.33333V12H29.3333V9.33333H24Z' fill='#325691' />
        <path
          d='M2.6665 14.6673V17.334H7.99984V14.6673H2.6665Z'
          fill='#4C7FD1'
        />
        <path
          d='M2.6665 20.0003V22.667H7.99984V20.0003H2.6665Z'
          fill='#4C7FD1'
        />
        <path d='M2.6665 9.33333V12H7.99984V9.33333H2.6665Z' fill='#4C7FD1' />
        <path
          d='M6.6665 6.66699V25.3337H25.3332V6.66699H6.6665ZM22.6665 22.667H9.33317V9.33366H22.6665V22.667Z'
          fill='#6E9CE7'
        />
        <path d='M12 20H20L16 16L12 20Z' fill='#4C7FD1' />
        <path d='M16 16L20 20V12L16 16Z' fill='#325691' />
      </svg>
    ),
  },
  {
    id: 3,
    skill: 'Data Libraries',
    question: '0/10 Questions',
    level: 'NO BADGES',
    link: '/analytics-lab/dl',
    barColor: '#F5844C',
    logo: (
      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clip-path='url(#clip0_5700_9385)'>
          <path
            d='M31.9999 17.4062V31.9997H29.89L26.3735 24.703L29.89 17.4062H31.9999Z'
            fill='#FFD820'
          />
          <path
            d='M29.8902 17.4062H17.4067V31.9997H29.8902V17.4062Z'
            fill='#FFE777'
          />
          <path
            d='M14.5937 17.4062V31.9997H12.4837L8.96729 24.703L12.4837 17.4062H14.5937Z'
            fill='#FE5F1A'
          />
          <path
            d='M12.4835 17.4062H0V31.9997H12.4835V17.4062Z'
            fill='#FE834D'
          />
          <path
            d='M31.9999 0V14.5934H29.89L26.3735 7.29669L29.89 0H31.9999Z'
            fill='#FE5F1A'
          />
          <path d='M29.8902 0H17.4067V14.5934H29.8902V0Z' fill='#FE834D' />
          <path
            d='M14.5937 0V14.5934H12.4837L8.96729 7.29669L12.4837 0H14.5937Z'
            fill='#FFD820'
          />
          <path d='M12.4835 0H0V14.5934H12.4835V0Z' fill='#FFE777' />
        </g>
        <defs>
          <clipPath id='clip0_5700_9385'>
            <rect width='32' height='32' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    id: 4,
    skill: 'EDA',
    question: '0/5 Questions',
    level: 'NO BADGES',
    link: '/analytics-lab/eda',
    barColor: '#F5844C',
    logo: (
      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11.8457 2.8139H15.1147V9.6009H11.8468L11.8457 2.8139ZM11.8457 16.7559H15.1147V23.5449H11.8468L11.8457 16.7559ZM11.8457 11.5829H15.1147V14.7859H11.8468L11.8457 11.5829ZM6.59475 8.3939H9.86275V30.9569H6.59375L6.59475 8.3939ZM16.9888 22.3569H20.2577V29.1439H16.9888V22.3569ZM16.9888 8.4019H20.2577V15.1889H16.9888V8.4019ZM16.9888 17.1719H20.2577V20.3749H16.9888V17.1719ZM22.1308 1.0459H25.3997V23.6089H22.1308V1.0459Z'
          fill='#767676'
        />
      </svg>
    ),
  },
];

const Skills = () => {
  return (
    <div className='grid grid-cols-12 gap-4'>
      <div className='col-span-12 '>
        <p className='mb-2 text-2xl font-medium'> Skills</p>
        <p className='text-[#B0B0B0] font-semibold mb-4'>
          You have earned 4 skill Badges based on your performance{' '}
        </p>
        <div className='my-6 grid lg:grid-cols-3 gap-6'>
          {skillData.map((skills) => {
            return (
              <SkillCard
                skill={skills.skill}
                logo={skills.logo}
                question={skills.question}
                level={skills.level}
                link={skills.link}
                barColor={skills.barColor}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Skills;
