import React from 'react';
import QuizStatus from './QuizStatus';

interface allProps {
  setShowQuiz: any;
  enrollData: any;
  capstoneData: any;
  totalQuestions: any;
}

const QuizAndScore = (props: allProps) => {
  const enrollData = props.enrollData;
  const capstoneData = props.capstoneData;

  const allSkills: any[] = [];

  capstoneData.tasks.forEach((task: any) => {
    task.skillId.forEach((skill: any) => {
      if (
        skill &&
        !allSkills.some((someSkill: any) => someSkill._id === skill._id)
      ) {
        allSkills.push(skill);
      }
    });
  });

  return (
    <div className='px-1 sm:px-6'>
      <p className='font-semibold lg:text-xl'>Quiz & Score</p>
      <div className='flex'>
        <div className='hidden md:block my-8 border-4 border-primary h-11 w-12 rounded-full '></div>
        <div className='border bg-gray-50  rounded w-full md:ml-10 my-4 md:my-8 p-2 sm:p-4'>
          <div className='grid grid-cols-12 md:grid-flow-col py-2 sm:py-4 border-b gap-2 md:gap-0'>
            <div className='col-span-12 md:col-span-8 md:flex md:justify-between sm:pr-4 space-y-2 md:space-y-0'>
              <p className='font-semibold lg:text-lg mb-4 sm:mb-0'>
                Capstone Quiz*
              </p>
              <div>
                {enrollData?.capstoneAssessments?.timeTaken && (
                  <p className='rounded-3xl font-medium md:font-semibold text-sm md:text-base  border text-primary bg-white border-primary px-2 py-1 text-center'>
                    Capstone Performance-{' '}
                    {enrollData?.capstoneAssessments?.percentage}%
                  </p>
                )}
              </div>
              <p className='text-center'>{props.totalQuestions} mins</p>
            </div>
            <div className='col-span-12 md:col-span-4  md:border-l  border-primary '>
              <div className='text-center'>
                <p className='rounded-3xl  sm:ml-6 font-medium md:font-semibold text-sm md:text-base border text-primary bg-white border-primary px-4 py-1'>
                  {capstoneData?.domainId?.title}
                </p>
              </div>
            </div>
          </div>
          <div className='grid grid-cols-12  md:grid-flow-col py-4 border-b gap-4 md:gap-0'>
            <div className='col-span-12 md:col-span-8  sm:pr-4'>
              <p className='mb-4 text-sm md:text-base'>
                The quiz evaluates your capstone domain knowledge & skill
                expertise
              </p>
              <div className='flex flex-wrap gap-2 sm:gap-4'>
                {capstoneData.skillId.map((skill: any) => {
                  return (
                    <p
                      key={skill._id}
                      className='border  mb-2 md:mb-0 bg-primary-lightest font-semibold rounded-2xl py-1 px-2 text-sm'
                    >
                      {skill.title}
                    </p>
                  );
                })}
              </div>
            </div>
            <div className='col-span-12 md:col-span-4  md:border-l  border-primary'>
              <div className='px-4 md:px-8 md:mt-4'>
                <QuizStatus
                  setShowQuiz={props.setShowQuiz}
                  enrollData={enrollData}
                  capstoneData={capstoneData}
                />

                {/* <p className='text-primary font-semibold text-xs md:text-base'>
                  Unlock in 18 Hours
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizAndScore;
