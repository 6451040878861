import React, { useState } from 'react';
import TextEditorQuill from './TextEditorQuill';
import htmlparser from 'html-react-parser';
import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { supabase } from 'config/supabaseCOnfig';
import { useGetUserQuery } from 'components/profile/userSlice';

interface allProps {
  taskData: any;
  enrollData: any;
  setSelectedTask: Function;
  setReFetch: any;
  teamData: any;
}

const TaskSolution = (props: allProps) => {
  const { data: userData } = useGetUserQuery();
  const userId = userData?._id;
  const [loading, setLoading] = useState(false);
  const capstone_id = props.enrollData.capstone_id;
  const [userSoution, setUserSolution] = useState('');

  const taskId = props.taskData?._id;
  const enrollCurrentTask = props.enrollData?.task_submissions?.filter(
    (sub: any) => {
      return sub.task_id === taskId;
    }
  );
  const initialtext =
    enrollCurrentTask && enrollCurrentTask.length
      ? enrollCurrentTask[0].solution
      : '';

  const [isOpen, setIsOpen] = useState(false);

  const submitSolution = async () => {
    setLoading(true);
    const { data, status } = await supabase
      .from('capstone_submissions')
      .select()
      .match({ team_id: props.teamData.id, capstone_id });
    if (status === 200 && data?.length) {
      const submissionData = data[0]?.task_submissions || [];
      const currentTaskData = submissionData.find(
        ({ task_id }: any) => task_id === taskId
      );
      if (!currentTaskData) {
        const finalSub = submissionData.concat({
          task_id: taskId,
          solution: userSoution,
          submit_time: Date.now(),
          user_id: userId,
        });
        await supabase
          .from('capstone_submissions')
          .update({ task_submissions: finalSub })
          .match({ id: data[0]?.id });
      }
      props.setReFetch((pre: any) => !pre);
      props.setSelectedTask(false);
    }
    if (status === 200 && !data?.length) {
      const subData = {
        team_id: props.teamData.id,
        capstone_id,
        task_submissions: [
          {
            task_id: taskId,
            solution: userSoution,
            submit_time: Date.now(),
            user_id: userId,
          },
        ],
      };
      await supabase.from('capstone_submissions').insert(subData);
      props.setReFetch((pre: any) => !pre);
      props.setSelectedTask(false);
    }
  };

  return (
    <div className='mt-4'>
      {initialtext ? (
        <div className='bg-white p-1 sm:p-4'>
          <div className='font-semibold text-lg'>Your Answer</div>
          <div className='prose prose-sm max-w-none lg:text-lg mt-6'>
            {htmlparser(initialtext)}
          </div>
        </div>
      ) : (
        <>
          <div className='font-semibold text-lg'>Your Solution</div>
          <TextEditorQuill
            initialtext={initialtext}
            setUserSolution={setUserSolution}
          />
          <div className='text-center  mt-16'>
            <button
              onClick={() => setIsOpen(true)}
              disabled={loading}
              className={classNames(
                'px-8 py-1 rounded-lg mt-4',
                { 'bg-gray-300 pointer-events-none': userSoution?.length < 12 },
                { ' bg-primary-light': userSoution?.length >= 12 }
              )}
            >
              {loading ? 'Loading' : 'Submit'}
            </button>
          </div>
        </>
      )}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        as='div'
        className='relative z-10'
      >
        <div className='fixed inset-0 overflow-y-auto overflow-x-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <div>
              <Dialog.Panel className='rounded bg-white m-4 p-4'>
                <div className='text-left w-64 sm:w-96'>
                  <div className='font-bold'>
                    Are you sure you want to submit the task?
                  </div>
                  <div className='mt-4'>
                    You can not make any changes in your task once you have
                    submitted the task
                  </div>
                  <div className='mt-6 px-4 py-3 flex justify-end'>
                    <button
                      type='button'
                      className='inline-flex w-full justify-center rounded-md bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm'
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type='button'
                      className='mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-primary-light px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                      onClick={() => {
                        submitSolution();
                        setIsOpen(false);
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default TaskSolution;
