import StartScreenCard from '../layout/StartScreenCard';
import AptStartScreenCta from '../aptitude/AptStartScreenCta';
import { useNavigate } from 'react-router-dom';

const AptStartTest = () => {
  const navigate = useNavigate();

  const aptitudeCardProps = {
    stepNo: 'STEP 1',
    stepColor: 'text-black',
    bgColor: 'bg-secondary',
    heading: 'Aptitude Test',
    score: '0 / 40',
    time: ' : 20 Minutes',
    text: 'Communication, Analytical Ability, Reasoning and Self-Awareness & Learnability',
    color: 'to-bgIndigo',
  };
  const domainCardProps = {
    stepNo: 'STEP 2',
    stepColor: 'text-gray-400',
    bgColor: 'bg-gray-400',
    heading: 'Domain Test',
    score: ' 0 / 50',
    time: ' : 30 to 60 Minutes',
    text: 'Industry, Domain and Skills ( Minimum 3 and Maximum 6)',
    color: 'to-bgCyan',
  };

  const content = (
    <div className='grid grid-cols-12'>
      <div className='lg:col-span-5 col-span-12 -mx-2'>
        <div className='p-2 bg-gradient-to-b from-[#193257] to-[#0A1626] '>
          <div>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1661327845/b2c/Aptitude_Test_eoqbq4.png'
              alt='belong'
              className='mx-auto mt-8 object-contain h-96'
            />
          </div>
          <p className='text-white text-center text-lg md:text-2xl font-bold mb-12 md:mb-16 md:w-3/5 mx-auto mt-10 md:mt-14'>
            Unlock Job & Internships with
            <br />
            <span className='text-primary'> Belong Score </span>
          </p>
        </div>
      </div>
      <div className='lg:col-span-6 col-span-12 mx-auto'>
        <AptStartScreenCta />
        <div className='grid md:grid-cols-2 grid-cols-1'>
          <StartScreenCard {...aptitudeCardProps} />
          <StartScreenCard {...domainCardProps} />
        </div>
        <div className='text-center'>
          <button
            disabled
            onClick={() => {
              navigate('aptitude-quiz');
            }}
            className='font-manrope lg:mt-10 mt-6 text-primary font-bold text-xl border-2 border-[#878787]/50 lg:px-10 px-6 py-2 rounded-xl '
          >
            Start Now
          </button>
        </div>
      </div>
    </div>
  );

  return content;
};

export default AptStartTest;
