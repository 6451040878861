import classNames from 'classnames';
import React, { useState } from 'react';
import useCapstoneTestResponsesContext from 'hooks/useCapstoneTestResponsesContext';
// import { useFinishCapstoneTestMutation } from '../../CapstoneQuizSlice';
import { supabase } from 'config/supabaseCOnfig';
import axios from 'axios';
import { API_URL } from 'config/API_LINKS';
import { useGetUserQuery } from 'components/profile/userSlice';

const ShowQuestion: React.FC<{
  setShowScores: any;
  setShowQuiz: any;
  setReFetch: any;
}> = ({ setShowQuiz, setReFetch }) => {
  const { state, dispatch } = useCapstoneTestResponsesContext();
  const questionToShow = state.responses[state.currentQuestion];
  // const [finishTest, { isLoading }] = useFinishCapstoneTestMutation();

  const { data: userData } = useGetUserQuery();
  const userId = userData?._id;
  const [isLoading, setLoading] = useState(false);
  // const navigate = useNavigate();
  async function finishTestFn() {
    setLoading(true);
    const { data, status } = await supabase
      .from('capstone_submissions')
      .select()
      .match({ id: state.enrollmentId });
    if (status === 200 && data?.length) {
      const quizData = data[0]?.quiz_submission || [];
      const currentuserData = quizData.find(
        ({ user_id }: any) => user_id === userId
      );
      const otherQuizData = quizData.filter((quiz: any) => {
        return quiz.user_id !== userId;
      });
      const quizScoreData = await axios.post(
        API_URL + '/enrollments/capstone/check-test',
        {
          capstoneId: data[0]?.capstone_id,
          responses: state.responses,
        }
      );

      const finalQuizData = otherQuizData.concat({
        ...currentuserData,
        responses: state.responses,
        ...quizScoreData?.data?.data,
        finish_time: Date.now(),
      });
      await supabase
        .from('capstone_submissions')
        .update({ quiz_submission: finalQuizData })
        .match({ id: state.enrollmentId });
    }
    setReFetch((pre: any) => !pre);
    setShowQuiz(false);
  }

  return (
    <div>
      <p className='font-semibold p-4'>
        <span className='text-secondary'>{`${
          state.currentQuestion + 1
        }. `}</span>
        {`${questionToShow.question}`}
      </p>
      <div className='grid grid-flow-col auto-cols-fr gap-4 m-2 sm:m-4'>
        {questionToShow.image?.map((imageName: any) => {
          return (
            <img
              key={imageName}
              className='rounded shadow h-full'
              src={imageName}
              alt='Question'
            />
          );
        })}
      </div>
      {questionToShow.options.map((option: any) => {
        return (
          <div
            onClick={() => {
              dispatch({
                type: 'UPDATE_OPTIONS',
                payload: { option: option._id, value: !option.selected },
              });
            }}
            className={classNames(
              'm-4 rounded py-2 px-4',
              {
                'bg-primary-light': option.selected,
              },
              {
                'bg-gray-100': !option.selected,
              }
            )}
            key={option._id}
          >
            {option.option}
          </div>
        );
      })}
      <div className='flex justify-between mx-4 mt-6 text-primary font-semibold'>
        <div>
          <button
            onClick={() => {
              dispatch({
                type: 'UPDATE_CURRENT_QUESTION',
                payload: state.currentQuestion - 1,
              });
            }}
            className={classNames(
              'border border-gray-400 px-8 py-1 rounded-xl shadow bg-white',
              {
                hidden: !state.currentQuestion,
              }
            )}
          >
            Previous
          </button>
        </div>

        <button
          onClick={() => {
            dispatch({
              type: 'UPDATE_CURRENT_QUESTION',
              payload: state.currentQuestion + 1,
            });
          }}
          className={classNames(
            'border border-gray-400 px-8 py-1 rounded-xl shadow bg-white',
            { hidden: state.currentQuestion + 1 === state.totalQuestions }
          )}
        >
          Next
        </button>

        <button
          onClick={() => {
            finishTestFn();
          }}
          disabled={isLoading}
          className={classNames(
            'border border-gray-400 px-8 py-1 rounded-xl shadow bg-white',
            { hidden: state.currentQuestion + 1 < state.totalQuestions }
          )}
        >
          {isLoading ? 'Submitting' : 'Finish'}
        </button>
      </div>
    </div>
  );
};

export default ShowQuestion;
