import { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { Link } from 'react-scroll';
// import {
//   useGetMyCapstonesQuery,
//   useUpdateEnrollmentsMutation,
// } from '../capstoneSlice';
interface allProps {
  data: any;
  enrollData: any;
}

const EnrolNow = (props: allProps) => {
  // const [enrollLoading, setEnrollLoading] = useState(false);
  // const capstoneId = props.data._id;

  // const [enroll] = useUpdateEnrollmentsMutation();
  // const { data: enrollments = [] } = useGetMyCapstonesQuery({
  //   enrollmentType: 'Capstone',
  // });
  // const enrollCapstone = () => {
  //   setEnrollLoading(true);
  //   enroll({ capstoneId })
  //     .unwrap()
  //     .finally(() => {
  //       setEnrollLoading(false);
  //     });
  // };
  const [showPopup, setShowPopup] = useState(false);
  const taskDone = props.enrollData?.task_submissions?.length || 0;
  const totalTask = props.data?.tasks?.length || 4;
  const progress = Math.min(Math.ceil((taskDone * 100) / totalTask), 100);

  return (
    <div className='mt-2 p-4 border-b-2 border-gray-200'>
      <div className='md:flex justify-between'>
        <div className='flex space-x-4 md:space-x-6'>
          <div className='text-primary'>About</div>
          <Link
            className=' hover:text-primary cursor-pointer'
            activeClass='active'
            to='project-section'
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            Project
          </Link>
          <Link
            className=' hover:text-primary cursor-pointer '
            activeClass='active'
            to='tasks-section'
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            Tasks & Skills
          </Link>
        </div>
        <div>
          {props.enrollData ? (
            <div className='w-24 sm:w-48 mt-2 md:mt-0  bg-gray-200 h-8 rounded dark:bg-gray-700 '>
              {progress > 0 ? (
                <div
                  className='bg-primary h-8 text-xs font-medium  text-blue-100 text-center py-2 leading-none rounded flex items-center justify-center'
                  style={{ width: `${progress}%` }}
                >
                  {progress}%
                </div>
              ) : (
                <div className='text-center py-1'> 0 % </div>
              )}
            </div>
          ) : (
            ''
            // <button
            //   onClick={() => {
            //     enrollments.length ? setShowPopup(true) : enrollCapstone();
            //   }}
            //   className='bg-primary text-white px-2 md:px-6 py-1 rounded'
            //   disabled={enrollLoading}
            // >
            //   {enrollLoading ? 'Loading...' : 'Enrol Now'}
            // </button>
          )}
        </div>
      </div>
      <Transition appear show={showPopup} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          onClose={() => {
            setShowPopup(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <div className='rounded-md bg-blue-50 p-4'>
                    <div className='flex'>
                      <div className='flex-shrink-0'>
                        <InformationCircleIcon
                          className='h-5 w-5 text-blue-400'
                          aria-hidden='true'
                        />
                      </div>
                      <div className='ml-3 flex-1 md:flex md:justify-between'>
                        <p className='text-sm text-blue-700'>
                          You have already enrolled in a capstone
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default EnrolNow;
