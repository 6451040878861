import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput } from 'components/layout/FormElement';
import { supabase } from 'config/supabaseCOnfig';

const criterias = [
  {
    id: 1,
    heading: 'Problem Statement Comprehension and Analysis',
    text: 'Ability to construct the problem statement, identify the various challenges and structure the holistic view',
    Required: 'Analytical and Critical Thinking',
    scoreId: 'problemStatementAnalysis',
  },
  {
    id: 2,
    heading: 'Potential Solutions Evaluation',
    text: 'Ability to conduct research, identify approaches and evaluate potential solutions with insightful explanation',
    Required: 'Research and Creative Problem Solving',
    scoreId: 'potentialSolutionsEvaluation',
  },
  {
    id: 3,
    heading: 'Solution Startegy and Implementation',
    text: 'Ability to review logic/reasoning, examines feasibility of solution and weigh impacts of solution',
    Required: 'Domain Knowledge and Skill Applicability',
    scoreId: 'solutionStrategy',
  },
  {
    id: 4,
    heading: 'Challenge Addressal',
    text: 'Ability to proactively identify the challenge in the implementation and suggest & revise the solution ',
    Required: 'Commitment',
    scoreId: 'challangeAddressal',
  },
  {
    id: 5,
    heading: 'Outcome Evaluation',
    text: 'Ability to conclude results relative to the problem defined with thorough considerations on need for further work',
    Required: 'Domain Expertise',
    scoreId: 'outcomeEvaluation',
  },
];

const UploadScore = ({
  evaluateAssignmentData,
  setEvaluateAssignmentData,
  teamId,
}: any) => {
  return (
    <div>
      <div className='mt-4 md:mt-6'>
        <div className='sm:flex justify-between '>
          <p className='font-semibold text--light text-lg'>Score & Feedback</p>
          {/* <p className='text-white text-sm px-4 py-1 mt-2 sm:mt-0 max-w-fit rounded-2xl bg-primary-light font-medium '>
            Project Performance - {score?.total}%
          </p> */}
        </div>
        <Formik
          initialValues={{
            problemStatementAnalysis: 0,
            potentialSolutionsEvaluation: 0,
            solutionStrategy: 0,
            challangeAddressal: 0,
            outcomeEvaluation: 0,
          }}
          validationSchema={Yup.object({
            problemStatementAnalysis: Yup.number()
              .min(0, 'Min value 0.')
              .max(20, 'Max value 20.')
              .required(),
            potentialSolutionsEvaluation: Yup.number()
              .min(0, 'Min value 0.')
              .max(20, 'Max value 20.')
              .required(),
            solutionStrategy: Yup.number()
              .min(0, 'Min value 0.')
              .max(20, 'Max value 20.')
              .required(),
            challangeAddressal: Yup.number()
              .min(0, 'Min value 0.')
              .max(20, 'Max value 20.')
              .required(),
            outcomeEvaluation: Yup.number()
              .min(0, 'Min value 0.')
              .max(20, 'Max value 20.')
              .required(),
          })}
          onSubmit={async (values) => {
            const total =
              values.problemStatementAnalysis +
              values.potentialSolutionsEvaluation +
              values.solutionStrategy +
              values.challangeAddressal +
              values.outcomeEvaluation;

            const scoreData = { ...values, total };
            await supabase
              .from('assignment_submissions')
              .update({ score: scoreData, evaluate_team_id: teamId })
              .match({ id: evaluateAssignmentData.id });
            setEvaluateAssignmentData(null);
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className='my-4 lg:my-8 '>
                {criterias.map((criteria) => {
                  return (
                    <div
                      key={criteria.id}
                      className='grid grid-cols-12 md:grid-flow-col gap-2 mb-4 sm:mb-2   text-textColor-lighter'
                    >
                      <div className='col-span-12 md:col-span-6 border rounded-lg  p-2 md:p-4 '>
                        <p className='font-medium mb-2 text-black'>
                          {criteria.heading}
                        </p>
                        <p className='text-sm'>{criteria.text}</p>
                      </div>
                      <div className='col-span-10 md:col-span-4  border rounded-lg  p-2 md:p-4'>
                        <div className='flex items-center h-full '>
                          <p className='text-sm sm:text-base'>
                            {criteria.Required}
                          </p>
                        </div>
                      </div>
                      <div className='col-span-2  border rounded-lg  p-2 '>
                        <div className='flex items-center justify-center h-full '>
                          <TextInput
                            name={criteria.scoreId}
                            type='number'
                            placeholder='Score'
                          />
                          /20
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className='text-center'>
                <button
                  type='submit'
                  className='border border-gray-400 rounded-lg text-primary font-semibold px-8 py-2 shadow-lg'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Processing...' : 'Submit Evaluation'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UploadScore;
