import Select from 'react-select';
import { customStyles } from '../../../constants/customStyles';
import { languageOptions } from '../../../constants/languaageOptions';

const LanguagesDropdown = ({ onSelectChange }: { onSelectChange: any }) => {
  return (
    <Select
      placeholder={`Filter By Category`}
      options={languageOptions}
      styles={customStyles}
      defaultValue={languageOptions[37]}
      onChange={(selectedOption) => onSelectChange(selectedOption)}
    />
  );
};

export default LanguagesDropdown;
