import React from 'react';
import { Link } from 'react-router-dom';

const JourneySection = () => {
  return (
    <div className='grid grid-cols-12 bg-primary/5 lg:mt-12 md:px-24 mt-4 p-2 -mx-4  md:-mx-20'>
      <div className='md:col-span-7 col-span-12 p-4 md:py-16 '>
        <p className='md:font-semibold font-medium lg:text-2xl text-lg  leading-normal'>
          Belong Score is the Way to{' '}
          <span className='text-primary hidden md:inline'>
            <br />
            Your Perfect Job and Internship
          </span>
          <span className='text-primary md:hidden'>
            Your Perfect Job and Internship
          </span>
        </p>
        <div className='my-6'>
          <Link
            to='/login'
            className='text-primary bg-textColor-lighter  px-12 py-2 rounded-lg font-bold lg:text-xl shadow'
          >
            Try For Free
          </Link>
        </div>
      </div>
      <div className='md:col-span-5 col-span-12  object-contain'>
        <div className='w-1/2 md:w-full lg:w-1/2 mx-auto'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1658213799/uploaded_resources/journeyGirl_toykgr.png'
            alt='girl'
          />
        </div>
      </div>
    </div>
  );
};

export default JourneySection;
