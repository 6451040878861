import { useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import { DomainTestContext } from '../../../../../hooks/useDomainTestContext';
import TestCardBar from '../../layout/TestCardBar';
// import TestStepsBar from '../../layout/TestStepsBar';

const Index = () => {
  interface domainQuizType {
    areaId: string;
    type: string;
    skillIds: string[];
    questionCountForEachSkill: number;
  }

  const paramsObj: domainQuizType = {
    areaId: '60bf4d6763dbccf5133ea710',
    type: 'B2C DOMAIN',
    skillIds: [''],
    questionCountForEachSkill: 10,
  };

  function reducer(state: domainQuizType, action: any) {
    switch (action.type) {
      case 'UPDATE_AREA_ID':
        return { ...state, areaId: action.payload };
      case 'UPDATE_SKILL_IDS':
        return { ...state, skillIds: action.payload };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, paramsObj);

  return (
    <DomainTestContext.Provider value={{ state, dispatch }}>
      <div className='lg:px-32 px-4 lg:py-16 py-8'>
        <div className='border pb-8'>
          <TestCardBar />
          {/* <TestStepsBar step={step} setStep={setStep} /> */}
          <Outlet />
        </div>
      </div>
    </DomainTestContext.Provider>
  );
};

export default Index;
