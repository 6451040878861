import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { supabase } from 'config/supabaseCOnfig';
import { useGetUserQuery } from 'components/profile/userSlice';

const ColabSubmissions = ({ exercise }: any) => {
  const exerciseId = exercise.id;
  const [link, setLink] = useState('');
  const [image, setImage] = useState('');
  const [message, setMessage] = useState('');
  const { data: userData } = useGetUserQuery();
  const userId = userData?._id;
  const [isLoading, setLoading] = useState(false);
  const [getDataLoading, setGetDataLoading] = useState(true);

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('team')
        .select('users, id');
      const myTeam = fetchedData?.find(({ users }) => users.includes(userId));
      const teamId = myTeam?.id;
      if (teamId) {
        const { data, status } = await supabase
          .from('submissions')
          .select()
          .match({ team_id: teamId, exercise_id: exerciseId });
        if (status === 200 && data?.length) {
          setImage(data[0]?.image || '');
          setLink(data[0]?.link || '');
        }
        setGetDataLoading(false);
      }
      if (!teamId) {
        setGetDataLoading(false);
      }
    };
    setDataFn();
  }, [exerciseId, userId]);

  const submitFn = async () => {
    setLoading(true);
    setMessage('');
    const { data: fetchedData } = await supabase
      .from('team')
      .select('users, id');
    const myTeam = fetchedData?.find(({ users }) => users.includes(userId));

    const teamId = myTeam?.id;
    if (!teamId) {
      setMessage('Something went wrong! No team');
    }
    if (teamId) {
      const { data, status } = await supabase
        .from('submissions')
        .select()
        .match({ team_id: teamId, exercise_id: exerciseId });
      if (status === 200 && data?.length) {
        setMessage('Exercise already submmited');
        const submissionsId = data[0].id;
        const alreadySubmission_number = data[0].submission_number || 0;
        const updateData = {
          submission_number: alreadySubmission_number + 1,
          image,
          user_id: userId,
          link: link,
        };
        const { error } = await supabase
          .from('submissions')
          .update(updateData)
          .match({ id: submissionsId });
        if (!error) {
          setMessage('Submissions updated successfully');
        }
      }
      if (status === 200 && !data?.length) {
        const submissionData = {
          team_id: teamId,
          exercise_id: exerciseId,
          user_id: userId,
          bootcamp_id: 1,
          image,
          status: 'PASSED',
          submission_number: 1,
          link: link,
        };
        const { error } = await supabase
          .from('submissions')
          .insert(submissionData);

        if (error) {
          setMessage('Something went wrong!');
        } else {
          setMessage('Submissions done successfully');
        }
      }
    }
    setLoading(false);
  };

  const uploadImage = async (file: any) => {
    try {
      const { data: fetchedData } = await supabase
        .from('team')
        .select('users, id');
      const myTeam = fetchedData?.find(({ users }) => users.includes(userId));

      const teamId = myTeam?.id;
      const fileName = `sub${teamId}-${exerciseId}-${file.name}`;
      const { error } = await supabase.storage
        .from('offline-bootcamp')
        .upload('images/' + fileName, file);

      if (error) {
        console.log('Error uploading image:', error);
        //@ts-ignore
        if (error?.error === 'Duplicate') {
          const { error: updateError } = await supabase.storage
            .from('offline-bootcamp')
            .update('images/' + fileName, file);
          if (updateError) {
            console.log('Error updateing image:', error);
            return;
          }
        }
      }
      const publicUrl = supabase.storage
        .from('offline-bootcamp')
        .getPublicUrl('images/' + fileName);

      console.log('Public URL:', publicUrl);
      setImage(publicUrl.data?.publicUrl || '');
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      uploadImage(file);
    }
  };

  return (
    <div>
      {!getDataLoading && (
        <>
          <div className='my-4 w-full'>
            <input
              className='focus:outline-none w-full sm:w-1/2 border-2 border-black  rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white mt-2'
              value={link}
              placeholder='Colab Link'
              onChange={(e) => setLink(e.target.value)}
            />
            <p className='text-xs text-red-500 mt-2'>
              {' '}
              {!link && 'Colab Link is Required'}{' '}
            </p>
          </div>
          <div>
            <p className='mb-2'>Add screenshot of the graph:</p>
            <input type='file' accept='image/*' onChange={handleImageUpload} />
            <p className='text-xs text-red-500 mt-2'>
              {' '}
              {!image && 'screenshot image is Required'}{' '}
            </p>
          </div>
          {image && (
            <div className='text-center'>
              <img src={image} alt='solution' className='mx-auto' />
            </div>
          )}
          <div className='text-center'>
            <button
              onClick={() => submitFn()}
              disabled={!link || !image || isLoading}
              className={classNames(
                'mt-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0',
                !link || !image ? 'opacity-50' : ''
              )}
            >
              {isLoading ? 'Processing...' : 'Submit'}
            </button>
            <div className='mt-2'> {message} </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ColabSubmissions;
