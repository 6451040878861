import CapstoneCertificateCard from 'components/bootcamp-home/layout/CapstoneCertificateCard';

const capCertificateData = [
  {
    id: 1,
    logo: <img src='images/new/o.png' alt='pic' />,
    title: 'Mortgage Prediction',
  },
  {
    id: 2,
    logo: <img src='images/new/o2.png' alt='pic' />,
    title: 'Enhancing revenue via lead qualification',
  },
];

const CertificateSection = () => {
  return (
    <div className='space-y-10 grid grid-cols-12 hidden'>
      {/* capstone certificate */}
      <div className='col-span-12'>
        <p className='mb-2 text-2xl font-medium'> Capstone Certificates</p>
        {/* <p className='text-[#B0B0B0] font-semibold mb-4'>
          No capstone certificates{' '}
        </p> */}
        {/* when NO capstone certificate */}
        {/* <div className='bg-primary-lighter rounded-md md:flex space-x-8 my-6 p-4 items-center'>
          <div>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M3 18C4.24237 18 5.25 16.9924 5.25 15.75V8.25C5.25 5.03363 7.5015 2.3475 10.5131 1.6695C10.506 1.614 10.5 1.55775 10.5 1.5C10.5 0.671625 11.1716 0 12 0C12.8284 0 13.5 0.671625 13.5 1.5C13.5 1.55775 13.494 1.614 13.4869 1.6695C16.4985 2.3475 18.75 5.03363 18.75 8.25V15.75C18.75 16.9924 19.7576 18 21 18C21.8284 18 22.5 18.6716 22.5 19.5C22.5 20.3284 21.8284 21 21 21H15C15 22.6567 13.6567 24 12 24C10.3433 24 9 22.6567 9 21H3C2.17163 21 1.5 20.3284 1.5 19.5C1.5 18.6716 2.17163 18 3 18ZM12 0.75C11.586 0.75 11.25 1.086 11.25 1.5C11.25 1.515 11.2552 1.52812 11.2564 1.54312C11.5005 1.51612 11.7484 1.5 12 1.5C12.2516 1.5 12.4995 1.51612 12.7436 1.54312C12.7448 1.52812 12.75 1.515 12.75 1.5C12.75 1.086 12.414 0.75 12 0.75ZM18 8.25C18 4.93612 15.3139 2.25 12 2.25C8.68612 2.25 6 4.93612 6 8.25V12.75H18V8.25ZM18 13.5H6V15H18V13.5ZM12 23.25C13.2424 23.25 14.25 22.2424 14.25 21H9.75C9.75 22.2424 10.7576 23.25 12 23.25ZM3 20.25H21C21.414 20.25 21.75 19.914 21.75 19.5C21.75 19.086 21.414 18.75 21 18.75C19.3432 18.75 18 17.4067 18 15.75H6C6 17.4067 4.65675 18.75 3 18.75C2.586 18.75 2.25 19.086 2.25 19.5C2.25 19.914 2.586 20.25 3 20.25Z'
                fill='#1B91D4'
              />
            </svg>
          </div>
          <p className='font-semibold text-primary'>
            Start solving capstones to earn Certificates{' '}
          </p>
        </div> */}
        {/* when capstone certificate */}
        <p className='text-[#B0B0B0] font-semibold mb-4'>
          You have earned 2 capstone certificates
        </p>
        <div className='my-6 grid lg:grid-cols-2 gap-6'>
          {capCertificateData.map((cer) => {
            return (
              <CapstoneCertificateCard logo={cer.logo} title={cer.title} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CertificateSection;
