import { useEffect, useState } from 'react';
import QuickInfo from './QuickInfo';

import { supabase } from '../../config/supabaseCOnfig';
import { useGetUserQuery } from 'components/profile/userSlice';
import TaskStatus from './TaskStatus';

const Index = () => {
  const [fetchError, SetFetchError] = useState<any>(null);
  const [assignments, SetAssignments] = useState<any>([]);
  const [submissions, SetSubmissions] = useState<any>([]);
  const [teamData, setTeamData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [teamLoading, setTeamLoading] = useState(true);
  const [reFetch, setReFetch] = useState(false);

  const { data: userData } = useGetUserQuery();
  const userId = userData?._id;

  useEffect(() => {
    const fetchAssignments = async () => {
      const { data, error } = await supabase.from('assignments').select('*');
      if (error) {
        SetFetchError('Could not find data');
        SetAssignments(null);
      }
      if (data) {
        SetAssignments(data);
        SetFetchError(null);
      }
    };
    fetchAssignments();
  }, []);

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('team')
        .select('users, id')
        .match({ bootcamp_id: 2 });
      const myTeam = fetchedData?.find(({ users }) => users.includes(userId));
      setTeamData(myTeam);
      if (!myTeam) {
        SetFetchError('Team Not Found');
      }
      setTeamLoading(false);
    };
    if (userId) {
      setDataFn();
    }
  }, [userId]);

  const teamId = teamData?.id;

  useEffect(() => {
    const fetchSubmission = async () => {
      const userSubResponse = await supabase
        .from('assignment_submissions')
        .select()
        .match({ user_id: userId });
      const userSubData = userSubResponse?.data || [];
      const userError = userSubResponse.error;

      let teamSubData = [];
      let teamError = null;

      if (teamId) {
        const teamResponse = await supabase
          .from('assignment_submissions')
          .select()
          .match({ team_id: teamId });

        teamSubData = teamResponse?.data || [];
        teamError = teamResponse.error;
      }

      const fetchedData = userSubData.concat(teamSubData);
      const error = userError || teamError;

      if (error) {
        SetFetchError('Could not find submission data');
        SetSubmissions([]);
        setLoading(false);
      } else if (fetchedData) {
        SetSubmissions(fetchedData);
        SetFetchError(null);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    if (!teamLoading) {
      fetchSubmission();
    }
  }, [userId, reFetch, teamId, teamLoading]);

  return (
    <div>
      {fetchError && <p>{fetchError}</p>}
      <QuickInfo />
      {!loading && (
        <TaskStatus
          teamId={teamId}
          submissions={submissions}
          assignments={assignments}
          setReFetch={setReFetch}
        />
      )}
    </div>
  );
};

export default Index;
