import React from 'react';
import AnalyticLabSkills from './AnalyticLabSkills';
import CapstoneLevelSection from './CapstoneLevelSection';

const Index = () => {
  return (
    <div className='space-y-10 lg:px-6'>
      <AnalyticLabSkills />
      <CapstoneLevelSection />
    </div>
  );
};

export default Index;
