import { useGetOnboardingQuery } from 'components/dashboard/panel/panelSlics';
import Loading from 'components/global/layout/Loading';
import BootcampCertificateSection from './BootcampCertificateSection';
import BelongScore from './BelongScore';
import BootcampScore from './BootcampScore';

const Index = () => {
  const { data, isLoading, isSuccess } = useGetOnboardingQuery();
  return (
    <div>
      {isLoading && <Loading />}
      {isSuccess && (
        <div className='space-y-10'>
          {/* belong score */}
          <BelongScore data={data} />
          <BootcampScore />
          <BootcampCertificateSection />
        </div>
      )}
    </div>
  );
};

export default Index;
