import React from 'react';
import UnlockedCard from './UnlockedCard';

const Unlocked = ({ capstoneData }: any) => {
  return (
    <div>
      <div className='my-6 grid lg:grid-cols-2 gap-6'>
        {capstoneData.map((captone: any, index: any) => {
          return <UnlockedCard capstoneData={captone} key={index} />;
        })}
      </div>
    </div>
  );
};

export default Unlocked;
