import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import Loading from 'components/global/layout/Loading';
import { useGetUserQuery } from 'components/profile/userSlice';
import Category from './Category';
import { supabase } from 'config/supabaseCOnfig';
import { useState } from 'react';

const DataStructures = () => {
  const { data: userData, isLoading } = useGetUserQuery();
  const userId = userData?._id;
  const [allBasicQuestionSolved, setAllBasicQuestionSolved] = useState(false);
  const [allIntermediateQuestionSolved, setAllIntermediateQuestionSolved] =
    useState(false);
  const [allAdvancedQuestionSolved, setAllAdvancedQuestionSolved] =
    useState(false);

  const basicQuestions = [52, 53, 54, 55, 56];
  const intermediateQuestions = [47, 48, 49, 50, 51];
  const advancedQuestions = [57, 58, 59, 60, 61];

  // functions for basic questions
  async function fetchData() {
    const { data } = await supabase
      .from('submissions')
      .select()
      .match({ user_id: userId, bootcamp_id: 2 })
      .in('exercise_id', basicQuestions);
    if (data && data.length === 5) {
      setAllBasicQuestionSolved(true);
    } else {
      setAllBasicQuestionSolved(false);
    }
  }

  if (userId) {
    fetchData();
  }

  // functions for Intermediate questions
  async function fetchIntermediateData() {
    const { data } = await supabase
      .from('submissions')
      .select()
      .match({ user_id: userId, bootcamp_id: 2 })
      .in('exercise_id', intermediateQuestions);
    if (data && data.length === 5) {
      setAllIntermediateQuestionSolved(true);
    } else {
      setAllIntermediateQuestionSolved(false);
    }
  }

  if (userId) {
    fetchIntermediateData();
  }

  // functions for Advanced questions
  async function fetchAdvancedData() {
    const { data } = await supabase
      .from('submissions')
      .select()
      .match({ user_id: userId, bootcamp_id: 2 })
      .in('exercise_id', advancedQuestions);
    if (data && data.length === 5) {
      setAllAdvancedQuestionSolved(true);
    } else {
      setAllAdvancedQuestionSolved(false);
    }
  }

  if (userId) {
    fetchAdvancedData();
  }

  const questions = [
    {
      question: 'You are given a constant array A.',
      number: 52,
    },
    {
      question:
        'Given an array A of N integers and also given two integers B and C',
      number: 53,
    },
    {
      question:
        'Given an array A and an integer B. A pair(i, j) in the array is a good...',
      number: 54,
    },
    {
      question:
        'Given an array A of N integers. Construct prefix sum of the array in the given array itself. ',
      number: 55,
    },
    {
      question: 'Given an integer array A of size N and an integer B',
      number: 56,
    },
  ];
  const questionsIntermediate = [
    {
      question:
        'Given a list A. You have some integers given in the list B.For the i-th number,',
      number: 47,
    },
    {
      question:
        'Given an integer list A of size N, find the first repeating element in it.',
      number: 48,
    },
    {
      question: 'Given a number A, find if it is COLORFUL number or not.',
      number: 49,
    },
    {
      question:
        'Given two integer lists, A and B of size N and M, respectively. ',
      number: 50,
    },
    {
      question: 'Given an Array of integers B, and a target sum A.',
      number: 51,
    },
  ];

  const questionsAdvanced = [
    {
      question:
        'Given an array of integers A and an integer B, find and return the minimum number',
      number: 57,
    },
    {
      question:
        'Given an array of integers A, every element appears twice except for one.',
      number: 58,
    },
    {
      question: 'Given a number A, check if it is a magic number or not.',
      number: 59,
    },
    {
      question:
        'Given an array of integers A, find and return the peak element in it.',
      number: 60,
    },
    {
      question: 'Given an expression string A, examine whether the pairs...',
      number: 61,
    },
  ];

  const dataStructureLogo = (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M20 12H12V20H20V12Z' fill='#6E9CE7' />
      <path
        d='M17.3346 2.66699H14.668V8.00033H17.3346V2.66699Z'
        fill='#4C7FD1'
      />
      <path
        d='M12.0026 2.66699H9.33594V8.00033H12.0026V2.66699Z'
        fill='#4C7FD1'
      />
      <path d='M22.6667 2.66699H20V8.00033H22.6667V2.66699Z' fill='#4C7FD1' />
      <path d='M17.3346 24H14.668V29.3333H17.3346V24Z' fill='#325691' />
      <path d='M12.0026 24H9.33594V29.3333H12.0026V24Z' fill='#325691' />
      <path d='M22.6667 24H20V29.3333H22.6667V24Z' fill='#325691' />
      <path d='M24 14.6673V17.334H29.3333V14.6673H24Z' fill='#325691' />
      <path d='M24 20.0003V22.667H29.3333V20.0003H24Z' fill='#325691' />
      <path d='M24 9.33333V12H29.3333V9.33333H24Z' fill='#325691' />
      <path
        d='M2.66797 14.6673V17.334H8.0013V14.6673H2.66797Z'
        fill='#4C7FD1'
      />
      <path
        d='M2.66797 20.0003V22.667H8.0013V20.0003H2.66797Z'
        fill='#4C7FD1'
      />
      <path d='M2.66797 9.33333V12H8.0013V9.33333H2.66797Z' fill='#4C7FD1' />
      <path
        d='M6.66797 6.66699V25.3337H25.3346V6.66699H6.66797ZM22.668 22.667H9.33464V9.33366H22.668V22.667Z'
        fill='#6E9CE7'
      />
      <path d='M12 20H20L16 16L12 20Z' fill='#4C7FD1' />
      <path d='M16 16L20 20V12L16 16Z' fill='#325691' />
    </svg>
  );
  return (
    <div className='px-6'>
      <div className='flex justify-between'>
        <div>
          <p className='text-xl'>Data Structures</p>
          <p className='text-gray-400 text-xs mt-2'>Your skill Badges</p>
        </div>
        <div>
          <a href='/DS.pdf' download>
            <button className='py-2 px-8 border-primary border text-sm rounded shadow text-primary'>
              <DocumentArrowDownIcon className='inline-block w-6 mr-2' />
              Resources
            </button>
          </a>
        </div>
      </div>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <div className='mt-8 flex gap-4'>
            {/* badge 1 */}
            <div>
              {allBasicQuestionSolved ? (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat  object-shadow grid items-center'
                  style={{
                    backgroundImage:
                      'url("/images/skills/skill-bg-bronze.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    <div className='flex justify-center'>
                      {dataStructureLogo}
                    </div>
                    <p className='text-center mt-2 font-semibold'>
                      Data Structures
                    </p>
                    <img
                      src='/images/skills/stars-1.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              ) : (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat grayscale object-shadow grid items-center'
                  style={{
                    backgroundImage: 'url("/images/skills/skill-bg-0.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    <div className='flex justify-center'>
                      {' '}
                      {dataStructureLogo}
                    </div>
                    <p className='text-center mt-2 font-semibold'>
                      Data Structures
                    </p>
                    <img
                      src='/images/skills/stars-1.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              )}
            </div>
            {/* badge 2 */}
            <div>
              {allIntermediateQuestionSolved ? (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat  object-shadow grid items-center'
                  style={{
                    backgroundImage:
                      'url("/images/skills/skill-bg-silver.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    <div className='flex justify-center'>
                      {dataStructureLogo}
                    </div>
                    <p className='text-center mt-2 font-semibold'>
                      Data Structures
                    </p>
                    <img
                      src='/images/skills/stars-2.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              ) : (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat grayscale object-shadow grid items-center'
                  style={{
                    backgroundImage: 'url("/images/skills/skill-bg-0.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    <div className='flex justify-center'>
                      {' '}
                      {dataStructureLogo}
                    </div>
                    <p className='text-center mt-2 font-semibold'>
                      Data Structures
                    </p>
                    <img
                      src='/images/skills/stars-1.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              )}
            </div>
            {/* badge 3 */}
            <div>
              {allAdvancedQuestionSolved ? (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat  object-shadow grid items-center'
                  style={{
                    backgroundImage: 'url("/images/skills/skill-bg-gold.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    <div className='flex justify-center'>
                      {dataStructureLogo}
                    </div>
                    <p className='text-center mt-2 font-semibold'>
                      Data Structures
                    </p>
                    <img
                      src='/images/skills/stars-3.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              ) : (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat grayscale object-shadow grid items-center'
                  style={{
                    backgroundImage: 'url("/images/skills/skill-bg-0.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    <div className='flex justify-center'>
                      {' '}
                      {dataStructureLogo}
                    </div>
                    <p className='text-center mt-2 font-semibold'>
                      Data Structures
                    </p>
                    <img
                      src='/images/skills/stars-1.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <Category
              heading='Novice'
              questions={questions}
              locked={false}
              userId={userId}
            />
            <Category
              heading='Intermediate'
              questions={questionsIntermediate}
              locked={false}
              userId={userId}
            />
            <Category
              heading='Expert'
              questions={questionsAdvanced}
              locked={false}
              userId={userId}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DataStructures;
