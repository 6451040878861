import { supabase } from 'config/supabaseCOnfig';
import React, { useEffect, useState } from 'react';

const PeersEvaluate = ({
  teamId,
  assignment,
  setEvaluateAssignmentData,
}: any) => {
  const allTeams = [93, 94, 95, 96, 97, 98, 99];
  const myIndex = allTeams.indexOf(teamId);
  const taskNo = assignment.task_no;

  const indexOfEvaluateTeam = (myIndex + (taskNo % 5) + 1) % 7;
  const teamToEvaluate = allTeams[indexOfEvaluateTeam];

  const assignment_id = assignment.id;

  const [submissionData, setSubmissionData] = useState<any>(null);
  useEffect(() => {
    const fetchSubmission = async () => {
      const userSubResponse = await supabase
        .from('assignment_submissions')
        .select()
        .match({ team_id: teamToEvaluate, assignment_id });
      const userSubData = userSubResponse?.data || [];
      setSubmissionData(userSubData[0]);
    };

    fetchSubmission();
  }, [assignment_id, teamToEvaluate]);

  return (
    <div>
      {submissionData && !submissionData.score && (
        <div className='text-center'>
          <button
            onClick={() => setEvaluateAssignmentData(submissionData)}
            className='text-primary border border-primary bg-white rounded-lg px-2 py-2 mx-auto text-center text-xs font-semibold mt-4 sm:mt-0 '
          >
            Evaluate Peers
          </button>
        </div>
      )}
    </div>
  );
};

export default PeersEvaluate;
