import TaskCard from './TaskCard';

const Completed = ({
  setTaskView,
  assignments,
  teamId,
}: {
  setTaskView: any;
  assignments: any;
  teamId: any;
}) => {
  return (
    <div>
      {assignments && (
        <>
          <div className='grid grid-cols-1 gap-8 '>
            {assignments?.map((assignment: any) => {
              return (
                <TaskCard
                  key={assignment.id}
                  assignment={assignment}
                  setTaskView={setTaskView}
                  teamId={teamId}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Completed;
