import React, { useState } from 'react';
import BasicDetails from './BasicDetails';

const Index = () => {
  const [currentScreen, setCurrentScreen] = useState('BASICDETAILS');

  return (
    <div>
      {currentScreen === 'BASICDETAILS' && (
        <BasicDetails setCurrentScreen={setCurrentScreen} />
      )}
      {currentScreen === 'SUCCESS' && (
        <div className='mx-auto w-96 font-bold my-20 sm:my-40'>
          You have successfully registered for Career Bootcamp in Data Science
          July 2023. Check your email for further dates.
        </div>
      )}
    </div>
  );
};

export default Index;
