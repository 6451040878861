import { supabase } from 'config/supabaseCOnfig';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../config/API_LINKS';
import AssignmentReport from './AssignmentReport';

const UserSubmissions = ({ userId }: { userId: string }) => {
  const [data, setData] = useState<any>();
  const [user, setUser] = useState<any>();
  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabase
        .from('submissions')
        .select('*')
        .match({ bootcamp_id: '2', user_id: userId });
      if (error) {
        setData(null);
      }
      if (data) {
        setData(data);
      }
    };
    fetchData();

    const fetchUser = async () => {
      const user = await axios(API_URL + '/user', {
        params: { userId },
      });

      if (user) {
        // setAllUser user?.data?.data);
        setUser(user.data.data);
      }
    };

    fetchUser();
  }, [userId]);

  return (
    <div className='flex gap-8 mb-4'>
      <div className='w-1/3'>
        {user?.firstName} {user?.lastName}
        <p className='text-xs text-gray-400'>{user?.email}</p>
      </div>
      <div>
        {data && data.length} <span className='text-gray-400'>/ 40</span>
      </div>
      <div className='w-1/4'>
        <AssignmentReport userId={userId} />
      </div>
    </div>
  );
};

export default UserSubmissions;
