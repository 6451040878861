import React, { useEffect } from 'react';
import { useDomainTestScoreQuery } from '../QuizSlice';
import { Outlet, useNavigate } from 'react-router-dom';
import Loading from 'components/global/layout/Loading';

const CheckQuiz = () => {
  const { isLoading, isSuccess, data } = useDomainTestScoreQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess && Boolean(data)) {
      const dataScienceQuiz = data?.filter(
        (data: any) => data.domain._id === '60bf4d6763dbccf5133ea710'
      )[0];

      if (dataScienceQuiz) {
        navigate('/belong-score/domain/scorecard/60bf4d6763dbccf5133ea710');
      }
      //   else {
      //     navigate('/belong-score/domain/steps/select-skills');
      //   }
    }
  }, [isSuccess, data, navigate]);

  return <div>{isLoading ? <Loading /> : <Outlet />}</div>;
};

export default CheckQuiz;
