import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { supabase } from 'config/supabaseCOnfig';

const TaskOne = ({ setView, teamData }: any) => {
  const teamId = teamData.id;
  const [link, setLink] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const setDataFn = async () => {
      const { data, status } = await supabase
        .from('hackathon')
        .select()
        .match({ team_id: teamId });
      if (status === 200 && data?.length) {
        setLink(data[0]?.task1 || '');
      }
    };
    setDataFn();
  }, [teamId]);

  const submitFn = async () => {
    setLoading(true);
    setMessage('');

    const { data, status } = await supabase
      .from('hackathon')
      .select()
      .match({ team_id: teamId });
    if (status === 200 && data?.length) {
      const hackathonId = data[0].id;
      const updateData = {
        task1: link,
      };
      const { error } = await supabase
        .from('hackathon')
        .update(updateData)
        .match({ id: hackathonId });
      if (!error) {
        setMessage('Task updated successfully');
      }
    }
    if (status === 200 && !data?.length) {
      const hackathonData = {
        team_id: teamId,
        bootcamp_id: 2,
        task1: link,
      };
      const { error } = await supabase.from('hackathon').insert(hackathonData);

      if (error) {
        setMessage('Something went wrong!');
      } else {
        setMessage('Task done successfully');
      }
    }

    setLoading(false);
  };

  return (
    <div className='m-2'>
      <button
        className='px-4 py-2 bg-primary-light rounded-xl '
        onClick={() => setView('FULL')}
      >
        Back
      </button>
      <div className='flex justify-between'>
        <div className='font-bold text-xl'>
          Task - 1 Data Cleaning and Understanding
        </div>
        <div className='px-4 py-2 bg-primary-lightest rounded-xl'>
          Github, Jupyter Notebook/Co-Lab
        </div>
      </div>
      <div className='mt-5'>
        <div className='font-semibold text-lg'>Background Description:</div>
        <div className='mt-2'>
          <p>
            {' '}
            Performing data cleaning is fixing incorrect, incomplete, duplicate
            or otherwise erroneous data in a data set, identifying data errors
            and then changing, updating or removing data and correcting them.
          </p>
          <p>
            You can clean the dataset manually depending upon your understanding
            of data or use different approaches to achieve this task. Knowledge
            of Python is a must.
          </p>
        </div>
      </div>
      <div className='mt-5'>
        <div className='font-semibold text-lg'>Task Instructions - </div>
        <div className='mt-2'>
          <ul className='list-disc px-6 font-semibold'>
            <li>
              Clean the data by removing incorrect, duplicate or missing data.
            </li>
            <li>
              Analyse the data and understand the nature of the trends present
              in the data.
            </li>
            <li>
              Address any discrepancy in the data and deduce ways to either fix
              it or remove it.
            </li>
            <li>Make the data suitable for further analysis.</li>
          </ul>
        </div>
      </div>
      <div className='mt-5'>
        <div className='font-semibold text-lg'>Submission Format:</div>
        <div className='mt-2'>
          <ul className='list-disc px-6 font-semibold'>
            <li>Upload your Colab link with view access OR</li>
            <li>Upload your file on the github and provide the link OR </li>
            <li>
              Upload the file on your drive and share the drive link on the
              dashboard.
            </li>
          </ul>
        </div>
      </div>
      <div className='mt-5'>
        <div className='font-semibold text-lg'>Resources:</div>
        <div>
          <a
            href='https://www.youtube.com/watch?v=AbBZYHNYFaY'
            target='_blank'
            rel='noreferrer'
            className='mr-4 mt-2 hover:underline text-blue-500 '
          >
            https://www.youtube.com/watch?v=AbBZYHNYFaY
          </a>
        </div>
        <div>
          <a
            href='https://www.youtube.com/watch?v=EaGbS7eWSs0'
            target='_blank'
            rel='noreferrer'
            className='mr-4 mt-2 hover:underline text-blue-500 '
          >
            https://www.youtube.com/watch?v=EaGbS7eWSs0
          </a>
        </div>
        <div>
          <a
            href='https://www.kdnuggets.com/2023/04/exploring-data-cleaning-techniques-python.html#:~:text=In%20Python%2C%20a%20range%20of,and%20remove%20duplicate%20rows%2C%20respectively'
            target='_blank'
            rel='noreferrer'
            className='mr-4 mt-2 hover:underline text-blue-500 '
          >
            https://www.kdnuggets.com/2023/04/exploring-data-cleaning-techniques-python.html#:~:text=In%20Python%2C%20a%20range%20of,and%20remove%20duplicate%20rows%2C%20respectively
          </a>
        </div>
      </div>

      <div className='my-4 w-full'>
        <input
          className='focus:outline-none w-full sm:w-1/2 border-2 border-black  rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white mt-2'
          value={link}
          placeholder='Colab Link'
          onChange={(e) => setLink(e.target.value)}
        />
        <p className='text-xs text-red-500 mt-2'>
          {' '}
          {!link && 'Colab Link is Required'}{' '}
        </p>
      </div>

      <div className='text-center'>
        <button
          onClick={() => submitFn()}
          disabled={!link || isLoading}
          className={classNames(
            'mt-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0',
            !link ? 'opacity-50' : ''
          )}
        >
          {isLoading ? 'Processing...' : 'Submit'}
        </button>
        <div className='mt-2'> {message} </div>
      </div>
    </div>
  );
};

export default TaskOne;
