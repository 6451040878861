import QueryPanel from './QueryPanel';

const Index = () => {
  return (
    <div className='space-y-10'>
      <div>
        <p className='mb-2 text-2xl font-medium'> Assistance</p>
        <p className='text-[#B0B0B0] font-semibold mb-4'>
          Raise a Query to Belong Team{' '}
        </p>
      </div>
      <QueryPanel />
    </div>
  );
};

export default Index;
