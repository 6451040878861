import { supabase } from 'config/supabaseCOnfig';
import React, { useEffect, useState } from 'react';
import UserSubmissions from './UserSubmissions';

const Index = () => {
  const [data, setData] = useState<any>();

  const users = [
    '64b6477ee1584c0014457223',
    '64b95a4ed444890014bae302',
    '64b636c2e1584c0014456c4a',
    '64ccf76e20f2670014eaa919',
    '64b95a72d444890014bae325',
    '64b7bf0ca7ec2b0014751f95',
    '64b78353a7ec2b0014750a42',
    '64b7a423a7ec2b00147515d6',
    '64b7b367a7ec2b0014751b6f',
    '64b7fea52e820800147450a7',
    '64b69129a7ec2b001474f4b0',
    '64c1fcb3b0354300140e2d56',
    '64d1d5d0ebfc850014988716',
    '64c206b1b0354300140e3011',
    '64d1d82eebfc85001498874d',
    '64b62d17e1584c0014456958',
    '64b804552e8208001474517f',
    '64b8e3e62e820800147464e7',
    '64c3b56990d20b0014fb0147',
  ];
  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabase
        .from('submissions')
        .select('*')
        .eq('bootcamp_id', '2');
      if (error) {
        setData(null);
      }
      if (data) {
        setData(data);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <p className='text-lg mb-8 font-semibold'>User Report</p>
      <div className='flex gap-8 mb-4'>
        <div className='w-1/3'>Name</div>
        <div>Tech Submissions</div>
      </div>
      {users.map((userId) => {
        return <UserSubmissions userId={userId} />;
      })}
      <div>
        <b>Our Super Team</b> assignment submitted by Team 2, Team 4
      </div>
    </div>
  );
};

export default Index;
