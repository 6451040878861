import React, { useState, useEffect } from 'react';
import { supabase } from 'config/supabaseCOnfig';
import { Link, useParams } from 'react-router-dom';
import Exercise from '../../components/exercise';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline';
import ColabSubmissions from './ColabSubmissions';

const FetchExercise = ({ show }: { show: boolean }) => {
  const { id } = useParams();
  const [exercise, setExercise] = useState<any>('');
  useEffect(() => {
    const fetchExercise = async () => {
      const { data, error } = await supabase
        .from('code_exercises')
        .select('*')
        .eq('id', id)
        .single();
      if (error) {
        console.log(error);
      }
      if (data) {
        setExercise(data);
      }
    };
    if (id) {
      fetchExercise();
    }
  }, [id]);

  return (
    exercise && (
      <div>
        <div>
          <Link className='flex gap-2' to='/analytics-lab/python'>
            <ArrowLeftCircleIcon className='w-6 mb-4' />
            Back
          </Link>
        </div>
        {exercise.link_required && (
          <div>
            <p className='text-primary font-semibold'>
              For challenges ahead, you need to switch to Colab environment{' '}
              <a
                href='https://colab.google/'
                target='_blank'
                rel='noreferrer noopener'
                className='hover:underline text-blue-500'
              >
                https://colab.google/.
              </a>{' '}
              Steps to access the Colab:
            </p>
            <ul className='list-disc px-6  mt-6'>
              <li className='py-1'>
                Go to the link :{' '}
                <a
                  href='https://colab.research.google.com/?utm_source=scs-index'
                  target='_blank'
                  rel='noreferrer noopener'
                  className='hover:underline text-blue-500'
                >
                  https://colab.research.google.com/?utm_source=scs-index
                </a>{' '}
              </li>
              <li className='py-1'>
                Click on “Sign in”. (On top right corner of the webpage)
              </li>
              <li className='py-1'>You can login with your gmail account.</li>
              <li className='py-1'>Go to files</li>
              <li className='py-1'>Select new notebook</li>
              <li className='py-1'>Start Coding !!</li>
            </ul>
          </div>
        )}
        <p className='mb-4'>
          {/* {exercise.question} */}
          {show && (
            <p className='mb-4'>
              <span className='text-lg font-semibold'>Question - Python</span>
              {`(Novice)`}
            </p>
          )}
          {show &&
            exercise.question
              .split('\n')
              .map((line: string, lineIndex: number) => {
                return (
                  <React.Fragment key={lineIndex}>
                    {line.includes('https://') ? (
                      <a
                        className='hover:underline text-blue-500'
                        href={line}
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        {line}
                      </a>
                    ) : (
                      line
                    )}
                    <br />
                  </React.Fragment>
                );
              })}
        </p>
        {exercise.submission_type === 'IDE' ? (
          <div>
            <Exercise exercise={exercise} />
          </div>
        ) : (
          <ColabSubmissions exercise={exercise} />
        )}
      </div>
    )
  );
};

export default FetchExercise;
