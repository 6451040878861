import React from 'react';
import Upcoming from './Upcoming';
import Completed from './Completed';

const Tasks = ({
  setTaskView,
  upcoming,
  completed,
  teamId,
}: {
  setTaskView: any;
  upcoming: any;
  completed: any;
  teamId: any;
}) => {
  return (
    <div className='mt-10'>
      <div>Tasks & Skill Milestones</div>
      <div className='text-xs text-gray-500 mt-1 hidden'>
        You Have Completed 2 Out of 16 Tasks
      </div>
      <div className='mt-4 md:mt-6 grid grid-cols-12 gap-4 xl:gap-8'>
        <div className='col-span-12 xl:col-span-6'>
          <div className='p-1 border-r-2 text-center text-primary font-semibold border rounded mb-2'>
            <p>Upcoming</p>
          </div>
          <Upcoming
            setTaskView={setTaskView}
            assignments={upcoming}
            teamId={teamId}
          />
        </div>
        <div className='col-span-12 xl:col-span-6'>
          <div className='p-1 border-r-2 text-center text-primary font-semibold border rounded mb-2'>
            <p>Completed</p>
          </div>
          <Completed
            setTaskView={setTaskView}
            assignments={completed}
            teamId={teamId}
          />
        </div>
      </div>
    </div>
  );
};

export default Tasks;
