import { Amplify } from 'aws-amplify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AMPLIFY_CONFIG from '../config/AmplifyConfig';
import PrivateRoute from './PrivateRoute';
import NotFound from '../components/global/layout/NotFound';
import DashboardLayout from '../components/dashboard/DashboardLayout';
import NoSidebarLayout from '../components/dashboard/NoSidebarLayout';
// import LogoLayout from 'components/dashboard/LogoLayout';
// import SignUp from 'components/auth/SignUp';
import Login from 'components/auth/Login';
// import Dashboard from 'components/dashboard/panel';
import Profile from 'features/profile';
import BelongScore from 'components/belong-score';
import BelongScoreRoutes from './BelongScoreRoutes';
// import Phone from 'components/auth/phone-verification';

// import ForgotPassword from 'components/auth/forgotPassword/ForgotPassword';
// import PrivacyPolicy from 'components/home/footer-Links/PrivacyPolicy';
// import TermsConditions from 'components/home/footer-Links/TermsConditions';
// import AboutUs from 'components/home/footer-Links/AboutUs';
// import ContactUs from 'components/home/footer-Links/ContactUs';
// import CheckLink from 'components/auth/CheckLink';
// import JKLU from './JKLU';
// import Capstone from 'components/capstone';
import CapstoneView from 'components/capstone/capstoneView';
// import One from 'previous/one';
// import Two from 'previous/one copy';
import PaymentDetails from 'payment';
import Home from 'components/home/Home';
import BootcampHome from 'features/home';
import Analytics from 'features/analytics';
import FinalChallenge from 'features/final-challenge';
import EntrepreneurialLab from 'features/entrepreneurialLab';
import SkillLab from 'features/skillLab';
import DataStructures from 'features/skillLab/DataStructures';
import DataLibraries from 'features/skillLab/DataLibraries';
import Eda from 'features/skillLab/Eda';
import FetchExercise from 'features/skillLab/ide';
import PracticeLab from 'features/practice-lab';
import Assistance from 'features/assistance/query';
import Report from '../components/report';
const Index = () => {
  Amplify.configure(AMPLIFY_CONFIG);

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        {/* <Route path='jklu' element={<JKLU />} /> */}
        <Route path='/signup'>
          {/* <Route index element={<CheckLink />} /> */}
          {/* <Route path=':slug' element={<SignUp />} /> */}
        </Route>
        <Route path='login' element={<Login />} />
        {/* <Route path='forgot-password' element={<ForgotPassword />} />  */}

        <Route path='/register' element={<PaymentDetails />} />
        <Route path='/home' element={<Home />} />
        {/* <Route path='/'>
          <Route index element={<Home />} />
          <Route element={<LogoLayout />}>
            <Route path='privacy-policy' element={<PrivacyPolicy />} />
            <Route path='terms-&-conditions' element={<TermsConditions />} />
            <Route path='about-us' element={<AboutUs />} />
            <Route path='contact' element={<ContactUs />} />
          </Route>
          <Route path='career-bootcamp-feb-2023' element={<One />} />
          <Route path='the-better-india-x-belong' element={<Two />} />
        </Route> */}
        {/* Protected Routes */}
        <Route element={<PrivateRoute />}>
          <Route path='/' element={<DashboardLayout />}>
            <Route index element={<BootcampHome />} />
            <Route path='report' element={<Report />} />
            <Route path='analytics-lab'>
              <Route index element={<Analytics />} />
              <Route path='python' element={<SkillLab />} />
              <Route path='ds' element={<DataStructures />} />
              <Route path='dl' element={<DataLibraries />} />
              <Route path='eda' element={<Eda />} />
              <Route
                path='python/ide/:id'
                element={<FetchExercise show={true} />}
              />
            </Route>

            <Route
              path='entrepreneurial-lab'
              element={<EntrepreneurialLab />}
            />
            <Route path='practice-lab' element={<PracticeLab />} />
            <Route path='final-challenge' element={<FinalChallenge />} />
            <Route path='assistance' element={<Assistance />} />
            <Route path='profile' element={<Profile />} />
            <Route path='score-&-certification' element={<BelongScore />} />
            <Route path='belong-score/profile' element={<Profile />} />
            <Route path='/capstone/:capstoneId' element={<CapstoneView />} />
            {/* <Route path='/capstone' element={<Capstone />} /> */}
          </Route>
          <Route path='/' element={<NoSidebarLayout />}>
            {BelongScoreRoutes}
            {/* <Route path='phone-verify' element={<Phone />} /> */}
          </Route>
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Index;
