import classNames from 'classnames';
import useDomainTestResponsesContext from 'hooks/useDomainTestResponsesContent';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const TestCardBar: React.FC = () => {
  const { state } = useDomainTestResponsesContext();
  const navigate = useNavigate();
  return (
    <div className='flex justify-between items-center lg:font-bold font-medium font-manrope lg:text-lg text-base bg-gradient-to-b  from-white/100 to-bgCyan/100 lg:h-20 h-10 lg:px-20 px-6'>
      <p>Data Science Quiz</p>
      <div>
        <p
          className={classNames({
            hidden: !state.minutesLeft && !state.secondsLeft,
          })}
        >
          {state.minutesLeft}:{state.secondsLeft}
        </p>
      </div>
      <div
        onClick={() => {
          navigate('/');
        }}
        className='bg-red-400 rounded px-3 py-1 text-white cursor-pointer'
      >
        Exit
      </div>
    </div>
  );
};

export default TestCardBar;
