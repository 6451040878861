import React from 'react';
import TaskDetail from './TaskDetail';
import TaskSolution from './TaskSolution';

interface allProps {
  data: any;
  setSelectedTask: Function;
  selectedTask: any;
  enrollData: any;
  count: number;
  setReFetch: any;
  teamData: any;
}

const Index = (props: allProps) => {
  //  count={props.count}
  //       totalTasks={props.data.tasks.length}
  return (
    <div className='p-4'>
      <div className='flex justify-between'>
        <button
          className='text-primary border border-primary bg-white px-4 py-0.5 rounded-xl'
          onClick={() => props.setSelectedTask(null)}
        >
          Back
        </button>
        <div className='flex items-center bg-white border border-primary px-4 py-0.5 rounded text-sm font-semibold'>
          Task {props.count}/{props.data.tasks.length}{' '}
        </div>
      </div>
      <TaskDetail taskData={props.selectedTask} />
      <TaskSolution
        taskData={props.selectedTask}
        enrollData={props.enrollData}
        setSelectedTask={props.setSelectedTask}
        setReFetch={props.setReFetch}
        teamData={props.teamData}
      />
    </div>
  );
};

export default Index;
