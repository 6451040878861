import React from 'react';
import { Link } from 'react-scroll';

const NavigationBar = () => {
  return (
    <div className='flex justify-between items-baseline w-full'>
      <div className='flex space-x-2 sm:space-x-10 font-medium text-textColor-light'>
        <p className='underline underline-offset-8'>Task</p>
        <Link
          activeClass='active'
          to='solution'
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className='cursor-pointer'
        >
          Solution
        </Link>
        <Link
          activeClass='active'
          to='score-feedback'
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className='cursor-pointer'
        >
          Score & Feedback
        </Link>
      </div>
    </div>
  );
};

export default NavigationBar;
