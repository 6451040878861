import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className='flex flex-col py-4 lg:py-0 mt-2 lg:flex-row text-center items-center gap-x-8 gap-y-6 text-textColor-lightest font-semibold font-manrope'>
      {/* <p>Internships</p> */}
      {/* <p>Jobs</p> */}
      <div>
        <a href='https://employers.belong.education/'>Employer</a>
      </div>
      <div>
        <a href='https://universities.belong.education/'>University</a>
      </div>
      {/* <p>Belong Booster</p> */}
      <div className='text-secondary font-poppins font-bold'>
        <Link to='/login'>Login</Link>
      </div>
      <Link
        to='/signup'
        className='bg-primary/70 rounded-lg px-4 py-1 font-poppins font-bold shadow'
      >
        Signup
      </Link>
    </nav>
  );
};

export default Navbar;
