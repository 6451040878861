import React from 'react';

const CapstoneCertificateCard = ({
  logo,
  title,
}: {
  logo: any;
  title: string;
}) => {
  return (
    <div className='border rounded-md shadow-md p-4 space-y-6'>
      <div className='h-8'>{logo}</div>
      <p className='font-bold'>{title}</p>
      {/* date,level and score */}
      <div className='flex gap-4 text-sm'>
        <p className='border-r-2 border-black  pr-4'>24th May 2023</p>
        <p className='border-r-2 border-black  pr-4'>Beginner</p>
        <p>98 / 100</p>
      </div>
      <div className='flex justify-between items-center'>
        <div className='flex space-x-2 items-center '>
          <div>
            <svg
              width='18'
              height='19'
              viewBox='0 0 18 19'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15.75 16.25H2.25M13.5 8.75L9 13.25M9 13.25L4.5 8.75M9 13.25V2.75'
                stroke='#171717'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </div>
          <p>Task Sheet</p>
        </div>
        <button className='bg-primary py-2 px-6 font-semibold text-white text-sm rounded-md'>
          Certificate
        </button>
      </div>
    </div>
  );
};

export default CapstoneCertificateCard;
