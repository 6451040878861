import React from 'react';

const QuickInfo = () => {
  return (
    <div>
      <div>Entrepreneurial Lab</div>
      <div className='text-xs text-gray-500 mt-1'>
        Solve Tasks To Earn Certificate
      </div>
      <div className='mt-4 md:mt-6 bg-bgPink/50 p-4'>
        <div className='flex space-x-2'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1670234242/new%20b2b/image_212_y4kdrr.svg'
            alt='bulb'
          />
          <p className='font-semibold'>Quick Tips</p>
        </div>
        <div className='px-4 sm:px-8  mt-2 text-sm '>
          <ul className='list-disc'>
            <li className='hidden'>
              Please seek support on your assignments from the assigned coach
              via scheduled discussions or written query
            </li>
            <li>
              Please make sure to plan your work and schedule to avoid missing
              out on deadlines
            </li>
            <li className='hidden'>
              All tasks need to be submitted to complete the VGA & earn the
              certificate
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default QuickInfo;
