import React, { useEffect, useState } from 'react';
// import Panel from './Panel';
import Skills from './Skills';
import CertificateSection from './CertificateSection';
import { useGetUserQuery } from 'components/profile/userSlice';
import { supabase } from 'config/supabaseCOnfig';
import Loading from 'components/global/layout/Loading';
import Team from './Team';

const Index = () => {
  const { data: userData } = useGetUserQuery();
  const userId = userData?._id;
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [level, setLevel] = useState('');

  useEffect(() => {
    const fetchPreTestScore = async () => {
      const { data, error } = await supabase
        .from('quiz')
        .select('*')
        .match({ type: 'AICPRETEST', user_id: userId });
      if (error) {
        setError('Could not find data');
        setData(null);
        setLoading(false);
      }
      if (data) {
        setError(null);
        setData(data);
        setLoading(false);
        if (data) {
          if (data[0]?.percentage < 40) {
            setLevel('Basic');
          }
          if (data[0]?.percentage >= 40 && data[0]?.percentage < 70) {
            setLevel('Intermediate');
          }
          if (data[0]?.percentage >= 70) {
            setLevel('Advanced');
          }
        }
      }
    };
    if (userId) {
      fetchPreTestScore();
    }
  }, [userId]);

  return (
    <div>
      {loading && <Loading />}
      {error && 'Something went wrong'}
      {!loading && !data?.length && 'Please attempt your pre test first'}
      {!loading && data?.length ? (
        <div>
          <div className='grid grid-cols-12 gap-4'>
            <div className='col-span-9 space-y-10'>
              <div>
                <Team />
              </div>
              {/* level */}
              <div className='bg-[#FFDCC8] rounded-md md:flex space-x-8 my-6 p-4 items-center'>
                <div>
                  <svg
                    width='50'
                    height='49'
                    viewBox='0 0 50 49'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect width='25' height='23.6111' fill='#F5844C' />
                    <path
                      d='M25 0H30C41.0457 0 50 8.95431 50 20V23.6111H25V0Z'
                      fill='#FFA87D'
                    />
                    <rect
                      x='25'
                      y='23.6113'
                      width='25'
                      height='25'
                      fill='#F5844C'
                    />
                    <rect
                      x='3'
                      y='26.6113'
                      width='19'
                      height='19'
                      rx='9.5'
                      stroke='#FFA87D'
                      stroke-width='6'
                    />
                  </svg>
                </div>
                <p className='font-semibold text-lg'>
                  Based on your Belong Score you fall under {level} Skill Level
                </p>
              </div>
              {/* <Panel /> */}
              <Skills />
              <CertificateSection />
            </div>
            <div className='col-span-3'>
              <div className='border-2 rounded-md p-2 sm:p-8 mt-20'>
                <p className='text-xl'>Bootcamp Milestone</p>
                <div className='text-gray-400 my-4 text-sm'>
                  <p>Milestones Achieved - 8</p>
                  <p>Levels Climbed - 2</p>
                </div>

                <div className='mt-6'>
                  <div className='flex justify-center items-center border border-primary rounded-full'>
                    <svg
                      width='60'
                      height='60'
                      viewBox='0 0 258 258'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g filter='url(#filter0_d_6576_9311)'>
                        <rect
                          x='61'
                          y='61'
                          width='136'
                          height='136'
                          rx='68'
                          fill='white'
                        />
                        <g clip-path='url(#clip0_6576_9311)'>
                          <path
                            d='M154.978 124.36H151.63H151.629H143.555H143.554H128.999H114.445H114.444H106.37H106.369H103.02C100.373 124.36 98.2266 126.506 98.2266 129.154V165.339C98.2266 167.986 100.373 170.132 103.02 170.132H128.999H154.978C157.625 170.132 159.771 167.986 159.771 165.339V129.154C159.771 126.506 157.625 124.36 154.978 124.36Z'
                            fill='#D77E37'
                          />
                          <path
                            d='M143.555 110.83V122.623H143.556H151.63V110.83C151.63 98.3522 141.478 88.2002 129 88.2002V96.2748C137.026 96.2748 143.555 102.804 143.555 110.83Z'
                            fill='#696464'
                          />
                          <path
                            d='M114.442 122.623V110.83C114.442 102.804 120.971 96.2748 128.997 96.2748V88.2002C116.519 88.2002 106.367 98.3522 106.367 110.83V122.623H106.368H114.442Z'
                            fill='#959595'
                          />
                          <path
                            d='M154.979 121.444H151.631H151.63H143.556H143.555H129V167.216H154.979C157.626 167.216 159.772 165.07 159.772 162.423V126.238C159.772 123.591 157.626 121.444 154.979 121.444Z'
                            fill='#FFE089'
                          />
                          <path
                            d='M114.444 121.444H114.444H106.37H106.369H103.02C100.373 121.444 98.2266 123.59 98.2266 126.238V162.423C98.2266 165.07 100.373 167.216 103.02 167.216H128.999V121.444H114.444Z'
                            fill='#FFCA35'
                          />
                          <rect
                            x='125.977'
                            y='137.644'
                            width='6.04444'
                            height='15.1111'
                            rx='3.02222'
                            fill='#D77E37'
                          />
                          <rect
                            x='101.801'
                            y='121.444'
                            width='10.5778'
                            height='16.6222'
                            rx='4.53333'
                            transform='rotate(-90 101.801 121.444)'
                            fill='white'
                          />
                        </g>
                      </g>
                      <defs>
                        <filter
                          id='filter0_d_6576_9311'
                          x='0.555553'
                          y='0.555553'
                          width='256.889'
                          height='256.889'
                          filterUnits='userSpaceOnUse'
                          color-interpolation-filters='sRGB'
                        >
                          <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                          />
                          <feColorMatrix
                            in='SourceAlpha'
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                            result='hardAlpha'
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation='30.2222' />
                          <feColorMatrix
                            type='matrix'
                            values='0 0 0 0 0.588235 0 0 0 0 0.584314 0 0 0 0 0.584314 0 0 0 0.15 0'
                          />
                          <feBlend
                            mode='normal'
                            in2='BackgroundImageFix'
                            result='effect1_dropShadow_6576_9311'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_dropShadow_6576_9311'
                            result='shape'
                          />
                        </filter>
                        <clipPath id='clip0_6576_9311'>
                          <rect
                            width='83.1111'
                            height='83.1111'
                            fill='white'
                            transform='translate(87.4453 87.4443)'
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <p className='text-primary'>BASIC</p>
                  </div>
                  <div className='flex mt-4 px-6'>
                    <input
                      type='checkbox'
                      disabled
                      className='mr-4 accent-primary'
                    />
                    Earn 4/4 Skill Badges
                  </div>
                  <div className='flex mt-4 px-6'>
                    <input
                      type='checkbox'
                      disabled
                      className='mr-4 accent-primary'
                    />
                    Solve 1/1 Capstone
                  </div>
                  <div className='flex mt-4 px-6'>
                    <input
                      type='checkbox'
                      disabled
                      className='mr-4 accent-primary'
                    />
                    Solve 5/5 Entrepreneurial Tasks
                  </div>
                </div>
                <div className='mt-6 grayscale'>
                  <div className='flex justify-center items-center border border-primary rounded-full'>
                    <svg
                      width='60'
                      height='60'
                      viewBox='0 0 258 258'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g filter='url(#filter0_d_6576_9311)'>
                        <rect
                          x='61'
                          y='61'
                          width='136'
                          height='136'
                          rx='68'
                          fill='white'
                        />
                        <g clip-path='url(#clip0_6576_9311)'>
                          <path
                            d='M154.978 124.36H151.63H151.629H143.555H143.554H128.999H114.445H114.444H106.37H106.369H103.02C100.373 124.36 98.2266 126.506 98.2266 129.154V165.339C98.2266 167.986 100.373 170.132 103.02 170.132H128.999H154.978C157.625 170.132 159.771 167.986 159.771 165.339V129.154C159.771 126.506 157.625 124.36 154.978 124.36Z'
                            fill='#D77E37'
                          />
                          <path
                            d='M143.555 110.83V122.623H143.556H151.63V110.83C151.63 98.3522 141.478 88.2002 129 88.2002V96.2748C137.026 96.2748 143.555 102.804 143.555 110.83Z'
                            fill='#696464'
                          />
                          <path
                            d='M114.442 122.623V110.83C114.442 102.804 120.971 96.2748 128.997 96.2748V88.2002C116.519 88.2002 106.367 98.3522 106.367 110.83V122.623H106.368H114.442Z'
                            fill='#959595'
                          />
                          <path
                            d='M154.979 121.444H151.631H151.63H143.556H143.555H129V167.216H154.979C157.626 167.216 159.772 165.07 159.772 162.423V126.238C159.772 123.591 157.626 121.444 154.979 121.444Z'
                            fill='#FFE089'
                          />
                          <path
                            d='M114.444 121.444H114.444H106.37H106.369H103.02C100.373 121.444 98.2266 123.59 98.2266 126.238V162.423C98.2266 165.07 100.373 167.216 103.02 167.216H128.999V121.444H114.444Z'
                            fill='#FFCA35'
                          />
                          <rect
                            x='125.977'
                            y='137.644'
                            width='6.04444'
                            height='15.1111'
                            rx='3.02222'
                            fill='#D77E37'
                          />
                          <rect
                            x='101.801'
                            y='121.444'
                            width='10.5778'
                            height='16.6222'
                            rx='4.53333'
                            transform='rotate(-90 101.801 121.444)'
                            fill='white'
                          />
                        </g>
                      </g>
                      <defs>
                        <filter
                          id='filter0_d_6576_9311'
                          x='0.555553'
                          y='0.555553'
                          width='256.889'
                          height='256.889'
                          filterUnits='userSpaceOnUse'
                          color-interpolation-filters='sRGB'
                        >
                          <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                          />
                          <feColorMatrix
                            in='SourceAlpha'
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                            result='hardAlpha'
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation='30.2222' />
                          <feColorMatrix
                            type='matrix'
                            values='0 0 0 0 0.588235 0 0 0 0 0.584314 0 0 0 0 0.584314 0 0 0 0.15 0'
                          />
                          <feBlend
                            mode='normal'
                            in2='BackgroundImageFix'
                            result='effect1_dropShadow_6576_9311'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_dropShadow_6576_9311'
                            result='shape'
                          />
                        </filter>
                        <clipPath id='clip0_6576_9311'>
                          <rect
                            width='83.1111'
                            height='83.1111'
                            fill='white'
                            transform='translate(87.4453 87.4443)'
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <p className='text-primary'>INTERMEDIATE</p>
                  </div>
                  <div className='flex mt-4 px-6'>
                    <input type='checkbox' className='mr-4 accent-primary' />
                    Earn 0/4 Skill Badges
                  </div>
                  <div className='flex mt-4 px-6'>
                    <input type='checkbox' className='mr-4 accent-primary' />
                    Solve 0/1 Capstone
                  </div>
                  <div className='flex mt-4 px-6'>
                    <input type='checkbox' className='mr-4 accent-primary' />
                    Solve 0/6 Entrepreneurial Tasks
                  </div>
                </div>
                <div className='mt-6 grayscale'>
                  <div className='flex justify-center items-center border border-primary rounded-full'>
                    <svg
                      width='60'
                      height='60'
                      viewBox='0 0 258 258'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g filter='url(#filter0_d_6576_9311)'>
                        <rect
                          x='61'
                          y='61'
                          width='136'
                          height='136'
                          rx='68'
                          fill='white'
                        />
                        <g clip-path='url(#clip0_6576_9311)'>
                          <path
                            d='M154.978 124.36H151.63H151.629H143.555H143.554H128.999H114.445H114.444H106.37H106.369H103.02C100.373 124.36 98.2266 126.506 98.2266 129.154V165.339C98.2266 167.986 100.373 170.132 103.02 170.132H128.999H154.978C157.625 170.132 159.771 167.986 159.771 165.339V129.154C159.771 126.506 157.625 124.36 154.978 124.36Z'
                            fill='#D77E37'
                          />
                          <path
                            d='M143.555 110.83V122.623H143.556H151.63V110.83C151.63 98.3522 141.478 88.2002 129 88.2002V96.2748C137.026 96.2748 143.555 102.804 143.555 110.83Z'
                            fill='#696464'
                          />
                          <path
                            d='M114.442 122.623V110.83C114.442 102.804 120.971 96.2748 128.997 96.2748V88.2002C116.519 88.2002 106.367 98.3522 106.367 110.83V122.623H106.368H114.442Z'
                            fill='#959595'
                          />
                          <path
                            d='M154.979 121.444H151.631H151.63H143.556H143.555H129V167.216H154.979C157.626 167.216 159.772 165.07 159.772 162.423V126.238C159.772 123.591 157.626 121.444 154.979 121.444Z'
                            fill='#FFE089'
                          />
                          <path
                            d='M114.444 121.444H114.444H106.37H106.369H103.02C100.373 121.444 98.2266 123.59 98.2266 126.238V162.423C98.2266 165.07 100.373 167.216 103.02 167.216H128.999V121.444H114.444Z'
                            fill='#FFCA35'
                          />
                          <rect
                            x='125.977'
                            y='137.644'
                            width='6.04444'
                            height='15.1111'
                            rx='3.02222'
                            fill='#D77E37'
                          />
                          <rect
                            x='101.801'
                            y='121.444'
                            width='10.5778'
                            height='16.6222'
                            rx='4.53333'
                            transform='rotate(-90 101.801 121.444)'
                            fill='white'
                          />
                        </g>
                      </g>
                      <defs>
                        <filter
                          id='filter0_d_6576_9311'
                          x='0.555553'
                          y='0.555553'
                          width='256.889'
                          height='256.889'
                          filterUnits='userSpaceOnUse'
                          color-interpolation-filters='sRGB'
                        >
                          <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                          />
                          <feColorMatrix
                            in='SourceAlpha'
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                            result='hardAlpha'
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation='30.2222' />
                          <feColorMatrix
                            type='matrix'
                            values='0 0 0 0 0.588235 0 0 0 0 0.584314 0 0 0 0 0.584314 0 0 0 0.15 0'
                          />
                          <feBlend
                            mode='normal'
                            in2='BackgroundImageFix'
                            result='effect1_dropShadow_6576_9311'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_dropShadow_6576_9311'
                            result='shape'
                          />
                        </filter>
                        <clipPath id='clip0_6576_9311'>
                          <rect
                            width='83.1111'
                            height='83.1111'
                            fill='white'
                            transform='translate(87.4453 87.4443)'
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <p className='text-primary'>ADVANCE</p>
                  </div>
                  <div className='flex mt-4 px-6'>
                    <input type='checkbox' className='mr-4 accent-primary' />
                    Earn 0/4 Skill Badges
                  </div>
                  <div className='flex mt-4 px-6'>
                    <input type='checkbox' className='mr-4 accent-primary' />
                    Solve 0/1 Capstone
                  </div>
                  <div className='flex mt-4 px-6'>
                    <input type='checkbox' className='mr-4 accent-primary' />
                    Solve 0/6 Entrepreneurial Tasks
                  </div>
                </div>
                <div className='mt-6 grayscale'>
                  <div className='flex justify-center items-center border border-primary rounded-full'>
                    <svg
                      width='60'
                      height='60'
                      viewBox='0 0 258 258'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g filter='url(#filter0_d_6576_9311)'>
                        <rect
                          x='61'
                          y='61'
                          width='136'
                          height='136'
                          rx='68'
                          fill='white'
                        />
                        <g clip-path='url(#clip0_6576_9311)'>
                          <path
                            d='M154.978 124.36H151.63H151.629H143.555H143.554H128.999H114.445H114.444H106.37H106.369H103.02C100.373 124.36 98.2266 126.506 98.2266 129.154V165.339C98.2266 167.986 100.373 170.132 103.02 170.132H128.999H154.978C157.625 170.132 159.771 167.986 159.771 165.339V129.154C159.771 126.506 157.625 124.36 154.978 124.36Z'
                            fill='#D77E37'
                          />
                          <path
                            d='M143.555 110.83V122.623H143.556H151.63V110.83C151.63 98.3522 141.478 88.2002 129 88.2002V96.2748C137.026 96.2748 143.555 102.804 143.555 110.83Z'
                            fill='#696464'
                          />
                          <path
                            d='M114.442 122.623V110.83C114.442 102.804 120.971 96.2748 128.997 96.2748V88.2002C116.519 88.2002 106.367 98.3522 106.367 110.83V122.623H106.368H114.442Z'
                            fill='#959595'
                          />
                          <path
                            d='M154.979 121.444H151.631H151.63H143.556H143.555H129V167.216H154.979C157.626 167.216 159.772 165.07 159.772 162.423V126.238C159.772 123.591 157.626 121.444 154.979 121.444Z'
                            fill='#FFE089'
                          />
                          <path
                            d='M114.444 121.444H114.444H106.37H106.369H103.02C100.373 121.444 98.2266 123.59 98.2266 126.238V162.423C98.2266 165.07 100.373 167.216 103.02 167.216H128.999V121.444H114.444Z'
                            fill='#FFCA35'
                          />
                          <rect
                            x='125.977'
                            y='137.644'
                            width='6.04444'
                            height='15.1111'
                            rx='3.02222'
                            fill='#D77E37'
                          />
                          <rect
                            x='101.801'
                            y='121.444'
                            width='10.5778'
                            height='16.6222'
                            rx='4.53333'
                            transform='rotate(-90 101.801 121.444)'
                            fill='white'
                          />
                        </g>
                      </g>
                      <defs>
                        <filter
                          id='filter0_d_6576_9311'
                          x='0.555553'
                          y='0.555553'
                          width='256.889'
                          height='256.889'
                          filterUnits='userSpaceOnUse'
                          color-interpolation-filters='sRGB'
                        >
                          <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                          />
                          <feColorMatrix
                            in='SourceAlpha'
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                            result='hardAlpha'
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation='30.2222' />
                          <feColorMatrix
                            type='matrix'
                            values='0 0 0 0 0.588235 0 0 0 0 0.584314 0 0 0 0 0.584314 0 0 0 0.15 0'
                          />
                          <feBlend
                            mode='normal'
                            in2='BackgroundImageFix'
                            result='effect1_dropShadow_6576_9311'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_dropShadow_6576_9311'
                            result='shape'
                          />
                        </filter>
                        <clipPath id='clip0_6576_9311'>
                          <rect
                            width='83.1111'
                            height='83.1111'
                            fill='white'
                            transform='translate(87.4453 87.4443)'
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <p className='text-primary'>BELONG SCORE</p>
                  </div>
                  <div className='flex mt-4 px-6'>
                    <input type='checkbox' className='mr-4 accent-primary' />
                    Aptitude Test
                  </div>
                  <div className='flex mt-4 px-6'>
                    <input type='checkbox' className='mr-4 accent-primary' />
                    Domain Test
                  </div>
                  <div className='flex mt-4 px-6'>
                    <input type='checkbox' className='mr-4 accent-primary' />
                    Profile Strength
                  </div>
                </div>
                <div className='mt-6 grayscale'>
                  <div className='flex justify-center items-center border border-primary rounded-full'>
                    <svg
                      width='60'
                      height='60'
                      viewBox='0 0 258 258'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g filter='url(#filter0_d_6576_9311)'>
                        <rect
                          x='61'
                          y='61'
                          width='136'
                          height='136'
                          rx='68'
                          fill='white'
                        />
                        <g clip-path='url(#clip0_6576_9311)'>
                          <path
                            d='M154.978 124.36H151.63H151.629H143.555H143.554H128.999H114.445H114.444H106.37H106.369H103.02C100.373 124.36 98.2266 126.506 98.2266 129.154V165.339C98.2266 167.986 100.373 170.132 103.02 170.132H128.999H154.978C157.625 170.132 159.771 167.986 159.771 165.339V129.154C159.771 126.506 157.625 124.36 154.978 124.36Z'
                            fill='#D77E37'
                          />
                          <path
                            d='M143.555 110.83V122.623H143.556H151.63V110.83C151.63 98.3522 141.478 88.2002 129 88.2002V96.2748C137.026 96.2748 143.555 102.804 143.555 110.83Z'
                            fill='#696464'
                          />
                          <path
                            d='M114.442 122.623V110.83C114.442 102.804 120.971 96.2748 128.997 96.2748V88.2002C116.519 88.2002 106.367 98.3522 106.367 110.83V122.623H106.368H114.442Z'
                            fill='#959595'
                          />
                          <path
                            d='M154.979 121.444H151.631H151.63H143.556H143.555H129V167.216H154.979C157.626 167.216 159.772 165.07 159.772 162.423V126.238C159.772 123.591 157.626 121.444 154.979 121.444Z'
                            fill='#FFE089'
                          />
                          <path
                            d='M114.444 121.444H114.444H106.37H106.369H103.02C100.373 121.444 98.2266 123.59 98.2266 126.238V162.423C98.2266 165.07 100.373 167.216 103.02 167.216H128.999V121.444H114.444Z'
                            fill='#FFCA35'
                          />
                          <rect
                            x='125.977'
                            y='137.644'
                            width='6.04444'
                            height='15.1111'
                            rx='3.02222'
                            fill='#D77E37'
                          />
                          <rect
                            x='101.801'
                            y='121.444'
                            width='10.5778'
                            height='16.6222'
                            rx='4.53333'
                            transform='rotate(-90 101.801 121.444)'
                            fill='white'
                          />
                        </g>
                      </g>
                      <defs>
                        <filter
                          id='filter0_d_6576_9311'
                          x='0.555553'
                          y='0.555553'
                          width='256.889'
                          height='256.889'
                          filterUnits='userSpaceOnUse'
                          color-interpolation-filters='sRGB'
                        >
                          <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                          />
                          <feColorMatrix
                            in='SourceAlpha'
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                            result='hardAlpha'
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation='30.2222' />
                          <feColorMatrix
                            type='matrix'
                            values='0 0 0 0 0.588235 0 0 0 0 0.584314 0 0 0 0 0.584314 0 0 0 0.15 0'
                          />
                          <feBlend
                            mode='normal'
                            in2='BackgroundImageFix'
                            result='effect1_dropShadow_6576_9311'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_dropShadow_6576_9311'
                            result='shape'
                          />
                        </filter>
                        <clipPath id='clip0_6576_9311'>
                          <rect
                            width='83.1111'
                            height='83.1111'
                            fill='white'
                            transform='translate(87.4453 87.4443)'
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <p className='text-primary'>FINAL CHALLENGE</p>
                  </div>
                  <div className='flex mt-4 px-6'>
                    <input type='checkbox' className='mr-4 accent-primary' />
                    Hackathon
                  </div>
                  <div className='flex mt-4 px-6'>
                    <input type='checkbox' className='mr-4 accent-primary' />
                    Fish Tank
                  </div>
                </div>
                <div className='mt-6 grayscale'>
                  <div className='flex justify-center items-center border border-primary rounded-full'>
                    <svg
                      width='60'
                      height='60'
                      viewBox='0 0 258 258'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g filter='url(#filter0_d_6576_9311)'>
                        <rect
                          x='61'
                          y='61'
                          width='136'
                          height='136'
                          rx='68'
                          fill='white'
                        />
                        <g clip-path='url(#clip0_6576_9311)'>
                          <path
                            d='M154.978 124.36H151.63H151.629H143.555H143.554H128.999H114.445H114.444H106.37H106.369H103.02C100.373 124.36 98.2266 126.506 98.2266 129.154V165.339C98.2266 167.986 100.373 170.132 103.02 170.132H128.999H154.978C157.625 170.132 159.771 167.986 159.771 165.339V129.154C159.771 126.506 157.625 124.36 154.978 124.36Z'
                            fill='#D77E37'
                          />
                          <path
                            d='M143.555 110.83V122.623H143.556H151.63V110.83C151.63 98.3522 141.478 88.2002 129 88.2002V96.2748C137.026 96.2748 143.555 102.804 143.555 110.83Z'
                            fill='#696464'
                          />
                          <path
                            d='M114.442 122.623V110.83C114.442 102.804 120.971 96.2748 128.997 96.2748V88.2002C116.519 88.2002 106.367 98.3522 106.367 110.83V122.623H106.368H114.442Z'
                            fill='#959595'
                          />
                          <path
                            d='M154.979 121.444H151.631H151.63H143.556H143.555H129V167.216H154.979C157.626 167.216 159.772 165.07 159.772 162.423V126.238C159.772 123.591 157.626 121.444 154.979 121.444Z'
                            fill='#FFE089'
                          />
                          <path
                            d='M114.444 121.444H114.444H106.37H106.369H103.02C100.373 121.444 98.2266 123.59 98.2266 126.238V162.423C98.2266 165.07 100.373 167.216 103.02 167.216H128.999V121.444H114.444Z'
                            fill='#FFCA35'
                          />
                          <rect
                            x='125.977'
                            y='137.644'
                            width='6.04444'
                            height='15.1111'
                            rx='3.02222'
                            fill='#D77E37'
                          />
                          <rect
                            x='101.801'
                            y='121.444'
                            width='10.5778'
                            height='16.6222'
                            rx='4.53333'
                            transform='rotate(-90 101.801 121.444)'
                            fill='white'
                          />
                        </g>
                      </g>
                      <defs>
                        <filter
                          id='filter0_d_6576_9311'
                          x='0.555553'
                          y='0.555553'
                          width='256.889'
                          height='256.889'
                          filterUnits='userSpaceOnUse'
                          color-interpolation-filters='sRGB'
                        >
                          <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                          />
                          <feColorMatrix
                            in='SourceAlpha'
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                            result='hardAlpha'
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation='30.2222' />
                          <feColorMatrix
                            type='matrix'
                            values='0 0 0 0 0.588235 0 0 0 0 0.584314 0 0 0 0 0.584314 0 0 0 0.15 0'
                          />
                          <feBlend
                            mode='normal'
                            in2='BackgroundImageFix'
                            result='effect1_dropShadow_6576_9311'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_dropShadow_6576_9311'
                            result='shape'
                          />
                        </filter>
                        <clipPath id='clip0_6576_9311'>
                          <rect
                            width='83.1111'
                            height='83.1111'
                            fill='white'
                            transform='translate(87.4453 87.4443)'
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <p className='text-primary'>CERTIFICATE</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Index;
