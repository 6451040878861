// import FinalChallengeCard from 'components/bootcamp-final-challenge/FinalChallengeCard';
// import FishTankSection from './FishTankSection';
import Hackathon from 'components/bootcamp-final-challenge';

const Index = () => {
  return (
    <div className='space-y-10 px-6'>
      {/* card area */}
      <div>
        <p className='mb-2 text-2xl font-medium'> Final Challenge</p>
        <p className='text-[#B0B0B0] font-semibold mb-4'>
          Solve Hackathon Challenges to earn certificate{' '}
        </p>
        <Hackathon />
      </div>
      {/* fish tank area */}
      {/* <FishTankSection /> */}
    </div>
  );
};

export default Index;
