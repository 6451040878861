import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import Loading from 'components/global/layout/Loading';
import { useGetUserQuery } from 'components/profile/userSlice';
import Category from './Category';
import { supabase } from 'config/supabaseCOnfig';
import { useState } from 'react';

const DataLibraries = () => {
  const { data: userData, isLoading } = useGetUserQuery();
  const userId = userData?._id;
  const [allBasicQuestionSolved, setAllBasicQuestionSolved] = useState(false);
  const [allIntermediateQuestionSolved, setAllIntermediateQuestionSolved] =
    useState(false);

  const basicQuestions = [35, 36, 37, 38, 39];
  const intermediateQuestions = [62, 63, 64, 65, 66];

  // functions for basic questions
  async function fetchData() {
    const { data } = await supabase
      .from('submissions')
      .select()
      .match({ user_id: userId, bootcamp_id: 2 })
      .in('exercise_id', basicQuestions);
    if (data && data.length === 5) {
      setAllBasicQuestionSolved(true);
    } else {
      setAllBasicQuestionSolved(false);
    }
  }

  if (userId) {
    fetchData();
  }

  // functions for Intermediate questions
  async function fetchIntermediateData() {
    const { data } = await supabase
      .from('submissions')
      .select()
      .match({ user_id: userId, bootcamp_id: 2 })
      .in('exercise_id', intermediateQuestions);
    if (data && data.length === 5) {
      setAllIntermediateQuestionSolved(true);
    } else {
      setAllIntermediateQuestionSolved(false);
    }
  }

  if (userId) {
    fetchIntermediateData();
  }

  const questions = [
    {
      question: 'Find the average temperature for each month in the dataset.',
      number: 35,
    },
    {
      question:
        'Find the date (EST) with the highest humidity in the dataset. ',
      number: 36,
    },
    {
      question:
        'Calculate the total precipitation for each event type in the dataset.',
      number: 37,
    },
    {
      question:
        'What is the average temperature for records where the precipitation is zero and the wind speed is greater than 10 MPH?',
      number: 38,
    },
    {
      question:
        'What is the percentage of records where the precipitation is zero and the cloud cover is either 0 or 1?',
      number: 39,
    },
  ];
  const questionsIntermediate = [
    {
      question: 'find the total sales revenue generated by each year.',
      number: 62,
    },
    {
      question:
        'Find the top 3 product categories that generated the highest revenue.',
      number: 63,
    },
    {
      question:
        'calculate the average customer age for each country and display the result in descending order.',
      number: 64,
    },
    {
      question:
        'Calculate the total revenue generated by each product category and sort them in alphabetical order.',
      number: 65,
    },
    {
      question: 'Display the month and the corresponding total revenue.',
      number: 66,
    },
  ];

  // const questionsAdvanced = [
  //   {
  //     question:
  //       'Given an array of integers A and an integer B, find and return the minimum number',
  //     number: 57,
  //   },
  //   {
  //     question:
  //       'Given an array of integers A, every element appears twice except for one.',
  //     number: 58,
  //   },
  //   {
  //     question: 'Given a number A, check if it is a magic number or not.',
  //     number: 59,
  //   },
  //   {
  //     question:
  //       'Given an array of integers A, find and return the peak element in it.',
  //     number: 60,
  //   },
  //   {
  //     question: 'Given an expression string A, examine whether the pairs...',
  //     number: 61,
  //   },
  // ];

  const dataLibraryLogo = (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_6576_4304)'>
        <path
          d='M32.0014 17.4062V31.9997H29.8915L26.375 24.703L29.8915 17.4062H32.0014Z'
          fill='#FFD820'
        />
        <path
          d='M29.8897 17.4062H17.4062V31.9997H29.8897V17.4062Z'
          fill='#FFE777'
        />
        <path
          d='M14.5951 17.4062V31.9997H12.4852L8.96875 24.703L12.4852 17.4062H14.5951Z'
          fill='#FE5F1A'
        />
        <path d='M12.4835 17.4062H0V31.9997H12.4835V17.4062Z' fill='#FE834D' />
        <path
          d='M32.0014 0V14.5934H29.8915L26.375 7.29669L29.8915 0H32.0014Z'
          fill='#FE5F1A'
        />
        <path d='M29.8897 0H17.4062V14.5934H29.8897V0Z' fill='#FE834D' />
        <path
          d='M14.5951 0V14.5934H12.4852L8.96875 7.29669L12.4852 0H14.5951Z'
          fill='#FFD820'
        />
        <path d='M12.4835 0H0V14.5934H12.4835V0Z' fill='#FFE777' />
      </g>
      <defs>
        <clipPath id='clip0_6576_4304'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
  return (
    <div className='px-6'>
      <div className='flex justify-between'>
        <div>
          <p className='text-xl'>Data Libraries</p>
          <p className='text-gray-400 text-xs mt-2'>Your skill Badges</p>
        </div>
        <div>
          <a href='/DL.pdf' download>
            <button className='py-2 px-8 border-primary border text-sm rounded shadow text-primary'>
              <DocumentArrowDownIcon className='inline-block w-6 mr-2' />
              Resources
            </button>
          </a>
        </div>
      </div>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <div className='mt-8 flex gap-4'>
            {/* badge 1 */}
            <div>
              {allBasicQuestionSolved ? (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat  object-shadow grid items-center'
                  style={{
                    backgroundImage:
                      'url("/images/skills/skill-bg-bronze.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    <div className='flex justify-center'>{dataLibraryLogo}</div>
                    <p className='text-center mt-2 font-semibold'>
                      Data Libraries
                    </p>
                    <img
                      src='/images/skills/stars-1.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              ) : (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat grayscale object-shadow grid items-center'
                  style={{
                    backgroundImage: 'url("/images/skills/skill-bg-0.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    <div className='flex justify-center'>
                      {' '}
                      {dataLibraryLogo}
                    </div>
                    <p className='text-center mt-2 font-semibold'>
                      Data Libraries
                    </p>
                    <img
                      src='/images/skills/stars-1.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              )}
            </div>
            {/* badge 2 */}
            <div>
              {allIntermediateQuestionSolved ? (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat  object-shadow grid items-center'
                  style={{
                    backgroundImage:
                      'url("/images/skills/skill-bg-silver.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    <div className='flex justify-center'>{dataLibraryLogo}</div>
                    <p className='text-center mt-2 font-semibold'>
                      Data Libraries
                    </p>
                    <img
                      src='/images/skills/stars-2.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              ) : (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat grayscale object-shadow grid items-center'
                  style={{
                    backgroundImage: 'url("/images/skills/skill-bg-0.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    <div className='flex justify-center'>
                      {' '}
                      {dataLibraryLogo}
                    </div>
                    <p className='text-center mt-2 font-semibold'>
                      Data Libraries
                    </p>
                    <img
                      src='/images/skills/stars-1.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <Category
              heading='Novice'
              questions={questions}
              locked={false}
              userId={userId}
            />
            <Category
              heading='Intermediate'
              questions={questionsIntermediate}
              locked={false}
              userId={userId}
            />
            {/* <Category
              heading='Expert'
              questions={questionsAdvanced}
              done={false}
              locked={false}
              userId={userId}
            /> */}
          </div>
        </>
      )}
    </div>
  );
};

export default DataLibraries;
