import { useInterval } from 'hooks/useInterval';
import { useTimeout } from 'hooks/useTimeout';
// import { useTimeout } from 'hooks/useTimeout';
import React, { useEffect, useReducer, useState } from 'react';
import { CapstoneTestResponsesContext } from 'hooks/useCapstoneTestResponsesContext';

import Questions from './questions';
import { reducer } from './utils/helper';
import { initialState } from './utils/types';
import // useFinishCapstoneTestMutation,
// useUpdateCapstoneResponsesMutation,
'../CapstoneQuizSlice';
import { useGetUserQuery } from 'components/profile/userSlice';
import { addMinutes, differenceInSeconds } from 'date-fns';
import { supabase } from 'config/supabaseCOnfig';
import axios from 'axios';
import { API_URL } from 'config/API_LINKS';

const Index: React.FC<{
  enrollData: any;
  setShowScores: any;
  setShowQuiz: any;
  setReFetch: any;
}> = ({ enrollData, setShowScores, setShowQuiz, setReFetch }) => {
  const { data: userData } = useGetUserQuery();
  const userId = userData?._id;
  const userQuizData = enrollData.quiz_submission?.find(
    (quiz: any) => quiz.user_id === userId
  );
  const totalQuestions = userQuizData.responses.length;
  const startTime = userQuizData?.start_time;
  const endTime = addMinutes(new Date(startTime), totalQuestions);
  const currentTime = new Date();
  const timeRemain = differenceInSeconds(endTime, currentTime);

  // const [updateResponse] = useUpdateCapstoneResponsesMutation();
  // const [finishTest] = useFinishCapstoneTestMutation();
  const [timeLeft, setTimeLeft] = useState(timeRemain);

  const domainResponsesInitialState: initialState = {
    capstone: '',
    minutesLeft: 0,
    secondsLeft: 0,
    currentQuestion: 0,
    responses: userQuizData.responses,
    totalQuestions: totalQuestions,
    enrollmentId: enrollData.id,
  };

  const [state, dispatch] = useReducer(reducer, domainResponsesInitialState);

  async function finishTestFn() {
    // finishTest({ enrollmentId: state.enrollmentId });
    const { data, status } = await supabase
      .from('capstone_submissions')
      .select()
      .match({ id: enrollData.id });
    if (status === 200 && data?.length) {
      const quizData = data[0]?.quiz_submission || [];
      const currentuserData = quizData.find(
        ({ user_id }: any) => user_id === userId
      );
      const otherQuizData = quizData.filter((quiz: any) => {
        return quiz.user_id !== userId;
      });
      const quizScoreData = await axios.post(
        API_URL + '/enrollments/capstone/check-test',
        {
          capstoneId: enrollData.capstone_id,
          responses: state.responses,
        }
      );
      const finalQuizData = otherQuizData.concat({
        ...currentuserData,
        responses: state.responses,
        ...quizScoreData?.data?.data,
        finish_time: Date.now(),
      });
      await supabase
        .from('capstone_submissions')
        .update({ quiz_submission: finalQuizData })
        .match({ id: enrollData.id });
    }
    setReFetch((pre: any) => !pre);
    setShowQuiz(false);
  }

  // useEffect(() => {
  //   updateResponse({
  //     responses: state.responses,
  //     enrollmentId: state.enrollmentId,
  //   });
  // }, [state.responses, state.enrollmentId, updateResponse]);

  useEffect(() => {
    const updateFn = async () => {
      const { data, status } = await supabase
        .from('capstone_submissions')
        .select()
        .match({ id: enrollData.id });
      if (status === 200 && data?.length) {
        const quizData = data[0]?.quiz_submission || [];
        const currentuserData = quizData.find(
          ({ user_id }: any) => user_id === userId
        );
        const otherQuizData = quizData.filter((quiz: any) => {
          return quiz.user_id !== userId;
        });
        const finalQuizData = otherQuizData.concat({
          ...currentuserData,
          responses: state.responses,
        });
        await supabase
          .from('capstone_submissions')
          .update({ quiz_submission: finalQuizData })
          .match({ id: enrollData.id });
      }
    };
    updateFn();
  }, [enrollData.id, state.responses, userId]);

  // Set timeout to finish test
  useTimeout(() => {
    finishTestFn();
  }, timeRemain * 1000);
  //Update timer
  useInterval(() => {
    const minutesLeft = Math.floor(timeLeft / 60);
    const secondsLeft = timeLeft % 60;
    dispatch({
      type: 'UPDATE_REMAINING_TIME',
      payload: { minutesLeft, secondsLeft },
    });
    setTimeLeft((timeLeft: number) => timeLeft - 1);
  }, 1000);

  return (
    <CapstoneTestResponsesContext.Provider value={{ state, dispatch }}>
      <div>
        <Questions
          setShowScores={setShowScores}
          setShowQuiz={setShowQuiz}
          setReFetch={setReFetch}
        />
      </div>
    </CapstoneTestResponsesContext.Provider>
  );
};

export default Index;
