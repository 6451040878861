import { useState } from 'react';

import Editor from '@monaco-editor/react';

const CodeEditorWindow = ({
  onChange,
  language,
  code,
  theme,
  prewrittenCode,
}: any) => {
  const [value, setValue] = useState(code || '');

  const handleEditorChange = (value?: string) => {
    setValue(value);
    onChange('code', value);
  };

  return (
    <div className='overlay rounded-md overflow-hidden w-full h-full shadow-4xl'>
      <Editor
        height='85vh'
        width={`100%`}
        language={language || 'javascript'}
        value={value}
        theme={theme}
        defaultValue={prewrittenCode}
        onChange={handleEditorChange}
      />
    </div>
  );
};
export default CodeEditorWindow;
