import { supabase } from 'config/supabaseCOnfig';
import { useEffect, useState } from 'react';

const AssignmentReport = ({ userId }: { userId: string }) => {
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabase
        .from('assignment_submissions')
        .select('*')
        .match({ user_id: userId });
      if (error) {
        setData(null);
      }
      if (data) {
        setData(data);
      }
    };
    fetchData();
  }, [userId]);
  return <div>{data?.length ? 'Assignment 1 Submitted' : ''}</div>;
};

export default AssignmentReport;
