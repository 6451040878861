import { useEffect, useState } from 'react';
import { API_URL, UPLOAD_OR_DOWNLOAD_URL } from '../../config/API_LINKS';
import axios from 'axios';

const TeamMembers = ({ teamData }: any) => {
  const [allUser, setAllUser] = useState<any[]>([]);
  const usersArray = teamData.users;

  useEffect(() => {
    const fetchData = async () => {
      const data = await Promise.all(
        usersArray.map(async (userId: any) => {
          const user = await axios(API_URL + '/user', {
            params: { userId },
          });
          return user?.data?.data;
        })
      );
      setAllUser(data);
    };

    fetchData();
  }, [usersArray]);

  return (
    <div>
      {allUser
        .filter((el) => {
          return el;
        })
        .map((user) => {
          const imageUrl = user.imageUrl
            ? UPLOAD_OR_DOWNLOAD_URL + '/' + user.imageUrl
            : 'https://res.cloudinary.com/belong/image/upload/v1675236744/test/Upload_Placement_Profile_Hover_nmjf4j.svg';
          return (
            <div className='grid grid-cols-3 space-y-2 mt-2 '>
              <img className='h-12 rounded-full mx-auto' src={imageUrl} />
              <div className=' flex items-center'>
                {user.firstName} {user.lastName}{' '}
              </div>
              <div className=' flex flex-col items-center'>
                <p>{user.email}</p> <p>{user.contactNumber}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TeamMembers;
