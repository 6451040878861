import React, { useState, useEffect } from 'react';
import UploadScore from './UploadScore';
import htmlparser from 'html-react-parser';
import { IoIosArrowBack } from 'react-icons/io';
import { supabase } from 'config/supabaseCOnfig';
import TeamMembers from 'features/home/TeamMembers';

const EvaluateView = ({
  evaluateAssignmentData,
  setEvaluateAssignmentData,
  teamId,
}: any) => {
  const [teamData, setTeamData] = useState<any>(null);
  useEffect(() => {
    const fetchData = async () => {
      const { data: fetchedData } = await supabase
        .from('team')
        .select('team_name, users')
        .match({ id: evaluateAssignmentData.team_id });

      const myTeam = fetchedData && fetchedData[0];
      setTeamData(myTeam);
    };

    fetchData();
  }, [evaluateAssignmentData.team_id]);
  console.log(teamData);
  return (
    <div>
      <div
        className='flex space-x-1  cursor-pointer items-center text-primary'
        onClick={() => setEvaluateAssignmentData(null)}
      >
        <IoIosArrowBack />
        <p>Back</p>
      </div>
      {teamData && (
        <div className='mt-5'>
          <div className='text-xl font-semibold'>{teamData.team_name} </div>
          <div className='text-gray-400 font-semibold'>
            {teamData.users.length} Members{' '}
          </div>
          <TeamMembers teamData={teamData} />
        </div>
      )}
      <div className='text-primary font-semibold mt-6 mb-2'>
        Team Submission
      </div>
      <div className='px-4 prose prose-sm max-w-none '>
        {htmlparser(evaluateAssignmentData.solution)}
      </div>
      <UploadScore
        evaluateAssignmentData={evaluateAssignmentData}
        teamId={teamId}
        setEvaluateAssignmentData={setEvaluateAssignmentData}
      />
    </div>
  );
};

export default EvaluateView;
