import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import TestCases from './TestCases';
import { useGetUserQuery } from '../profile/userSlice';
import { supabase } from 'config/supabaseCOnfig';

const TestCode = ({
  testCasesPassed,
  setTestCasesPassed,
  submitCode,
  setSubmitCode,
  exercise,
  code,
  setProcessing,
  outputDetails,
  link,
}: {
  testCasesPassed: number;
  submitCode: boolean;
  setSubmitCode: any;
  exercise: any;
  code: any;
  setProcessing: Dispatch<SetStateAction<boolean>>;
  setTestCasesPassed: Dispatch<SetStateAction<number>>;
  outputDetails: any;
  link: any;
}) => {
  const bootcamp_id = 2;
  const numTestCases = exercise.test_cases.length;

  const { data } = useGetUserQuery();
  const userId = data?._id;

  const exerciseId = exercise.id;
  const submission = code;

  const memory = outputDetails?.memory;
  const time = outputDetails?.time;

  const [message, setMessage] = useState('');

  useEffect(() => {
    const submitFn = async () => {
      setMessage('');
      const { data: fetchedData } = await supabase
        .from('team')
        .select('users, id');
      const myTeam = fetchedData?.find(({ users }: { users: any }) =>
        users.includes(userId)
      );
      const teamId = myTeam?.id;
      if (!userId) {
        setMessage('Something went wrong! No User ID');
      }
      if (userId) {
        const { data, status } = await supabase
          .from('submissions')
          .select()
          .match({ user_id: userId, exercise_id: exerciseId, bootcamp_id });
        if (status === 200 && data?.length) {
          setMessage('Exercise already submmited,Updating...');
          const submissionsId = data[0].id;
          const alreadySubmission_number = data[0].submission_number || 0;
          const updateData = {
            submission,
            submission_number: alreadySubmission_number + 1,
            memory,
            time,
            user_id: userId,
            link: link,
          };
          const { error } = await supabase
            .from('submissions')
            .update(updateData)
            .match({ id: submissionsId });
          if (!error) {
            setMessage('Submission updated successfully');
            setTestCasesPassed(0);
          }
        }
        if (status === 200 && !data?.length) {
          const submissionData = {
            exercise_id: exerciseId,
            user_id: userId,
            bootcamp_id: bootcamp_id,
            submission,
            status: 'PASSED',
            submission_number: 1,
            memory,
            time,
            num_test_cases_passed: testCasesPassed,
            link: link,
          };
          const { error } = await supabase
            .from('submissions')
            .insert(submissionData);

          if (error) {
            setMessage('Something went wrong!');
          } else {
            setMessage('Submissions done successfully');
            setTestCasesPassed(0);
          }
        }
      }
    };

    if (numTestCases === testCasesPassed) {
      submitFn();
    }
  }, [
    exerciseId,
    memory,
    numTestCases,
    submission,
    testCasesPassed,
    time,
    userId,
  ]);

  return (
    <>
      {exercise.test_cases.map((testCase: any, index: number) => {
        return (
          <TestCases
            testCaseNum={index}
            testCase={testCase}
            submitCode={submitCode}
            setSubmitCode={setSubmitCode}
            exercise={exercise}
            code={code}
            setProcessing={setProcessing}
            outputDetails={outputDetails}
            testCasesPassed={testCasesPassed}
            setTestCasesPassed={setTestCasesPassed}
          />
        );
      })}
      <div> {message} </div>
    </>
  );
};

export default TestCode;
