import React from 'react';

interface allProps {
  totalQuestion: any;
}

const Instructions = (props: allProps) => {
  return (
    <div className='m-1 bg-gray-50 p-2 sm:p-8'>
      <div className='ml-2'>
        <div className='text-primary font-semibold mb-4'>Format</div>
        <div className='mb-2 sm:mb-6'>
          Total Questions: {props.totalQuestion} MCQs
        </div>
        <div className='text-primary font-semibold mb-4'>Instructions</div>
        <ul className='list-disc'>
          <li>Click on "Start Quiz" to begin</li>
          <li>
            You can navigate through questions using Previous and Next Buttons
          </li>
          <li>
            Remember to finish on time and click on the submit button. If the
            time runs out, your answer will be auto-submitted
          </li>
          <li>
            You can preview and edit your answers at the end, if you are able to
            finish all your sections before time
          </li>
          <li>
            Post submission, you will be able to see your percentage performance
            and unlock your certificate
          </li>
          <li>
            Incase you don't meet the performance criteria of 60%, you can
            re-take the quiz after 24 hours
          </li>
        </ul>
        <div className='mt-2 sm:mt-6 ml-1 font-bold'>Note:</div>
        <ul className='list-disc'>
          <li>
            You can not close the tab once the assessment has started, otherwise
            your assessment will be auto-submitted
          </li>
          <li>
            In case of any network issues, please raise a query to the Belong
            Team
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Instructions;
