import { useState, useEffect } from 'react';
import classNames from 'classnames';

const aboutInfos = [
  {
    index: 0,
    number: 1,
    heading: 'Belong Profile',
    text: 'Showcase your professional journey',
    active: false,
    color: 'to-[#fee3ea80]',
    bg: 'bg-[#fee3ea80]',
  },
  {
    index: 1,
    number: 2,
    heading: 'Aptitude Test',
    text: ' Evaluate your workplace readiness',
    active: true,
    color: 'to-[#a1c0fde6]',
    bg: 'bg-[#a1c0fde6]',
  },
  {
    index: 2,
    number: 3,
    heading: 'Domain Test',
    text: 'Assess your domain knowledge',
    active: false,
    color: 'to-[#c2fffb80]',
    bg: 'bg-[#c2fffb80]',
  },
];

const AboutInfo = () => {
  const [currentActive, setCurrentActive] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentActive((currentActive + 1) % 3);
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [currentActive]);

  return (
    <div className='md:grid md:grid-cols-3 md:space-x-4 lg:space-x-8 '>
      {aboutInfos.map(({ index, number, heading, text, color, bg }) => {
        return (
          <div
            className='mb-6 lg:mb-20'
            key={number}
            // onMouseEnter={() => {
            //   setCurrentActive(index);
            // }}
            // onMouseLeave={() => {
            //   setCurrentActive(null);
            // }}
          >
            <div
              className={classNames(
                'flex h-full rounded-2xl py-4 px-2 lg:px-4 bg-gradient-to-b from-[#F6F6F6]/50 ' +
                  color,
                { 'text-[#AFE3E7]': !(currentActive === index) },
                { 'text-primary': currentActive === index }
              )}
            >
              <p className='font-semibold  text-4xl lg:text-6xl mr-4'>
                {number}
              </p>
              <div>
                <p className='font-extrabold  text-lg lg:text-2xl lg:pt-2'>
                  {heading}
                </p>
                <p
                  className={classNames(
                    'lg:text-lg mt-2 lg:mt-4',
                    { 'text-textColor-main ': currentActive === index },
                    {
                      'text-textColor-lightest': !(currentActive === index),
                    }
                  )}
                >
                  {text}
                </p>
              </div>
            </div>
            {/* <div className={classNames('mt-8 h-4 w-full ' + bg)}></div> */}
          </div>
        );
      })}
    </div>
  );
};

export default AboutInfo;
