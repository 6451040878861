import NavigationBar from './NavigationBar';
// import { useGetUserTicketQuery } from './querySlice';
// import Loading from 'components/global/layout/Loading';

const QueryPanel = () => {
  // const { data, isSuccess, isLoading } = useGetUserTicketQuery({ batchId });
  return (
    <div className='  md:border rounded-b-lg text-textColor-lighter '>
      {/* {isLoading && <Loading />} */}
      <div>
        <div className='px-4 md:px-8 space-y-14'>
          <NavigationBar />
        </div>
      </div>
    </div>
  );
};

export default QueryPanel;
