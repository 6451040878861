import { useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import Answer from './Answer';

const FaqTypes = (props) => {
  const [showText, setShowText] = useState(false);
  return (
    <div className='m-4 py-5 px-4 md:px-10 lg:px-16 border border-1 border-primary rounded-xl'>
      <div
        className='flex justify-between'
        onClick={() => {
          setShowText(!showText);
        }}
      >
        <div className=' font-medium text-textColor-main hover:text-primary hover:cursor-pointer transition-all ease-in-out duration-300 text-lg'>
          {props.question}
        </div>
        <div className='cursor-pointer'>
          {showText ? <FaAngleUp /> : <FaAngleDown />}
        </div>
      </div>
      <AnimatePresence>
        {showText && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.3 }}
            key='answer'
          >
            <Answer content={props.content} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
// && <QAs questions={props.questions} />}

export default FaqTypes;
