import { AiOutlineArrowRight } from 'react-icons/ai';

const CustomRightArrow = ({ onClick }) => {
  return (
    <button
      onClick={() => onClick()}
      className='bg-primary-light hover:bg-primary h-10 w-10 rounded-full absolute  right-3 md:right-1 lg:right-3 flex items-center justify-center cursor-pointer transition-all ease-in-out duration-300'
    >
      <AiOutlineArrowRight />
    </button>
  );
};

export default CustomRightArrow;
