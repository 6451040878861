import { Link } from 'react-router-dom';

const MapPart2 = () => {
  return (
    <div>
      <div className='grid grid-col-12 text-center'>
        <p className='text-textColor-main lg:font-semibold font-medium lg:text-3xl text-xl'>
          Find Global Opportunities with
          <span className='text-primary'> Belong Score </span>
        </p>
        <div className='mt-6 mx-auto w-full'>
          <Link
            to='/login'
            className='text-primary bg-textColor-lighter px-12 py-2 rounded-lg lg:mt-18 mt-6 font-bold  lg:text-xl shadow mb-8'
          >
            Try For Free
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MapPart2;
