import React, { useState } from 'react';
import TestBar from './TestBar';
import Instructions from './Instructions';
import {
  // useCurrentCapstoneQuery,
  useGetCapstoneQuizQuery,
  // useStartCapstoneTestMutation,
} from './CapstoneQuizSlice';
import Assessment from './assessment';
import ShowScore from './ShowScore';
import { supabase } from 'config/supabaseCOnfig';
import { useGetUserQuery } from 'components/profile/userSlice';

interface allProps {
  setShowQuiz: any;
  enrollmentId: String;
  capstoneId: String;
  enrollData: any;
  setReFetch: any;
}

const Index = (props: allProps) => {
  const [showScores, setShowScores] = useState(false);
  const { data: resData } = useGetCapstoneQuizQuery({
    capstoneId: props.capstoneId,
  });

  const { data: userData } = useGetUserQuery();
  const userId = userData?._id;

  const enrollData = props.enrollData;

  const userQuizData = enrollData.quiz_submission?.find(
    (quiz: any) => quiz.user_id === userId
  );

  // const [startTest] = useStartCapstoneTestMutation();

  // const startTestObject = {
  //   enrollmentId: props.enrollmentId,
  //   responses: resData,
  // };
  const [loading, setLoading] = useState(false);

  const startTest = async () => {
    setLoading(true);
    const { data, status } = await supabase
      .from('capstone_submissions')
      .select()
      .match({ id: props.enrollmentId });
    if (status === 200 && data?.length) {
      const quizData = data[0]?.quiz_submission || [];
      const currentuserData = quizData.find(
        ({ user_id }: any) => user_id === userId
      );
      if (currentuserData) {
        props.setReFetch((pre: any) => !pre);
        props.setShowQuiz(false);
      } else {
        const finalQuizData = quizData.concat({
          responses: resData,
          start_time: Date.now(),
          user_id: userId,
        });
        await supabase
          .from('capstone_submissions')
          .update({ quiz_submission: finalQuizData })
          .match({ id: props.enrollmentId });
        props.setReFetch((pre: any) => !pre);
      }
    }
  };

  return (
    <div className='bg-white'>
      {showScores ? (
        <ShowScore
          capstoneId={props.capstoneId}
          setShowQuiz={props.setShowQuiz}
        />
      ) : (
        <>
          {' '}
          {enrollData &&
          userQuizData?.responses &&
          !userQuizData?.finish_time ? (
            <Assessment
              enrollData={enrollData}
              setShowScores={setShowScores}
              setShowQuiz={props.setShowQuiz}
              setReFetch={props.setReFetch}
            />
          ) : (
            <>
              <button
                className='text-primary m-2'
                onClick={() => props.setShowQuiz(false)}
              >
                {'<'} Back
              </button>
              <TestBar totalQuestion={resData && resData.length} />
              <Instructions totalQuestion={resData && resData.length} />
              {resData && resData.length && (
                <div className='flex justify-end mr-4'>
                  <button
                    className='px-4 py-2 bg-primary-light rounded'
                    disabled={loading}
                    onClick={() => {
                      startTest();
                    }}
                  >
                    {loading ? 'Loading...' : 'Start Test'}
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Index;
