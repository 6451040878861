import { useState, useEffect } from 'react';
import FullView from './FullView';
import Info from './Info';
import TaskOne from './TaskOne';
import TaskTwo from './TaskTwo';
import { supabase } from 'config/supabaseCOnfig';
import { useGetUserQuery } from 'components/profile/userSlice';

const Index = () => {
  const [teamData, setTeamData] = useState<any>(null);
  const [view, setView] = useState('FULL');
  const { data: userData } = useGetUserQuery();
  const userId = userData?._id;

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('team')
        .select('users, id')
        .match({ bootcamp_id: 2 });
      const myTeam = fetchedData?.find(({ users }) => users.includes(userId));
      setTeamData(myTeam);
    };
    setDataFn();
  }, [userId]);

  return (
    <div>
      <Info />
      {view === 'FULL' && <FullView setView={setView} teamData={teamData} />}
      {view === 'TASK1' && <TaskOne setView={setView} teamData={teamData} />}
      {view === 'TASK2' && <TaskTwo setView={setView} teamData={teamData} />}
    </div>
  );
};

export default Index;
