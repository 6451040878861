import React, { useState } from 'react';
import Tasks from './Tasks';
import TaskView from './taskView';

const TaskStatus = ({ submissions, assignments, setReFetch, teamId }: any) => {
  const [taskView, setTaskView] = useState(null);

  const finalData = assignments?.map((assignment: any) => {
    const teamSubmission = submissions.find(
      ({ assignment_id }: any) => assignment_id === assignment.id
    );
    return { ...assignment, teamSubmission };
  });
  const upcoming = finalData?.filter((el: any) => !el.teamSubmission);
  const completed = finalData?.filter((el: any) => el.teamSubmission);

  return (
    <div>
      {taskView ? (
        <>
          <TaskView
            assignment={taskView}
            setTaskView={setTaskView}
            setReFetch={setReFetch}
            teamId={teamId}
          />
        </>
      ) : (
        <Tasks
          setTaskView={setTaskView}
          upcoming={upcoming}
          completed={completed}
          teamId={teamId}
        />
      )}
    </div>
  );
};

export default TaskStatus;
