import React from 'react';
import { useCheckIncompleteQuery } from '../QuizSlice';
// import { Outlet } from 'react-router-dom';
import Assessment from '../assessment';
import Loading from 'components/global/layout/Loading';
import CheckQuiz from './CheckQuiz';

const Index = () => {
  //Checks if a test is already in progress or fresh test
  const { isLoading, isSuccess, data } = useCheckIncompleteQuery();

  const condition = isSuccess && Boolean(data);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : condition ? (
        <Assessment data={data} />
      ) : (
        <CheckQuiz />
      )}
    </>
  );
};

export default Index;
