import { useField } from 'formik';

export const TextInput = ({ label, ...props }: any) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <div className='grid grid-cols-12  items-center'>
      <label
        className='col-span-2 text-gray-600 font-semibold text-xs md:text-base'
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <input
        className='col-span-10 rounded shadow border focus:outline-none w-full px-4 py-1 text-gray-600 text-sm font-semibold'
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className='text-red-500 text-xs ml-2'>{meta.error}</div>
      ) : null}
    </div>
  );
};
export const TextArea = ({ label, ...props }: any) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <div className='grid grid-cols-12 '>
      <label
        className='col-span-2 text-gray-600 font-semibold text-xs md:text-base'
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <textarea
        className='col-span-10 rounded shadow border focus:outline-none w-full h-32 px-4 mr-6 py-1 text-gray-600 text-sm font-semibold'
        {...field}
        {...props}
      ></textarea>
      {meta.touched && meta.error ? (
        <div className='text-red-500 text-xs ml-2'>{meta.error}</div>
      ) : null}
    </div>
  );
};
