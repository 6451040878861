import classNames from 'classnames';
import React, { useState } from 'react';
import BasicLevel from './BasicLevel';
import IntermediateLevel from './IntermediateLevel';
import AdvanceLevel from './AdvanceLevel';

const CapstoneLevelSection = () => {
  const [level, setLevel] = useState('basic');
  return (
    <div className='space-y-8'>
      <div>
        <p className='mb-2 text-2xl font-medium'> Capstones</p>
        <p className='text-[#B0B0B0] font-semibold mb-4'>
          Capstones unlocked for you{' '}
        </p>
      </div>
      {/*START: capstone levels tabs */}
      <div className='flex gap-6'>
        {/* basic level tab */}
        <div
          onClick={() => setLevel('basic')}
          className={classNames(
            'px-6 py-2 w-1/4 rounded-md   flex items-center justify-between cursor-pointer',
            { 'bg-[#FFDCC8]': level === 'basic' },
            { 'border shadow-md': level !== 'basic' }
          )}
        >
          <p className={classNames('', { 'font-bold': level === 'basic' })}>
            BASIC
          </p>
          <div>
            <svg
              width='50'
              height='50'
              viewBox='0 0 50 50'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect y='0.939453' width='25' height='23.6111' fill='#F5844C' />
              <path
                d='M25 0.939453H30C41.0457 0.939453 50 9.89376 50 20.9395V24.5506H25V0.939453Z'
                fill='#FFA87D'
              />
              <rect x='25' y='24.5508' width='25' height='25' fill='#F5844C' />
              <rect
                x='3'
                y='27.5508'
                width='19'
                height='19'
                rx='9.5'
                stroke='#FFA87D'
                stroke-width='6'
              />
            </svg>
          </div>
        </div>
        {/* Intermediate level tab */}
        <div
          onClick={() => setLevel('intermediate')}
          className={classNames(
            'px-6 py-2 w-1/4 rounded-md   flex items-center justify-between cursor-pointer',
            { 'bg-[#FFDCC8]': level === 'intermediate' },
            { 'border shadow-md': level !== 'intermediate' }
          )}
        >
          <p
            className={classNames('', {
              'font-bold': level === 'intermediate',
            })}
          >
            INTERMEDIATE
          </p>
          <div>
            <svg
              width='51'
              height='50'
              viewBox='0 0 51 50'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                x='3.5'
                y='3'
                width='19'
                height='19'
                rx='9.5'
                stroke='#7D95B7'
                stroke-width='6'
              />
              <rect
                x='28.5'
                y='27.9395'
                width='19'
                height='19'
                rx='9.5'
                stroke='#7D95B7'
                stroke-width='6'
              />
              <rect
                x='0.5'
                y='24.9395'
                width='25'
                height='25'
                fill='#98AAC3'
                fill-opacity='0.6'
              />
              <path
                d='M25.5 0H30.5C41.5457 0 50.5 8.95431 50.5 20V25H25.5V0Z'
                fill='#98AAC3'
                fill-opacity='0.6'
              />
            </svg>
          </div>
        </div>
        {/*Advance level tab */}
        <div
          onClick={() => setLevel('advance')}
          className={classNames(
            'px-6 py-2 w-1/4 rounded-md   flex items-center justify-between cursor-pointer',
            { 'bg-[#FAC848]/10': level === 'advance' },
            { 'border shadow-md': level !== 'advance' }
          )}
        >
          <p
            className={classNames('', {
              'font-bold': level === 'advance',
            })}
          >
            ADVANCE
          </p>
          <div>
            <svg
              width='50'
              height='50'
              viewBox='0 0 50 50'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M46.7639 23H28.2361L37.5 4.47214L46.7639 23Z'
                stroke='#FAC848'
                stroke-width='4'
              />
              <rect
                x='3'
                y='3'
                width='19'
                height='19'
                rx='9.5'
                stroke='#FCE6AC'
                stroke-width='6'
              />
              <rect
                x='28'
                y='28'
                width='19'
                height='19'
                rx='9.5'
                fill='#FDE7AF'
                stroke='#FCE6AC'
                stroke-width='6'
              />
              <path
                d='M25 50H20C8.95431 50 0 41.0457 0 30V25H25V50Z'
                fill='#FAC848'
              />
            </svg>
          </div>
        </div>
      </div>
      {/*END: capstone levels tabs end */}
      {/*START: Component for each level, each Component will have locked and unlocked content inside it */}
      {level === 'basic' && <BasicLevel />}
      {level === 'intermediate' && <IntermediateLevel />}
      {level === 'advance' && <AdvanceLevel />}
    </div>
  );
};

export default CapstoneLevelSection;
