import {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { toast } from 'react-toastify';
import OutputDetails from './editor/OutputDetails';

const TestCases = ({
  testCaseNum,
  testCase,
  submitCode,
  setSubmitCode,
  exercise,
  code,
  setProcessing,
  outputDetails,
  setTestCasesPassed,
}: {
  testCaseNum: number;
  testCase: any;
  submitCode: boolean;
  setSubmitCode: any;
  exercise: any;
  code: any;
  setProcessing: Dispatch<SetStateAction<boolean>>;
  outputDetails: any;
  testCasesPassed: number;
  setTestCasesPassed: Dispatch<SetStateAction<number>>;
}) => {
  const testCaseInput = testCase.input.join('');
  const testCaseOutput = testCase.output;
  const [runningTestCases, setRunningTestCases] = useState(true);
  const [output, setoutput] = useState<any>([]);

  const submit = useCallback(() => {
    setRunningTestCases(true);
    setProcessing(true);
    const formData = {
      language_id: 71,
      // encode source code in base64
      source_code: btoa(code),
      stdin: btoa(testCaseInput),
    };

    const options: AxiosRequestConfig<any> = {
      method: 'POST' as Method,
      url: process.env.REACT_APP_RAPID_API_URL,
      params: { base64_encoded: 'true', fields: '*' },
      headers: {
        'content-type': 'application/json',
        'Content-Type': 'application/json',
        'X-RapidAPI-Host': process.env.REACT_APP_RAPID_API_HOST || '',
        'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY || '',
      },
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        const token = response.data.token;
        checkStatus(token);
        setProcessing(false);
      })
      .catch((err) => {
        const error = err.response ? err.response.data : err;
        // get error status
        const status = err.response.status;
        if (status === 429) {
          console.log('too many requests', status);
          showErrorToast(`Quota of requests exceeded for the Day!`, 10000);
        }
        setProcessing(false);
        setRunningTestCases(false);
        console.log('catch block...', error);
      });
  }, [setProcessing]);

  useEffect(() => {
    if (submitCode && code && exercise) {
      submit();
    }
  }, [submitCode, exercise, submit]);

  const checkStatus = async (token: any) => {
    const options = {
      method: 'GET',
      url: process.env.REACT_APP_RAPID_API_URL + '/' + token,
      params: { base64_encoded: 'true', fields: '*' },
      headers: {
        'X-RapidAPI-Host': process.env.REACT_APP_RAPID_API_HOST,
        'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
      },
    };
    try {
      // @ts-ignore
      const response = await axios.request(options);
      const statusId = response.data.status?.id;
      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        setTimeout(() => {
          checkStatus(token);
        }, 2000);
        return;
      } else {
        //success
        setProcessing(false);
        setRunningTestCases(false);
        // console.log('Input:', atob(response?.data?.stdin));
        // console.log('Input:', atob(response?.data?.stdout));
        const t =
          atob(response?.data?.stdout) !== null
            ? `${atob(response?.data?.stdout)}`
            : null;
        setoutput(t);
        return;
      }
    } catch (err) {
      setProcessing(false);
      setRunningTestCases(false);
      showErrorToast();
    }
  };

  const showErrorToast = (msg?: string, timer?: number) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: 'top-right',
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const cleanOutput = (inputArray: any) => {
    if (inputArray.length) {
      return inputArray.split(': ')[inputArray.split(': ').length - 1].trim();
    }
  };

  const final = cleanOutput(output);
  const expected = testCaseOutput;
  useEffect(() => {
    if (final == expected) {
      setTestCasesPassed((pre) => pre + 1);
    }
  }, [expected, setTestCasesPassed, final]);

  return (
    <div className='mt-4'>
      {runningTestCases ? (
        'Running Testcase...'
      ) : final == expected ? (
        <>
          {outputDetails && testCaseNum === 0 && (
            <OutputDetails outputDetails={outputDetails} />
          )}
          <div>
            Test Case {testCaseNum + 1}:{' '}
            <span className='text-green-700 font-semibold'>Passed</span>
          </div>
        </>
      ) : (
        <div>
          Test Case {testCaseNum + 1}:{' '}
          <span className='text-red-400 font-semibold'>Failed</span>
        </div>
      )}
    </div>
  );
};

export default TestCases;
