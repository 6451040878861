import { Link, NavLink } from 'react-router-dom';
import sidebarConfig from '../../config/sidebarConfig.json';
import classNames from 'classnames';
import { FaLock } from 'react-icons/fa';
import { useGetUserQuery } from 'components/profile/userSlice';

interface props {
  showSidebar: boolean;
  setshowSidebar: Function;
}

const Sidebar: React.FC<props> = ({ showSidebar, setshowSidebar }) => {
  const { data } = useGetUserQuery();
  const userEmail = data?.email || '';
  const allUser = [
    'mukesh@ingeniousfaces.com',
    'pragya@ingeniousfaces.com',
    'abhishek@ingeniousfaces.com',
    'rashika@ingeniousfaces.com',
    'surabhi@ingeniousfaces.com',
    'priya@ingeniousfaces.com',
    'demo@ingeniousfaces.com',
    'creatorstuffn@gmail.com',
    'kameshkamal12@gmail.com',
    'navieenramesh@gmail.com',
    '19b003@nssce.ac.in',
    'archanasv10082001@gmail.com',
    'jabaselvia@gmail.com',
    'elavarasant.tamil@gmail.com',
    'revathiarutselvan16@gmail.com',
    'prsakthi2292001@gmail.com',
    'akshahanaedv@gmail.com',
    'jyotisangeeta2002@gmail.com',
    'sanchari.saha1505@gmail.com',
    'vasanthaprasath12345@gmail.com',
    'rangnathbn2023@gmail.com',
    'pruthidevansh@gmail.com',
    'sarumathitvm02@gmail.com',
    'aritri.mitra.002@gmail.com',
    'muhammedzuhailmj@gmail.com',
    'swethakumaraguru@gmail.com',
    'sayanmallick14112002@gmail.com',
    'shyamasamanta3@gmail.com',
    'ritiksarma6@gmail.com',
    'madhuharsha2517@gmail.com',
    'parthapmaz123@gmail.com',
    'sahanajgowda2019@gmail.com',
    '2002karthigae@gmail.com',
    'amimollaramij@gmail.com',
    'reshmibiswas258@gmail.com',
    'praveen2982001@gmail.com',
    'irajdipsaha78@gmail.com',
    'yneha4241@gmail.com',
    'kmkavana5@gmail.com',
    'nithya29bns@gmail.com',
    'noormohd2708@gmail.com',
    'sonalinishad963@gmail.com',
    'pushpitdutta94@gmail.com',
    'arijitroyapdj@gmail.com',
    'shravyacs2021@gmail.com',
    'prathamgaur17@gmail.com',
    'rishabh.rishabh.uniyal@gmail.com',
    'deviaredemo@ingeniousfaces.com',
  ];
  const bootcapmUser = allUser.includes(userEmail);
  return (
    <>
      <nav
        className={classNames('space-y-2', {
          'hidden md:block': !showSidebar,
        })}
      >
        {sidebarConfig.map((element) => {
          return (
            <div key={element.path}>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? 'bg-primary-lightest rounded-full w-full inline-block text-gray-500 px-6 py-4'
                    : element.isDisabled
                    ? 'bg-gray-100 rounded-full w-full inline-block px-6 py-4 pointer-events-none'
                    : 'hover:bg-primary-lightest transition-all ease-in-out duration-300 text-gray-500 rounded-full w-full inline-block px-6 py-4'
                }
                to={element.path}
                onClick={() => setshowSidebar(false)}
              >
                <div className='flex justify-between'>
                  <div className='flex items-center space-x-4 '>
                    <img
                      className='object-contain'
                      src={element.icon}
                      alt='icons'
                    />
                    <span className='font-semibold text-textColor-main/60'>
                      {element.text}
                    </span>
                  </div>

                  {element.isDisabled && (
                    <FaLock className='text-gray-400 mt-1' />
                  )}
                </div>
              </NavLink>
            </div>
          );
        })}
      </nav>
    </>
  );
};

export default Sidebar;
