import React, { useState } from 'react';
import { FaAngleLeft } from 'react-icons/fa';

import PhoneInput from 'react-phone-input-2';
import TakePayment from './TakePayment';
import { Link } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import { API_URL } from 'config/API_LINKS';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextInput } from 'components/layout/FormElement';

const availableOffer = [
  'RAKAGR10',
  'ARIMIT10',
  'SARTHM10',
  'GUNAVI10',
  'KAMEK10',
  'GUNYAS10',
];

const BasicDetails = ({ setCurrentScreen }) => {
  const [showPayment, setShowPayment] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('+91');
  const [orderCollectionId, setOrderCollectionId] = useState(null);

  const [couponCode, setCouponCode] = useState('');
  const [amount, setAmount] = useState('3500');
  const [showCouponMessage, setShowCouponMessge] = useState(false);

  return (
    <div className='p-4 sm:p-16'>
      <div className='sm:w-3/4 lg:w-1/2 mx-auto mt-8 space-y-6'>
        <Link to='/'>
          <div className='flex items-center text-gray-600 cursor-pointer'>
            <FaAngleLeft />
            <button className='ml-2'> Back</button>
          </div>
        </Link>
        <div className='space-y-3'>
          <p className='font-bold text-lg'>
            Career Bootcamp in Data Science Registration Form
          </p>
          <p>
            Please fill in the details below to proceed to payment and reserve
            your your spot in the the online 6 week career bootcamp for the June
            cohort
          </p>
        </div>
        <div>
          <Formik
            // innerRef={formikRef}
            enableReinitialize={true}
            initialValues={{
              name: '',
              email: '',
              phone: '',
              orderType: 'BootcampMay23',
              couponCode: '',
            }}
            validationSchema={Yup.object({
              name: Yup.string().required('Name is required'),
              email: Yup.string().email().required(),
              phone: Yup.string()
                .required()
                .matches(
                  new RegExp('^[0-9]{12,13}$'),
                  'Phone number is not valid'
                ),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setEmail(values.email);
              setPhone(values.phone);
              setCouponCode(values.couponCode);
              const updatedData = { ...values };
              delete updatedData.couponCode;
              if (values.couponCode) {
                if (availableOffer.includes(values.couponCode)) {
                  setAmount('3150');
                  setShowCouponMessge(true);
                }
              }
              axios({
                method: 'POST',
                url: API_URL + '/order/add-order',
                data: updatedData,
              })
                .then(async function (response) {
                  if (response?.data?.data?.orderId) {
                    setCurrentScreen('SUCCESS');
                  } else {
                    setOrderCollectionId(response?.data?.data?._id);
                    setShowPayment(true);
                    setPaymentLoading(true);
                    setSubmitting(false);
                  }
                })
                .catch(function (error) {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, setFieldValue, errors }) => (
              <Form>
                <div className='space-y-4 border-2 shadow-md p-4 rounded-2xl mb-6 font-medium'>
                  {/* Student details */}
                  <div className='space-y-4'>
                    <p className='text-2xl font-semibold'>Student Details</p>
                    <div className='space-y-1 w-[90%] md:w-2/3'>
                      <p className='ml-2'>Name</p>
                      <TextInput name='name' type='text' label='Name' />
                    </div>
                    <div className='items-center space-y-4 md:space-y-0 md:space-x-10'>
                      <div className='space-y-1  w-[90%]  md:w-2/3'>
                        <p className='ml-2'>Email</p>
                        <TextInput name='email' type='text' label='Email' />
                      </div>
                    </div>
                    <div className='mt-8 ml-2 md:w-2/3 lg:w-1/3'>
                      <label className='block mb-2 text-sm text-left'>
                        Contact Number
                      </label>
                      <div className='w-full relative'>
                        <PhoneInput
                          preferredCountries={['in', 'za']}
                          country={'in'}
                          inputStyle={{ width: '100%' }}
                          onChange={(phone) => setFieldValue('phone', phone)}
                          countryCodeEditable={false}
                          inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                          }}
                        />
                        <p className='text-sm text-red-700'>{errors.phone} </p>
                      </div>
                    </div>
                    <div className='space-y-1 w-[90%] md:w-2/3'>
                      <p className='ml-2'>Coupon Code (if you Have)</p>
                      <TextInput
                        name='couponCode'
                        type='text'
                        label='couponCode'
                      />
                    </div>
                  </div>

                  <div className='flex justify-end'>
                    <button
                      disabled={isSubmitting}
                      type='submit'
                      className='border px-4 text-lg font-medium py-1 text-white bg-primary rounded-lg '
                    >
                      {isSubmitting ? 'Submitting' : 'Next'}
                    </button>
                  </div>
                  {showCouponMessage && (
                    <div className='text-green-500'>
                      {' '}
                      Coupon code applied successfully.{' '}
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {showPayment && orderCollectionId && (
        <TakePayment
          couponCode={couponCode}
          email={email}
          phone={phone}
          amount={amount}
          setCurrentScreen={setCurrentScreen}
          setPaymentLoading={setPaymentLoading}
          setShowPayment={setShowPayment}
          orderCollectionId={orderCollectionId}
        />
      )}
      {paymentLoading && (
        <div className='justify-center items-center flex overflow-x-auto overflow-y-auto fixed inset-0 outline-none focus:outline-none bg-gray-50 bg-opacity-80 z-50'>
          <div className=' w-screen my-6 mx-auto '>
            <div className='border-0 p-8 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none'>
              {' '}
              Loading{' '}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicDetails;
