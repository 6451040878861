import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import Loading from 'components/global/layout/Loading';
import { useGetUserQuery } from 'components/profile/userSlice';
import Category from './Category';
import { supabase } from 'config/supabaseCOnfig';
import { useState } from 'react';

const Eda = () => {
  const { data: userData, isLoading } = useGetUserQuery();
  const userId = userData?._id;
  const [allBasicQuestionSolved, setAllBasicQuestionSolved] = useState(false);

  const basicQuestions = [19, 20, 21, 40, 41];

  // functions for basic questions
  async function fetchData() {
    const { data } = await supabase
      .from('submissions')
      .select()
      .match({ user_id: userId, bootcamp_id: 2 })
      .in('exercise_id', basicQuestions);
    if (data && data.length === 5) {
      setAllBasicQuestionSolved(true);
    } else {
      setAllBasicQuestionSolved(false);
    }
  }

  if (userId) {
    fetchData();
  }
  const questions = [
    {
      question:
        'You are given a sequence of integers representing the scores of students in a class. ',
      number: 19,
    },
    {
      question:
        'You are given a sequence of integers representing the ages of participants in a marathon',
      number: 20,
    },
    {
      question:
        'You are given a sequence of integers representing the heights of a group of people.',
      number: 21,
    },
    {
      question:
        'Using the matplotlib library in Python, create a line plot to visualize the relationship between the',
      number: 40,
    },
    {
      question:
        'Using the matplotlib library in Python, create a line plot to compare the values in the y1 and y2 ',
      number: 41,
    },
  ];
  // const questionsIntermediate = [
  //   {
  //     question:
  //       'You are given a clean dataset sales.csv. Create a bar plot to visualize the total revenue..',
  //     number: 42,
  //   },
  //   {
  //     question:
  //       'Using the matplotlib library in Python, create a figure with two subplots side by side.        ',
  //     number: 67,
  //   },
  //   {
  //     question:
  //       'calculate the average customer age for each country and display the result in descending order.',
  //     number: 64,
  //   },
  //   {
  //     question:
  //       'Calculate the total revenue generated by each product category and sort them in alphabetical order.',
  //     number: 65,
  //   },
  //   {
  //     question: 'Display the month and the corresponding total revenue.',
  //     number: 66,
  //   },
  // ];

  // const questionsAdvanced = [
  //   {
  //     question:
  //       'Given an array of integers A and an integer B, find and return the minimum number',
  //     number: 57,
  //   },
  //   {
  //     question:
  //       'Given an array of integers A, every element appears twice except for one.',
  //     number: 58,
  //   },
  //   {
  //     question: 'Given a number A, check if it is a magic number or not.',
  //     number: 59,
  //   },
  //   {
  //     question:
  //       'Given an array of integers A, find and return the peak element in it.',
  //     number: 60,
  //   },
  //   {
  //     question: 'Given an expression string A, examine whether the pairs...',
  //     number: 61,
  //   },
  // ];

  const edaLogo = (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.8497 2.8139H15.1187V9.6009H11.8507L11.8497 2.8139ZM11.8497 16.7559H15.1187V23.5449H11.8507L11.8497 16.7559ZM11.8497 11.5829H15.1187V14.7859H11.8507L11.8497 11.5829ZM6.59866 8.3939H9.86666V30.9569H6.59766L6.59866 8.3939ZM16.9927 22.3569H20.2617V29.1439H16.9927V22.3569ZM16.9927 8.4019H20.2617V15.1889H16.9927V8.4019ZM16.9927 17.1719H20.2617V20.3749H16.9927V17.1719ZM22.1347 1.0459H25.4037V23.6089H22.1347V1.0459Z'
        fill='#767676'
      />
    </svg>
  );
  return (
    <div className='px-6'>
      <div className='flex justify-between'>
        <div>
          <p className='text-xl'>Data Structures</p>
          <p className='text-gray-400 text-xs mt-2'>Your skill Badges</p>
        </div>
        <div>
          <a href='/DL.pdf' download>
            <button className='py-2 px-8 border-primary border text-sm rounded shadow text-primary'>
              <DocumentArrowDownIcon className='inline-block w-6 mr-2' />
              Resources
            </button>
          </a>
        </div>
      </div>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <div className='mt-8 flex gap-4'>
            {/* badge 1 */}
            <div>
              {allBasicQuestionSolved ? (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat  object-shadow grid items-center'
                  style={{
                    backgroundImage:
                      'url("/images/skills/skill-bg-bronze.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    <div className='flex justify-center'>{edaLogo}</div>
                    <p className='text-center mt-2 font-semibold'>EDA</p>
                    <img
                      src='/images/skills/stars-1.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              ) : (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat grayscale object-shadow grid items-center'
                  style={{
                    backgroundImage: 'url("/images/skills/skill-bg-0.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    <div className='flex justify-center'> {edaLogo}</div>
                    <p className='text-center mt-2 font-semibold'>EDA</p>
                    <img
                      src='/images/skills/stars-1.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <Category
              heading='Novice'
              questions={questions}
              locked={false}
              userId={userId}
            />
            {/* <Category
              heading='Intermediate'
              questions={questionsIntermediate}
              done={false}
              locked={false}
              userId={userId}
            /> */}
            {/* <Category
              heading='Expert'
              questions={questionsAdvanced}
              done={false}
              locked={false}
              userId={userId}
            /> */}
          </div>
        </>
      )}
    </div>
  );
};

export default Eda;
