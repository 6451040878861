import React from 'react';
import { Link } from 'react-router-dom';

const SkillCard = ({
  skill,
  logo,
  question,
  level,
  link,
  barColor,
}: {
  skill: String;
  logo: any;
  question: string;
  level: string;
  link: string;
  barColor: string;
}) => {
  return (
    <div className='border  space-y-3 flex flex-col justify-between grayscale'>
      <div className='p-4 space-y-3'>
        <div className='h-8'>{logo}</div>
        <p className='font-semibold'>{skill}</p>
        {/* progress bar and question progress */}
        <div className='space-y-1'>
          <div className='bg-gray-300 w-full h-2 rounded-l-xl rounded-r-xl'>
            <div
              className={`w-0 h-2 rounded-l-xl`}
              style={{ backgroundColor: barColor }}
            ></div>
          </div>
          <p className='text-gray-500'>{question}</p>
        </div>
        {/* level */}
        <p>{level}</p>
      </div>
      <div style={{ backgroundColor: barColor }}>
        <Link to={link} className='flex justify-between p-4'>
          <p>Learn More</p>
          <div className='h-4'>
            <svg
              width='13'
              height='14'
              viewBox='0 0 13 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4.14231 11.8482C4.05194 11.7304 4.0046 11.6031 4.00029 11.4665C3.99599 11.3298 4.04332 11.2073 4.14231 11.0989L7.86711 7.02052L4.12941 2.91913C4.03902 2.82606 3.99599 2.70266 4.00029 2.54894C4.0046 2.39521 4.05194 2.26886 4.14231 2.1699C4.2499 2.0521 4.36394 1.99556 4.48443 2.00027C4.60493 2.00499 4.71467 2.06153 4.81365 2.1699L8.81118 6.55578C8.87573 6.62645 8.92334 6.69861 8.95401 6.77224C8.98467 6.84588 9 6.92864 9 7.02052C9 7.10652 8.98467 7.1878 8.95401 7.26437C8.92334 7.34095 8.87573 7.41457 8.81118 7.48525L4.82658 11.8482C4.72759 11.9565 4.6157 12.0069 4.49089 11.9992C4.36609 11.9916 4.2499 11.9412 4.14231 11.8482Z'
                fill='#505050'
              />
            </svg>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SkillCard;
