import OurAchievements from './OurAchievements';

const DataSection = () => {
  return (
    <div className='my-12 lg:my-6 mt-24 bg-gray-50 -mx-4 px-4 py-8 md:p-0 md:bg-white '>
      <OurAchievements />
      <p className='col-span-12 text-center font-semibold lg:text-3xl text-2xl text-textColor-lighter lg:mt-14 mt-8'>
        All with One <span className='text-primary'>Belong Score</span>
      </p>
    </div>
  );
};

export default DataSection;
