import TaskCard from './TaskCard';

const Upcoming = ({
  setTaskView,
  assignments,
  teamId,
}: {
  setTaskView: any;
  assignments: any;
  teamId: any;
}) => {
  const sorted_assignments = assignments.sort((a: any, b: any) => {
    return a.task_no - b.task_no;
  });
  return (
    <div>
      {assignments && (
        <>
          <div className='grid grid-cols-1 gap-8 '>
            {sorted_assignments?.map((assignment: any) => {
              return (
                <TaskCard
                  key={assignment.id}
                  assignment={assignment}
                  setTaskView={setTaskView}
                  teamId={teamId}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Upcoming;
