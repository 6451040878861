const ProjectDetails = () => {
  return (
    <div>
      <div className='mt-5'>
        <div className='font-bold text-xl'>Problem statement:</div>
        <div className='mt-2'>
          This project aims at analysing the trends for Electric and Hybrid
          Electric Vehicle bought in different cities of USA. This activity will
          provide insights on some important areas such as which model was most
          in demand, which is the most environmentally viable vehicle etc. These
          derivatives will help the business to deduce strategy based on current
          market trends.
        </div>
      </div>
      <div className='mt-5'>
        <div className='font-bold text-xl'>Learning Objectives:</div>
        <div className='mt-2'>
          <ul className='list-disc px-6'>
            <li>
              Demonstrate the ability to import, clean, modify and fix data,
              exploratory data analysis and perform feature engineering
              techniques.{' '}
            </li>
            <li>
              Demonstrate the ability to use data visualisation in accordance
              with the data and extract useful information.{' '}
            </li>
          </ul>
        </div>
      </div>
      <div className='mt-5'>
        <div className='font-bold text-xl'>Business Objectives:</div>
        <div className='mt-2'>
          <ul className='list-disc px-6'>
            <li>
              To derive the electric and hybrid electric vehicle model that is
              most in demand, which model is environmentally viable.
            </li>
            <li>
              Depending upon the analysis organisation can plan the business
              strategy for enhanced registrations.
            </li>
          </ul>
        </div>
      </div>

      <div className='mt-5'>
        <div className='font-bold text-xl'>Prerequisite skills:</div>
        <div className='mt-2'>
          <ul className='list-disc px-6 font-semibold'>
            <li>Python</li>
            <li>Statistical Knowledge</li>
            <li>Knowledge of Exploratory Data Analysis</li>
            <li>Data Visualisation Knowledge</li>
          </ul>
        </div>
      </div>
      <div className='mt-4 flex justify-between'>
        <div className='font-bold text-xl'>Dataset link: </div>
        <a
          href='https://drive.google.com/file/d/1mJMyiYCsrRSnuq0uncbbhMabFTdNlIPm/view?usp=sharing'
          target='_blank'
          rel='noreferrer'
          className='mr-4 hover:underline text-blue-500 '
        >
          Download
        </a>
      </div>
      <div className='mt-5'>
        <div className='font-bold text-xl'>Data Description:</div>
        <div className='mt-2'>
          The Dataset consists of information on Electric Vehicles and Hybrid
          Electric Vehicles currently registered in different cities of USA
        </div>
      </div>
      <div className='mt-3'>
        <div className='mt-2'>
          <ul className='list-disc px-6 font-semibold'>
            <li>
              Identifier: Vehicle Identification number consisting of first 10
              digits.
            </li>
            <li>City: The city where the vehicle is registered.</li>
            <li>State: The state where the vehicle is registered.</li>
            <li>
              Postal Code: The postal code of the vehicle registration location.
            </li>
            <li>Model Year: The year the vehicle was manufactured.</li>
            <li>Model : The Model of the vehicle.</li>
            <li>
              Vehicle Type: Electric Vehicle (EV) or Hybrid Electric
              Vehicle(HEV)
            </li>
            <li>
              Clean Alternative Fuel Vehicle (CAFV) Eligibility: If the vehicle
              is eligible for clean Alternative Fuel Vehicle Benefits.
            </li>
            <li>
              Electric Range: The range of the vehicle on a full electric
              charge.
            </li>
            <li>
              Base MSRP: The manufacturer’s suggested retail price for the
              vehicle.
            </li>

            <li>
              Legislative District: The legislative district associated with the
              vehicle registration location.
            </li>
            <li>
              Vehicle ID: Unique Identifier assigned by the State Department
            </li>
            <li>Vehicle Location: The precise location of the vehicle.</li>
            <li>
              Electric Utility: The electric utility company associated with the
              vehicle.
            </li>
            <li>
              2020 Census Tract: The census tract where the vehicle is
              registered.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
