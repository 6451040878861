// import React, { useState } from 'react';
import CreateQuery from './CreateQuery';
// import SelectQuery from './SelectQuery';

const NewQuery = () => {
  // const [isCreateNewQuery, SetIsCreateNewQuery] = useState('');
  return (
    <>
      <CreateQuery
      // SetIsCreateNewQuery={SetIsCreateNewQuery}
      // department={isCreateNewQuery}
      // batchId={batchId}
      />
    </>
  );
};

export default NewQuery;
