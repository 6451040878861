import { useGetUserQuery } from 'components/profile/userSlice';
import { supabase } from 'config/supabaseCOnfig';
import { useState, useEffect } from 'react';
import TeamMembers from './TeamMembers';

const Team = () => {
  const [teamData, setTeamData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { data: userData } = useGetUserQuery();
  const userId = userData?._id;
  //   const userId = '6496f228e6607b00143cd67a';

  useEffect(() => {
    const fetchData = async () => {
      const { data: fetchedData } = await supabase
        .from('team')
        .select('team_name, users');
      // .filter('users', '@>', `["${userId}"]`);
      const myTeam = fetchedData?.find(({ users }) => users.includes(userId));
      setTeamData(myTeam);
      setIsLoading(false);
    };
    if (userId) {
      fetchData();
    }
  }, [userId]);

  return (
    <div>
      {!isLoading && teamData && (
        <>
          <div className='mt-4'>
            <div className='border rounded shadow bg-[#DAF2FF] p-4 text-[#1B91D4]'>
              <div className='text-center'> {teamData.team_name} </div>
              {/* <BellIcon className='w-6 inline mr-4' />
              <p className=' inline-block'>
                Your team will be assigned to you before the commencement of the
                bootcamp.
              </p> */}
            </div>
          </div>
          <TeamMembers teamData={teamData} />
        </>
      )}
    </div>
  );
};

export default Team;
