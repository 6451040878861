import React, { useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import NavigationBar from './NavigationBar';
import IntroText from './IntroText';
import QuillSection from './QuillSection';
import EvaluateView from './EvaluateView';

const Index = ({
  setTaskView,
  assignment,
  setReFetch,
  teamId,
}: {
  setTaskView: any;
  assignment: any;
  setReFetch: any;
  teamId: any;
}) => {
  const [evaluateAssignmentData, setEvaluateAssignmentData] = useState(null);

  return (
    <>
      {evaluateAssignmentData ? (
        <EvaluateView
          setEvaluateAssignmentData={setEvaluateAssignmentData}
          evaluateAssignmentData={evaluateAssignmentData}
          teamId={teamId}
        />
      ) : (
        <>
          <div className='p-4 md:p-8  md:border rounded-b-lg pb-8 '>
            <div className='flex space-x-6 mt-6 md:mt-10  border-b-2'>
              <div
                className='flex space-x-1  cursor-pointer items-center text-primary'
                onClick={() => setTaskView(null)}
              >
                <IoIosArrowBack />
                <p>Back</p>
              </div>
              <NavigationBar />
            </div>
            <IntroText assignment={assignment} />
            <QuillSection
              assignment={assignment}
              setReFetch={setReFetch}
              setTaskView={setTaskView}
              setEvaluateAssignmentData={setEvaluateAssignmentData}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Index;
