// import AboutCompany from './AboutCompany';
import { useEffect, useState } from 'react';
import ProjectDetails from './ProjectDetails';
import Tasks from './Tasks';
import { supabase } from 'config/supabaseCOnfig';
// import FishTankSection from './FishTankSection';

const FullView = ({ setView, teamData }: any) => {
  const [taskData, setTaskData] = useState<any>(null);
  const teamId = teamData?.id;

  useEffect(() => {
    const setDataFn = async () => {
      const { data, status } = await supabase
        .from('hackathon')
        .select()
        .match({ team_id: teamId });
      if (status === 200 && data?.length) {
        setTaskData(data[0]);
      }
    };
    if (teamId) {
      setDataFn();
    }
  }, [teamId]);
  return (
    <div>
      {/* <AboutCompany /> */}
      <ProjectDetails />
      {teamData && (
        <>
          <Tasks setView={setView} taskData={taskData} />
          {/* {taskData && <FishTankSection taskData={taskData} teamId={teamId} />} */}
        </>
      )}
    </div>
  );
};

export default FullView;
