import { FaCheck } from 'react-icons/fa';

const Tasks = ({ setView, taskData }: any) => {
  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-6'>
      <div className='col-span-1 border-2 rounded-lg flex flex-col justify-between'>
        <div className='p-4 '>
          <div className='flex justify-between'>
            <p className='font-semibold mb-4 text-lg'>Task - 1 </p>
            <div>
              {taskData?.task1 && <FaCheck className='text-primary text-lg' />}
            </div>
          </div>
        </div>
        <div className='bg-primary-light p-3 hover:text-white hover:bg-primary transition ease-in-out duration-300'>
          <button onClick={() => setView('TASK1')} className='ml-2 w-full'>
            See Task
          </button>
        </div>
      </div>
      <div className='col-span-1 border-2 rounded-lg flex flex-col justify-between'>
        <div className='p-4 '>
          <div className='flex justify-between'>
            <p className='font-semibold mb-4 text-lg'>Task - 2 </p>
            <div>
              {taskData?.task2 && <FaCheck className='text-primary text-lg' />}
            </div>
          </div>
        </div>
        <div className='bg-primary-light p-3 hover:text-white hover:bg-primary transition ease-in-out duration-300'>
          <button onClick={() => setView('TASK2')} className='ml-2 w-full'>
            See Task
          </button>
        </div>
      </div>
    </div>
  );
};

export default Tasks;
