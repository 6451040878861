import BelongScore from './BelongScore';
import Employers from './Employers';
import SliderDomainImages from './score/SliderDomainImages';
import SliderScoreImages from './score/SliderScoreImages';
import { Link, Element } from 'react-scroll';

const AboutSection = () => {
  return (
    <>
      <div className='grid grid-cols-12 mb-8 lg:mb-0 mt-24 lg:mt-32 '>
        <div className='col-span-12 md:col-start-2 md:col-span-4 lg:col-start-3 lg:col-span-3  order-2 md:order-1 '>
          <SliderScoreImages />
        </div>
        <div className='col-span-12 md:col-span-5 md:col-start-7 lg:col-span-4 lg:col-start-7 order-1 md:order-2 grid '>
          <div>
            <h1 className='text-textColor-main font-semibold lg:text-3xl text-2xl lg:leading-[45px]'>
              <span className='text-[#10ADB9]'>Belong Score</span> is your{' '}
              <br /> New Resume
            </h1>
            <p className='lg:text-xl text-lg  text-textColor-lighter my-6'>
              Showcase your professional capabilities to employers With your
              <Link
                className='hover:text-primary italic hover:cursor-pointer md:font-semibold transition-all ease-in-out duration-300'
                activeClass='active'
                to='score-section'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                {' '}
                Belong Score{' '}
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-12'>
        <div className='col-span-12 md:col-start-2 md:col-span-5 lg:col-start-3 lg:col-span-4'>
          <div>
            <h1 className='text-textColor-main font-semibold lg:text-3xl text-2xl lg:leading-[45px]'>
              Showcase Your Skills with
              <span className='text-[#10ADB9]'> Belong Score </span>To Get Hired
            </h1>
            <p className='lg:text-xl text-lg text-textColor-lighter my-6'>
              Build employability proof with industry led projects and Get hired
              based on skills and not tags
            </p>
          </div>
        </div>
        <div className='col-span-12  md:col-span-5 md:col-start-8 lg:col-span-3 lg:col-start-8'>
          <SliderDomainImages />
        </div>
      </div>
      <Employers />
      <Element name='score-section' className='element'>
        <BelongScore />
      </Element>
    </>
  );
};

export default AboutSection;
