import React from 'react';
import Chart from 'react-apexcharts';

const IndividualScoreChart = ({ score }) => {
  const series = [100 - score, score];
  const options = {
    colors: ['#FEE3EA', '#C2FFFB'],
    chart: {
      type: 'donut',
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          size: '75%',
          labels: {
            show: true,
            total: {
              label: `${score}%`,
              fontSize: '18px',
              showAlways: true,
              show: true,
              formatter: function () {
                return '';
              },
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };
  return <Chart options={options} series={series} type='donut' width='160px' />;
};

export default IndividualScoreChart;
