import TextEditorQuill from 'components/capstone/capstoneView/taskView/TextEditorQuill';
import htmlparser from 'html-react-parser';
import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { supabase } from 'config/supabaseCOnfig';
import { format } from 'date-fns';
import { useGetUserQuery } from 'components/profile/userSlice';
import PeersEvaluate from './PeersEvaluate';
import FeedbackSection from './FeedbackSection';
import CriteriaSection from './CriteriaSection';

type MatchObject = {
  assignment_id: string; // Assuming the type is string, adjust if needed
  user_id?: string; // Optional user_id property
  team_id?: string; // Optional team_id property
};

type SubmissionData = {
  assignment_id: string; // Assuming the type is string, adjust if needed
  solution: string; // Assuming the type is string, adjust if needed
  user_id?: string; // Optional user_id property
  team_id?: string; // Optional team_id property
};

const QuillSection = ({
  assignment,
  setReFetch,
  setTaskView,
  setEvaluateAssignmentData,
}: {
  assignment: any;
  setReFetch: any;
  setTaskView: any;
  setEvaluateAssignmentData: any;
}) => {
  const [userSolution, setUserSolution] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [teamData, setTeamData] = useState<any>(null);

  const teamId = teamData?.id;
  const assignmentId = assignment.id;

  const { data: userData } = useGetUserQuery();
  const userId = userData?._id;

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('team')
        .select('users, id')
        .match({ bootcamp_id: 2 });
      const myTeam = fetchedData?.find(({ users }) => users.includes(userId));
      setTeamData(myTeam);
    };
    setDataFn();
  }, [userId]);

  const submitSolution = async () => {
    setLoading(true);
    const matchObj: MatchObject = { assignment_id: assignmentId };
    if (assignment.task_no === 1) {
      matchObj.user_id = userId;
    } else {
      matchObj.team_id = teamId;
    }
    const { data, status } = await supabase
      .from('assignment_submissions')
      .select()
      .match(matchObj);
    if (status === 200 && data?.length) {
      await supabase
        .from('assignment_submissions')
        .update({ solution: userSolution })
        .match({ id: data[0]?.id });
    }
    if (status === 200 && !data?.length) {
      const subData: SubmissionData = {
        assignment_id: assignmentId,
        solution: userSolution,
      };
      if (assignment.task_no === 1) {
        subData.user_id = userId;
      } else {
        subData.team_id = teamId;
      }
      await supabase.from('assignment_submissions').insert(subData);
    }
    setReFetch((pre: any) => !pre);
    setLoading(false);
    setTaskView(null);
  };
  return (
    <div className='mt-4 relative'>
      <p className='font-semibold text-lg'>Task Solution</p>
      {/* show submission here when exists */}
      {assignment.teamSubmission ? (
        <div className='px-4 prose prose-sm max-w-none '>
          {htmlparser(assignment.teamSubmission.solution)}
        </div>
      ) : (
        <div>
          <p className='text-primary font-semibold my-8 md:my-0 absolute top-0   md:right-0'>
            Due Date:{' '}
            {assignment.deadline &&
              format(new Date(assignment.deadline), 'PPpp')}
          </p>
          <div className='mt-10 md:mt-0'>
            <TextEditorQuill
              initialtext={''}
              setUserSolution={setUserSolution}
            />
          </div>{' '}
          {/* button removed IF submission exists */}
          <div className='text-center  mt-16'>
            <button
              onClick={() => setIsOpen(true)}
              // onClick={() => submitTaskFn()}
              className='px-8 py-1 bg-primary-light rounded-lg mt-4'
              disabled={loading}
            >
              {loading ? 'Loading' : 'Submit'}
            </button>
            <Dialog
              open={isOpen}
              onClose={() => setIsOpen(false)}
              as='div'
              className='relative z-10'
            >
              <div className='fixed top-1/3  md:left-1/3 lg:left-1/2 overflow-y-auto overflow-x-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <div>
                    <Dialog.Panel className='rounded bg-white m-4 p-4'>
                      <div className='text-left w-72 sm:w-96'>
                        <div className='font-bold'>
                          Are you sure you want to submit the task?
                        </div>
                        <div className='mt-4'>
                          You can not make any changes in your task once you
                          have submitted the task
                        </div>
                        <div className='mt-6 px-2 md:px-4 py-3 flex justify-around md:justify-end'>
                          <button
                            type='button'
                            className='w-1/3 lg:w-1/2   text-center rounded-md bg-red-500 px-2 lg:px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm'
                            onClick={() => {
                              setIsOpen(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type='button'
                            className='w-1/3 lg:w-1/2   text-center rounded-md border border-gray-300 bg-primary-light px-2 lg:px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                            onClick={() => {
                              submitSolution();
                              setIsOpen(false);
                              setLoading(false);
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      )}
      {assignment?.teamSubmission?.score ? (
        <FeedbackSection score={assignment?.teamSubmission?.score} />
      ) : (
        <CriteriaSection />
      )}
      {assignment.task_no !== 1 && (
        <PeersEvaluate
          assignment={assignment}
          teamId={teamId}
          setEvaluateAssignmentData={setEvaluateAssignmentData}
        />
      )}
    </div>
  );
};

export default QuillSection;
