import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { supabase } from 'config/supabaseCOnfig';

const TaskTwo = ({ setView, teamData }: any) => {
  const teamId = teamData.id;
  const [link, setLink] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const setDataFn = async () => {
      const { data, status } = await supabase
        .from('hackathon')
        .select()
        .match({ team_id: teamId });
      if (status === 200 && data?.length) {
        setLink(data[0]?.task2 || '');
      }
    };
    setDataFn();
  }, [teamId]);

  const submitFn = async () => {
    setLoading(true);
    setMessage('');

    const { data, status } = await supabase
      .from('hackathon')
      .select()
      .match({ team_id: teamId });
    if (status === 200 && data?.length) {
      const hackathonId = data[0].id;
      const updateData = {
        task2: link,
      };
      const { error } = await supabase
        .from('hackathon')
        .update(updateData)
        .match({ id: hackathonId });
      if (!error) {
        setMessage('Task updated successfully');
      }
    }
    if (status === 200 && !data?.length) {
      const hackathonData = {
        team_id: teamId,
        bootcamp_id: 2,
        task2: link,
      };
      const { error } = await supabase.from('hackathon').insert(hackathonData);

      if (error) {
        setMessage('Something went wrong!');
      } else {
        setMessage('Task done successfully');
      }
    }

    setLoading(false);
  };
  return (
    <div className='m-2'>
      <button
        className='px-4 py-2 bg-primary-light rounded-xl '
        onClick={() => setView('FULL')}
      >
        Back
      </button>
      <div className='flex justify-between'>
        <div className='font-bold text-xl'>
          Task - 2 Exploratory Data Analysis and Data visualisation
        </div>
        <div className='px-4 py-2 bg-primary-lightest rounded-xl'>Python</div>
      </div>
      <div className='mt-5'>
        <div className='font-semibold text-lg'>Background Description:</div>
        <div className='mt-2'>
          The second task is to perform an exploratory data analysis of the EV
          registration dataset. This task involves visualising the data,
          identifying patterns, trends, and relationships between variables, and
          using descriptive statistics to summarise the data. The goal is to
          gain a deeper understanding of the data and to validate assumptions,
          detect outliers and other anomalies, and uncover interesting insights.
        </div>
      </div>
      <div className='mt-5'>
        <div className='font-semibold text-lg'>Task Instructions - </div>
        <div className='mt-2'>
          <ul className='list-disc px-6 font-semibold'>
            <li>
              Conduct comprehensive exploratory data analysis (EDA) to gain deep
              insights into the dataset.
            </li>
            <li>
              Utilise descriptive Statistics to understand the dataset’s key
              features and distributions.
            </li>
            <li>
              Identify patterns, trends and test correlation relationships
              between variables.
            </li>
            <li>
              Validate assumptions using hypothesis testing and detect outliers
              and other anomalies in the data
            </li>
            <li>Create visualisations to present the results of the EDA</li>
            <li>
              Create histograms, box plots and scatter plots to identify trends
              and anomalies.
            </li>
            <li>
              Analyse categorical features using pie charts and bar plots to
              understand the distribution.
            </li>
            <li>
              Apply chi-square tests to analyse association between categorical
              variables.
            </li>
            <li>
              Utilise inferential statistical methods such as t-tests to compare
              groups and analyse differences.
            </li>
            <li>
              Apply data transformation techniques to normalise skewed
              variables.
            </li>
            <li>
              Present the findings in a clear and concise manner using a
              presentation or report.
            </li>
            <li>
              Summarise the insights and recommendations on EV registration in
              different cities and other factors associated.
            </li>
            <li>
              Emphasise the impact and importance of the results and explain how
              they can be used to make informed decisions
            </li>
          </ul>
        </div>
      </div>
      <div className='mt-5'>
        <div className='font-semibold text-lg'>Submission Format:</div>
        <div className='mt-2'>
          <ul className='list-disc px-6 font-semibold'>
            <li>Upload your Colab link with view access OR</li>
            <li>Upload your file on the github and provide the link OR </li>
            <li>
              Upload the file on your drive and share the drive link on the
              dashboard.
            </li>
          </ul>
        </div>
      </div>
      <div className='mt-5'>
        <div className='font-semibold text-lg'>Resources:</div>
        <div className='mt-2'>
          <a
            href='https://towardsdatascience.com/exploratory-data-analysis-in-python-a-step-by-step-process-d0dfa6bf94ee'
            target='_blank'
            rel='noreferrer'
            className='mr-4 hover:underline text-blue-500 '
          >
            https://towardsdatascience.com/exploratory-data-analysis-in-python-a-step-by-step-process-d0dfa6bf94ee
          </a>
        </div>
        <div className='mt-2'>
          <a
            href='https://www.youtube.com/watch?v=-o3AxdVcUtQ'
            target='_blank'
            rel='noreferrer'
            className='mr-4 hover:underline text-blue-500 '
          >
            https://www.youtube.com/watch?v=-o3AxdVcUtQ
          </a>
        </div>
        <div className='mt-2'>
          <a
            href='https://towardsdatascience.com/hypothesis-testing-with-python-step-by-step-hands-on-tutorial-with-practical-examples-e805975ea96e'
            target='_blank'
            rel='noreferrer'
            className='mr-4 hover:underline text-blue-500 '
          >
            https://towardsdatascience.com/hypothesis-testing-with-python-step-by-step-hands-on-tutorial-with-practical-examples-e805975ea96e
          </a>
        </div>
        <div className='mt-2'>
          <a
            href='https://www.youtube.com/watch?v=_YWwU-gJI5U'
            target='_blank'
            rel='noreferrer'
            className='mr-4 hover:underline text-blue-500 '
          >
            https://www.youtube.com/watch?v=_YWwU-gJI5U
          </a>
        </div>
        <div className='mt-2'>
          <a
            href='https://gilberttanner.com/blog/introduction-to-data-visualization-inpython/'
            target='_blank'
            rel='noreferrer'
            className='mr-4 hover:underline text-blue-500 '
          >
            https://gilberttanner.com/blog/introduction-to-data-visualization-inpython/
          </a>
        </div>
      </div>
      <div className='my-4 w-full'>
        <input
          className='focus:outline-none w-full sm:w-1/2 border-2 border-black  rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white mt-2'
          value={link}
          placeholder='Colab Link'
          onChange={(e) => setLink(e.target.value)}
        />
        <p className='text-xs text-red-500 mt-2'>
          {' '}
          {!link && 'Colab Link is Required'}{' '}
        </p>
      </div>

      <div className='text-center'>
        <button
          onClick={() => submitFn()}
          disabled={!link || isLoading}
          className={classNames(
            'mt-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0',
            !link ? 'opacity-50' : ''
          )}
        >
          {isLoading ? 'Processing...' : 'Submit'}
        </button>
        <div className='mt-2'> {message} </div>
      </div>
    </div>
  );
};

export default TaskTwo;
