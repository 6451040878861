// import Locked from 'components/bootcamp-analytic/Locked';
import Unlocked from 'components/bootcamp-analytic/Unlocked';
import Loading from 'components/global/layout/Loading';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from 'config/API_LINKS';

const AdvanceLevel = () => {
  // const [locked, setLocked] = useState(true);

  const [capstoneData, setCapstoneData] = useState<any>([]);

  useEffect(() => {
    const capstoneIds = [
      '64d06de1526d180014ad617c',
      '64d91030e0ae3200145c3b8f',
    ];
    const fetchData = async () => {
      const data = await Promise.all(
        capstoneIds.map(async (capstoneId: any) => {
          const capstone = await axios(API_URL + '/capstone', {
            params: { capstoneId },
          });
          return capstone?.data?.data[0];
        })
      );
      setCapstoneData(data);
    };

    fetchData();
  }, []);

  return (
    <div>
      {capstoneData.length > 0 ? (
        <Unlocked capstoneData={capstoneData} />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default AdvanceLevel;
