import React from 'react';
import EntrepreneurialLab from 'components/entrepreneurialLab';

const Index = () => {
  return (
    <div>
      <EntrepreneurialLab />
    </div>
  );
};

export default Index;
