import { useState } from 'react';
import CodeEditorWindow from './editor/CodeEditorWindow';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { languageOptions } from '../../constants/languaageOptions';
import classNames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { defineTheme } from '../../lib/defineTheme';
// import useKeyPress from '../hooks/useKeyPress';
// import Footer from './Footer';
import OutputWindow from './editor/OutputWindow';
import CustomInput from './editor/CustomInput';
// import ThemeDropdown from './ThemeDropdown';
import LanguagesDropdown from './editor/LngugesDropdown';
import TestCode from './TestCode';

const javascriptDefault = `/**
* Problem: Binary Search: Search a sorted array for a target value.
*/

// Time: O(log n)
const binarySearch = (arr, target) => {
 return binarySearchHelper(arr, target, 0, arr.length - 1);
};

const binarySearchHelper = (arr, target, start, end) => {
 if (start > end) {
   return false;
 }
 let mid = Math.floor((start + end) / 2);
 if (arr[mid] === target) {
   return mid;
 }
 if (arr[mid] < target) {
   return binarySearchHelper(arr, target, mid + 1, end);
 }
 if (arr[mid] > target) {
   return binarySearchHelper(arr, target, start, mid - 1);
 }
};

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const target = 5;
console.log(binarySearch(arr, target));
`;

const Landing = ({ exercise }: { exercise: any }) => {
  const [code, setCode] = useState<any>();
  const [customInput, setCustomInput] = useState('');
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState<boolean>(false);
  // const [theme, setTheme] = useState('cobalt');
  const [language, setLanguage] = useState(languageOptions[37]);
  const [submitCode, setSubmitCode] = useState(false);
  const [codeRan, setCodeRan] = useState(false);
  const [submissionProcessing, setSubmissionProcessing] = useState(false);
  const [testCasesPassed, setTestCasesPassed] = useState(0);
  const [submissionDone, setSubmissionDone] = useState(false);

  //   const enterPress = useKeyPress('Enter');
  //   const ctrlPress = useKeyPress('Control');

  const onSelectChange = (sl: any) => {
    // console.log('selected Option...', sl);
    setLanguage(sl);
  };

  //   useEffect(() => {
  //     if (enterPress && ctrlPress) {
  //       console.log('enterPress', enterPress);
  //       console.log('ctrlPress', ctrlPress);
  //       handleCompile();
  //     }
  //   }, [ctrlPress, enterPress]);
  const onChange = (action: any, data: string) => {
    switch (action) {
      case 'code': {
        setCode(data);
        break;
      }
      default: {
        console.warn('case not handled!', action, data);
      }
    }
  };

  const handleCompile = () => {
    setCodeRan(false);
    setProcessing(true);
    const formData = {
      language_id: language.id,
      // encode source code in base64
      source_code: btoa(code),
      stdin: btoa(customInput),
    };

    const options: AxiosRequestConfig<any> = {
      method: 'POST' as Method,
      url: process.env.REACT_APP_RAPID_API_URL,
      params: { base64_encoded: 'true', fields: '*' },
      headers: {
        'content-type': 'application/json',
        'Content-Type': 'application/json',
        'X-RapidAPI-Host': process.env.REACT_APP_RAPID_API_HOST || '',
        'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY || '',
      },
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        const token = response.data.token;
        checkStatus(token);
      })
      .catch((err) => {
        const error = err.response ? err.response.data : err;
        // get error status
        const status = err?.response?.status;
        if (status === 429) {
          console.log('too many requests', status);
          showErrorToast(`Quota of requests exceeded for the Day!`, 10000);
        }
        setProcessing(false);
      });
  };

  const checkStatus = async (token: any) => {
    const options = {
      method: 'GET',
      url: process.env.REACT_APP_RAPID_API_URL + '/' + token,
      params: { base64_encoded: 'true', fields: '*' },
      headers: {
        'X-RapidAPI-Host': process.env.REACT_APP_RAPID_API_HOST,
        'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
      },
    };
    try {
      // @ts-ignore
      const response = await axios.request(options);
      const statusId = response.data.status?.id;
      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        setTimeout(() => {
          checkStatus(token);
        }, 2000);
        return;
      } else {
        setProcessing(false);
        setOutputDetails(response.data);
        showSuccessToast(`Compiled Successfully!`);
        if (statusId === 3) {
          setCodeRan(true);
        }

        return;
      }
    } catch (err) {
      setProcessing(false);
      showErrorToast();
    }
  };

  // function handleThemeChange(th: any) {
  //   const theme = th;
  //   console.log('theme...', theme);

  //   if (['light', 'vs-dark'].includes(theme.value)) {
  //     setTheme(theme);
  //   } else {
  //     defineTheme(theme.value).then((_) => setTheme(theme));
  //   }
  // }
  // useEffect(() => {
  //   defineTheme('oceanic-next').then((_) =>
  //     setTheme({ value: 'oceanic-next', label: 'Oceanic Next' })
  //   );
  // }, []);

  const showSuccessToast = (msg?: string) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorToast = (msg?: string, timer?: number) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: 'top-right',
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const [link, setLink] = useState('');
  const isLinkDone = exercise.link_required ? Boolean(link) : true;
  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className='h-4 w-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500'></div>
      <div className='flex flex-row'>
        <div className='px-4 py-2'>
          <LanguagesDropdown onSelectChange={onSelectChange} />
        </div>
        {/* <div className='px-4 py-2'>
          <ThemeDropdown handleThemeChange={handleThemeChange} theme={theme} />
        </div> */}
      </div>
      <div className='flex flex-row space-x-4 items-start px-4 py-4'>
        <div className='flex flex-col w-1/2 h-full justify-start items-end'>
          <CodeEditorWindow
            code={code}
            onChange={onChange}
            language={language?.value}
            theme='oceanic-next'
            // theme={theme.value}
            prewrittenCode={exercise.prewritten_code}
          />
        </div>

        <div className='right-container flex flex-shrink-0 w-1/2 flex-col'>
          <OutputWindow outputDetails={outputDetails} />
          <div className='flex flex-col items-end'>
            <CustomInput
              customInput={customInput}
              setCustomInput={setCustomInput}
            />
            {exercise.link_required && (
              <div className='my-4 w-full'>
                <input
                  className='focus:outline-none w-full border-2 border-black  rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white mt-2'
                  value={link}
                  placeholder='Colab Link'
                  onChange={(e) => setLink(e.target.value)}
                />
                <p className='text-xs text-red-500 mt-2'>
                  {' '}
                  {!isLinkDone && 'Colab Link is Required'}{' '}
                </p>
              </div>
            )}
            <div className='flex gap-6'>
              <button
                onClick={handleCompile}
                disabled={!code || processing}
                className={classNames(
                  'mt-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0',
                  !code ? 'opacity-50' : ''
                )}
              >
                {processing ? 'Processing...' : 'Run'}
              </button>
              <button
                onClick={() => {
                  setSubmitCode(true);
                }}
                disabled={
                  !code || submissionProcessing || !codeRan || !isLinkDone
                }
                className={classNames(
                  'mt-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0',
                  !code || !codeRan || !isLinkDone ? 'opacity-50' : ''
                )}
              >
                {submissionProcessing ? 'Processing...' : 'Submit'}
              </button>
            </div>
          </div>
          {submitCode && (
            <TestCode
              testCasesPassed={testCasesPassed}
              setTestCasesPassed={setTestCasesPassed}
              submitCode={submitCode}
              setSubmitCode={setSubmitCode}
              exercise={exercise}
              code={code}
              setProcessing={setSubmissionProcessing}
              outputDetails={outputDetails}
              link={link}
            />
          )}
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
export default Landing;
