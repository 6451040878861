import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import Loading from 'components/global/layout/Loading';
import { useGetUserQuery } from 'components/profile/userSlice';
import Category from './Category';
import { supabase } from 'config/supabaseCOnfig';
import { useState } from 'react';

const Index = () => {
  const { data: userData, isLoading } = useGetUserQuery();
  const userId = userData?._id;
  const [allBasicQuestionSolved, setAllBasicQuestionSolved] = useState(false);
  const [allIntermediateQuestionSolved, setAllIntermediateQuestionSolved] =
    useState(false);
  const [allAdvancedQuestionSolved, setAllAdvancedQuestionSolved] =
    useState(false);

  const basicQuestions = [2, 5, 3, 6, 45];
  const intermediateQuestions = [7, 9, 10, 11, 12];
  const advancedQuestions = [13, 22, 23, 24, 25];

  // functions for basic questions
  async function fetchData() {
    const { data } = await supabase
      .from('submissions')
      .select()
      .match({ user_id: userId, bootcamp_id: 2 })
      .in('exercise_id', basicQuestions);
    if (data && data.length === 5) {
      setAllBasicQuestionSolved(true);
    } else {
      setAllBasicQuestionSolved(false);
    }
  }

  if (userId) {
    fetchData();
  }

  // functions for Intermediate questions
  async function fetchIntermediateData() {
    const { data } = await supabase
      .from('submissions')
      .select()
      .match({ user_id: userId, bootcamp_id: 2 })
      .in('exercise_id', intermediateQuestions);
    if (data && data.length === 5) {
      setAllIntermediateQuestionSolved(true);
    } else {
      setAllIntermediateQuestionSolved(false);
    }
  }

  if (userId) {
    fetchIntermediateData();
  }

  // functions for Advanced questions
  async function fetchAdvancedData() {
    const { data } = await supabase
      .from('submissions')
      .select()
      .match({ user_id: userId, bootcamp_id: 2 })
      .in('exercise_id', advancedQuestions);
    if (data && data.length === 5) {
      setAllAdvancedQuestionSolved(true);
    } else {
      setAllAdvancedQuestionSolved(false);
    }
  }

  if (userId) {
    fetchAdvancedData();
  }

  const pythonLogo = (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='mx-auto'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M13.0164 2C10.8193 2 9.03825 3.72453 9.03825 5.85185V8.51852H15.9235V9.25926H5.97814C3.78107 9.25926 2 10.9838 2 13.1111V18.8889C2 21.0162 3.78107 22.7407 5.97814 22.7407H8.27322V19.4815C8.27322 17.3542 10.0543 15.6296 12.2514 15.6296H19.5956C21.4547 15.6296 22.9617 14.1704 22.9617 12.3704V5.85185C22.9617 3.72453 21.1807 2 18.9836 2H13.0164ZM12.0984 6.74074C12.8589 6.74074 13.4754 6.14378 13.4754 5.40741C13.4754 4.67103 12.8589 4.07407 12.0984 4.07407C11.3378 4.07407 10.7213 4.67103 10.7213 5.40741C10.7213 6.14378 11.3378 6.74074 12.0984 6.74074Z'
        fill='url(#paint0_linear_5700_9339)'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M18.9834 30.0005C21.1805 30.0005 22.9616 28.276 22.9616 26.1487V23.482H16.0763V22.7413H26.0217C28.2188 22.7413 29.9998 21.0167 29.9998 18.8894V13.1116C29.9998 10.9843 28.2188 9.25977 26.0217 9.25977H23.7266V12.519C23.7266 14.6464 21.9455 16.3709 19.7485 16.3709H12.4042C10.5451 16.3709 9.03809 17.8301 9.03809 19.6301V26.1487C9.03809 28.276 10.8192 30.0005 13.0162 30.0005H18.9834ZM19.9015 25.2598C19.1409 25.2598 18.5244 25.8567 18.5244 26.5931C18.5244 27.3295 19.1409 27.9264 19.9015 27.9264C20.662 27.9264 21.2785 27.3295 21.2785 26.5931C21.2785 25.8567 20.662 25.2598 19.9015 25.2598Z'
        fill='url(#paint1_linear_5700_9339)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_5700_9339'
          x1='12.4809'
          y1='2'
          x2='12.4809'
          y2='22.7407'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#327EBD' />
          <stop offset='1' stop-color='#1565A7' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_5700_9339'
          x1='19.519'
          y1='9.25977'
          x2='19.519'
          y2='30.0005'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FFDA4B' />
          <stop offset='1' stop-color='#F9C600' />
        </linearGradient>
      </defs>
    </svg>
  );

  const questions = [
    {
      question: 'Write a Python program that calculates the...',
      number: 2,
    },
    {
      question:
        'Write a Python program that determines whether a given number (accepted from the user...',
      number: 5,
    },
    {
      question:
        'Write a Python program to calculate the sum of three given numbers....',
      number: 3,
    },
    {
      question:
        'Write a Python program that accepts an integer (n) and computes the value of (n+n*n+n*n*n)',
      number: 6,
    },
    {
      question: 'Write a Python program to check the greatest of 3 numbers',
      number: 45,
    },
  ];
  const questionsIntermediate = [
    {
      question: 'Write a Python program to solve (x + y) * (x + y).',
      number: 7,
    },
    {
      question:
        'Write a python code to perform the following operations on two input numbers',
      number: 9,
    },
    {
      question:
        'Given an integer A, you need to find the count of its factors....',
      number: 10,
    },
    {
      question: 'Given a number A. Return 1 if A is prime and return 0 if not.',
      number: 11,
    },
    {
      question:
        ' Given a number A. Return square root of the number if it is perfect square otherwise return -1.',
      number: 12,
    },
  ];

  const questionsAdvanced = [
    {
      question:
        'You are given a number N, write a program that calculates the sum of it’s digits.',
      number: 13,
    },
    {
      question:
        'You are given an integer A. You have to tell whether it is a perfect number or not.',
      number: 22,
    },
    {
      question:
        ' You will be given an integer n. You need to return the count of prime numbers less than or equal to n',
      number: 23,
    },
    {
      question:
        'You are given an integer A. You need to print all the Armstrong Numbers between 1 to A.',
      number: 24,
    },
    {
      question:
        'Given two integers A and B. A represents the count of mangoes and B represent the count of slices of mangoes...',
      number: 25,
    },
  ];

  return (
    <div className='px-6'>
      <div className='flex justify-between'>
        <div>
          <p className='text-xl'>Python</p>
          <p className='text-gray-400 text-xs mt-2'>Your skill Badges</p>
        </div>
        <div>
          <a href='/Python.pdf' download>
            <button className='py-2 px-8 border-primary border text-sm rounded shadow text-primary'>
              <DocumentArrowDownIcon className='inline-block w-6 mr-2' />
              Resources
            </button>
          </a>
        </div>
      </div>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <div className='mt-8 flex gap-4'>
            {/* badge 1 */}
            <div>
              {allBasicQuestionSolved ? (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat object-shadow grid items-center'
                  style={{
                    backgroundImage:
                      'url("/images/skills/skill-bg-bronze.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    {pythonLogo}

                    <p className='text-center mt-2 font-semibold'>Python</p>
                    <img
                      src='/images/skills/stars-1.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              ) : (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat grayscale object-shadow grid items-center'
                  style={{
                    backgroundImage: 'url("/images/skills/skill-bg-0.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    {pythonLogo}

                    <p className='text-center mt-2 font-semibold'>Python</p>
                    <img
                      src='/images/skills/stars-1.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              )}
            </div>
            {/* badge 2 */}
            <div>
              {allIntermediateQuestionSolved ? (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat object-shadow grid items-center'
                  style={{
                    backgroundImage:
                      'url("/images/skills/skill-bg-silver.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    {pythonLogo}

                    <p className='text-center mt-2 font-semibold'>Python</p>
                    <img
                      src='/images/skills/stars-2.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              ) : (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat object-shadow grid items-center grayscale'
                  style={{
                    backgroundImage: 'url("/images/skills/skill-bg-0.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    <svg
                      width='32'
                      height='32'
                      viewBox='0 0 32 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className='mx-auto'
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M13.0164 2C10.8193 2 9.03825 3.72453 9.03825 5.85185V8.51852H15.9235V9.25926H5.97814C3.78107 9.25926 2 10.9838 2 13.1111V18.8889C2 21.0162 3.78107 22.7407 5.97814 22.7407H8.27322V19.4815C8.27322 17.3542 10.0543 15.6296 12.2514 15.6296H19.5956C21.4547 15.6296 22.9617 14.1704 22.9617 12.3704V5.85185C22.9617 3.72453 21.1807 2 18.9836 2H13.0164ZM12.0984 6.74074C12.8589 6.74074 13.4754 6.14378 13.4754 5.40741C13.4754 4.67103 12.8589 4.07407 12.0984 4.07407C11.3378 4.07407 10.7213 4.67103 10.7213 5.40741C10.7213 6.14378 11.3378 6.74074 12.0984 6.74074Z'
                        fill='url(#paint0_linear_5700_9339)'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M18.9834 30.0005C21.1805 30.0005 22.9616 28.276 22.9616 26.1487V23.482H16.0763V22.7413H26.0217C28.2188 22.7413 29.9998 21.0167 29.9998 18.8894V13.1116C29.9998 10.9843 28.2188 9.25977 26.0217 9.25977H23.7266V12.519C23.7266 14.6464 21.9455 16.3709 19.7485 16.3709H12.4042C10.5451 16.3709 9.03809 17.8301 9.03809 19.6301V26.1487C9.03809 28.276 10.8192 30.0005 13.0162 30.0005H18.9834ZM19.9015 25.2598C19.1409 25.2598 18.5244 25.8567 18.5244 26.5931C18.5244 27.3295 19.1409 27.9264 19.9015 27.9264C20.662 27.9264 21.2785 27.3295 21.2785 26.5931C21.2785 25.8567 20.662 25.2598 19.9015 25.2598Z'
                        fill='url(#paint1_linear_5700_9339)'
                      />
                      <defs>
                        <linearGradient
                          id='paint0_linear_5700_9339'
                          x1='12.4809'
                          y1='2'
                          x2='12.4809'
                          y2='22.7407'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stop-color='#327EBD' />
                          <stop offset='1' stop-color='#1565A7' />
                        </linearGradient>
                        <linearGradient
                          id='paint1_linear_5700_9339'
                          x1='19.519'
                          y1='9.25977'
                          x2='19.519'
                          y2='30.0005'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stop-color='#FFDA4B' />
                          <stop offset='1' stop-color='#F9C600' />
                        </linearGradient>
                      </defs>
                    </svg>
                    <p className='text-center mt-2 font-semibold'>Python</p>
                    <img
                      src='/images/skills/stars-1.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              )}
            </div>
            {/* badge 3 */}
            <div>
              {allAdvancedQuestionSolved ? (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat object-shadow grid items-center'
                  style={{
                    backgroundImage: 'url("/images/skills/skill-bg-gold.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    {pythonLogo}

                    <p className='text-center mt-2 font-semibold'>Python</p>
                    <img
                      src='/images/skills/stars-3.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              ) : (
                <div
                  className='h-48 w-48 bg-contain bg-no-repeat object-shadow grid items-center grayscale'
                  style={{
                    backgroundImage: 'url("/images/skills/skill-bg-0.png")',
                  }}
                >
                  <div className='w-[90%]'>
                    <svg
                      width='32'
                      height='32'
                      viewBox='0 0 32 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className='mx-auto'
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M13.0164 2C10.8193 2 9.03825 3.72453 9.03825 5.85185V8.51852H15.9235V9.25926H5.97814C3.78107 9.25926 2 10.9838 2 13.1111V18.8889C2 21.0162 3.78107 22.7407 5.97814 22.7407H8.27322V19.4815C8.27322 17.3542 10.0543 15.6296 12.2514 15.6296H19.5956C21.4547 15.6296 22.9617 14.1704 22.9617 12.3704V5.85185C22.9617 3.72453 21.1807 2 18.9836 2H13.0164ZM12.0984 6.74074C12.8589 6.74074 13.4754 6.14378 13.4754 5.40741C13.4754 4.67103 12.8589 4.07407 12.0984 4.07407C11.3378 4.07407 10.7213 4.67103 10.7213 5.40741C10.7213 6.14378 11.3378 6.74074 12.0984 6.74074Z'
                        fill='url(#paint0_linear_5700_9339)'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M18.9834 30.0005C21.1805 30.0005 22.9616 28.276 22.9616 26.1487V23.482H16.0763V22.7413H26.0217C28.2188 22.7413 29.9998 21.0167 29.9998 18.8894V13.1116C29.9998 10.9843 28.2188 9.25977 26.0217 9.25977H23.7266V12.519C23.7266 14.6464 21.9455 16.3709 19.7485 16.3709H12.4042C10.5451 16.3709 9.03809 17.8301 9.03809 19.6301V26.1487C9.03809 28.276 10.8192 30.0005 13.0162 30.0005H18.9834ZM19.9015 25.2598C19.1409 25.2598 18.5244 25.8567 18.5244 26.5931C18.5244 27.3295 19.1409 27.9264 19.9015 27.9264C20.662 27.9264 21.2785 27.3295 21.2785 26.5931C21.2785 25.8567 20.662 25.2598 19.9015 25.2598Z'
                        fill='url(#paint1_linear_5700_9339)'
                      />
                      <defs>
                        <linearGradient
                          id='paint0_linear_5700_9339'
                          x1='12.4809'
                          y1='2'
                          x2='12.4809'
                          y2='22.7407'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stop-color='#327EBD' />
                          <stop offset='1' stop-color='#1565A7' />
                        </linearGradient>
                        <linearGradient
                          id='paint1_linear_5700_9339'
                          x1='19.519'
                          y1='9.25977'
                          x2='19.519'
                          y2='30.0005'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stop-color='#FFDA4B' />
                          <stop offset='1' stop-color='#F9C600' />
                        </linearGradient>
                      </defs>
                    </svg>
                    <p className='text-center mt-2 font-semibold'>Python</p>
                    <img
                      src='/images/skills/stars-1.png'
                      alt='Stars'
                      className='mx-auto'
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <Category
              heading='Novice'
              questions={questions}
              locked={false}
              userId={userId}
            />
            <Category
              heading='Intermediate'
              questions={questionsIntermediate}
              locked={false}
              userId={userId}
            />
            <Category
              heading='Expert'
              questions={questionsAdvanced}
              locked={false}
              userId={userId}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Index;
