import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import MobileNavbar from './mobileNavBar/MobileNavbar';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import QuickTips from '../quick-tips';
import Footer from '../home/Footer';

const DashboardLayout = () => {
  const [showSidebar, setshowSidebar] = useState(false);

  return (
    <div className='p-2'>
      <div className='hidden md:block'>
        <Navbar />
      </div>
      <div className='md:hidden'>
        <MobileNavbar
          showSidebar={showSidebar}
          setshowSidebar={setshowSidebar}
        />
      </div>
      <div className='grid grid-cols-10 gap-8'>
        <div className='col-span-12 md:col-span-2 '>
          <Sidebar showSidebar={showSidebar} setshowSidebar={setshowSidebar} />
          <div className='mt-10 hidden  px-2'>
            <QuickTips />
          </div>
        </div>
        <div className='col-span-12 md:col-span-8'>
          <Outlet />
        </div>
      </div>
      <div className='mx-4 sm:mx-8 md:mx-12'>
        <Footer />
      </div>
    </div>
  );
};

export default DashboardLayout;
