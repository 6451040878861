// import Locked from 'components/bootcamp-analytic/Locked';
import Unlocked from 'components/bootcamp-analytic/Unlocked';
import Loading from 'components/global/layout/Loading';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from 'config/API_LINKS';

const BasicLevel = () => {
  // const [locked, setLocked] = useState(false);

  const [capstoneData, setCapstoneData] = useState<any>([]);

  useEffect(() => {
    const capstoneIds = [
      '64d06868526d180014ad5d3e',
      '64dc46fc7564a60014ea61e3',
    ];
    const fetchData = async () => {
      const data = await Promise.all(
        capstoneIds.map(async (capstoneId: any) => {
          const capstone = await axios(API_URL + '/capstone', {
            params: { capstoneId },
          });
          return capstone?.data?.data[0];
        })
      );
      setCapstoneData(data);
    };

    fetchData();
  }, []);

  return (
    <div>
      {/* {locked && <Locked />} */}

      {capstoneData.length > 0 ? (
        <Unlocked capstoneData={capstoneData} />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default BasicLevel;
