import React, { useState, useEffect } from 'react';

import CapstoneInfo from './CapstoneInfo';
import EnrolNow from './EnrolNow';
import AboutCompany from './AboutCompany';
import VideoSection from './VideoSection';
import ProjectBrief from './ProjectBrief';
import BusinessObjectives from './BusinessObjectives';
import Tasks from './Tasks';
import TaskView from './taskView';
import Dataset from './Dataset';

import QuizAndScore from './QuizAndScore';
import QuizView from './capstoneQuiz';
import { useGetCapstoneQuizQuery } from './capstoneQuiz/CapstoneQuizSlice';
import { useGetUserQuery } from 'components/profile/userSlice';
import { supabase } from 'config/supabaseCOnfig';

interface allProps {
  data: any;
}

const CheckUserStatus = (props: allProps) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [showQuiz, setShowQuiz] = useState(false);
  const [count, setCount] = useState(1);
  const capstoneId = props.data._id;

  const { data: userData } = useGetUserQuery();
  const userId = userData?._id;

  const [teamData, setTeamData] = useState<any>(null);
  const [enrollData, setEnrollData] = useState<any>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [reFetch, setReFetch] = useState(false);

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('team')
        .select('users, id')
        .match({ bootcamp_id: 2 });
      const myTeam = fetchedData?.find(({ users }) => users.includes(userId));
      setTeamData(myTeam);
    };
    setDataFn();
  }, [userId]);

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('capstone_submissions')
        .select()
        .match({ team_id: teamData.id, capstone_id: capstoneId });
      setEnrollData(
        fetchedData?.length ? fetchedData[0] : { capstone_id: capstoneId }
      );
      setIsSuccess(true);
    };
    if (teamData) {
      setDataFn();
    }
  }, [capstoneId, teamData, reFetch]);

  const { data: questionData } = useGetCapstoneQuizQuery({
    capstoneId: capstoneId,
  });

  return (
    <div>
      <div className='bg-gray-50 pb-4'>
        <CapstoneInfo data={props.data} />
        {showQuiz ? (
          <QuizView
            setShowQuiz={setShowQuiz}
            capstoneId={props.data._id}
            enrollmentId={enrollData?.id}
            enrollData={enrollData}
            setReFetch={setReFetch}
          />
        ) : selectedTask ? (
          <TaskView
            setSelectedTask={setSelectedTask}
            selectedTask={selectedTask}
            data={props.data}
            enrollData={enrollData}
            count={count}
            setReFetch={setReFetch}
            teamData={teamData}
          />
        ) : (
          teamData && (
            <div className='border border-primary-light mt-4 rounded-lg mx-2 sm:mx-4 px-1 sm:px-2 bg-white shadow'>
              {isSuccess && (
                <EnrolNow data={props.data} enrollData={enrollData} />
              )}
              <AboutCompany data={props.data} />
              <VideoSection data={props.data} />
              <ProjectBrief data={props.data} />
              <BusinessObjectives data={props.data} />
              <Dataset data={props.data} />
              <Tasks
                data={props.data}
                setSelectedTask={setSelectedTask}
                enrollData={enrollData}
                setCount={setCount}
              />
              {questionData && questionData.length && (
                <QuizAndScore
                  setShowQuiz={setShowQuiz}
                  enrollData={enrollData}
                  capstoneData={props.data}
                  totalQuestions={questionData.length}
                />
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default CheckUserStatus;
