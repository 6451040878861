import { FaLinkedinIn } from 'react-icons/fa';
import { RiInstagramFill } from 'react-icons/ri';
import { BsTwitter } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='lg:mt-16 mt-8 bg-gray-50 -mx-4 p-4 md:p-0 md:bg-white'>
      <div className='border-t-2 border-textColor'></div>
      <div className='lg:px-12 grid grid-cols-12 mb-10'>
        <div className='lg:col-span-5 col-span-12'>
          <div className='flex space-x-4 items-center my-6'>
            <img
              className='object-contain h-12'
              src='https://res.cloudinary.com/belong/image/upload/v1643351815/uploaded_resources/logo-small_mtbkf0.png'
              alt='logo'
            />
            <p className='lg:text-3xl text-xl lg:font-semibold font-medium text-primary '>
              Belong
            </p>
          </div>
          <p className='mb-4'>India’s largest industry experience platform</p>
          <p>
            Belong Education is an employability platform that facilitates
            interaction between students and employers through
            industry-integrated work experience opportunities. {' '}
          </p>
          {/* <p className='lg:font-semibold font-medium lg:text-2xl text-lg text-secondary my-8'>
            Connect with us
          </p> */}
        </div>
        <div className='lg:col-span-7 col-span-12 flex lg:justify-around justify-between lg:mt-16 mt-8 lg:font-medium font-normal text-lg lg:leading-modifiedHeight leading-9'>
          <div>
            <div>
              <Link to='/about-us' target='_blank' rel='noopener noreferrer'>
                About Us
              </Link>
            </div>
            <div>
              <Link to='/contact' target='_blank' rel='noopener noreferrer'>
                Contact Us
              </Link>
            </div>
            <div className='flex space-x-4 text-primary lg:text-3xl  text-xl mt-6'>
              <div>
                <a
                  href='https://www.linkedin.com/school/ingeniousfaces/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaLinkedinIn />
                </a>
              </div>
              <div>
                <a
                  href='https://www.instagram.com/ingeniousfaces/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <RiInstagramFill />
                </a>
              </div>
              <div>
                <a
                  href='https://twitter.com/IngeniousFaces'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <BsTwitter />
                </a>
              </div>
            </div>
          </div>
          <div>
            <div>
              <Link
                to='/privacy-policy'
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy
              </Link>
            </div>
            <div>
              <Link
                to='/terms-&-conditions'
                target='_blank'
                rel='noopener noreferrer'
              >
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='border-t-2 border-textColor'></div>
      <div className='lg:text-lg lg:my-8 my-4'>
        <p>
          &copy; Belong. All Rights Reserved.{' '}
          <span>{new Date().getFullYear()}</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
