import React from 'react';

const BootcampScore = () => {
  return (
    <div>
      <p className='mb-2 text-2xl font-medium'> Bootcamp Score</p>
      <p className='text-[#B0B0B0] font-semibold mb-4'>
        Your Bootcamp Score is calculated based on the following components{' '}
      </p>
      <div className='my-6 border rounded-md shadow-md md:w-[90%] p-4 md:p-8 md:flex flex-col md:flex-row gap-6 md:gap-12'>
        {/* box 1 */}
        <div className='basis-1/2 border rounded-md p-4'>
          <div className='flex gap-4'>
            {/* left side */}
            <div className='basis-3/5 space-y-4'>
              <p className='text-primary font-medium'>TECH (50 Marks)</p>
              <div className='space-y-2 border-r-2'>
                <p>Capstone Tasks</p>
                <p>Capstone Quiz</p>
                <p>Hackathon Challenges</p>
                <p>Practice Questions</p>
              </div>
            </div>
            {/* right side */}
            <div className='basis-2/5 space-y-4'>
              <p className='text-primary font-medium'>DIVISION</p>
              <div className='space-y-2'>
                <p>10</p>
                <p>10</p>
                <p>25</p>
                <p>5</p>
              </div>
            </div>
          </div>
        </div>
        {/* box 2 */}
        <div className='basis-1/2 border rounded-md p-4'>
          <div className='flex gap-4 '>
            {/* left side */}
            <div className='basis-3/5 space-y-4'>
              <p className='text-primary font-medium'>NON - TECH (50 Marks)</p>
              <div className='space-y-2 border-r-2'>
                <p>Entrepreneurial Assignments</p>
                <p>Basic Concepts</p>
                <p>Design Thinking</p>
                <p>Fish Tank (Pitch Score)</p>
              </div>
            </div>
            {/* right side */}
            <div className='basis-2/5 space-y-4'>
              <p className='text-primary font-medium'>DIVISION</p>
              <div className='space-y-2'>
                <p> </p>
                <p>25</p>
                <p> </p>
                <p>25</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BootcampScore;
