import { apiSlice } from '../../api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCapstones: builder.query<any, any>({
      query: (params) => {
        return {
          url: '/capstone/bootcamp',
          params: params,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['AllCapstones'],
    }),
    getCapstone: builder.query<any, any>({
      query: (params) => {
        return {
          url: '/capstone',
          params: params,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['Capstone'],
    }),
    getFilter: builder.query<any, void>({
      query: () => {
        return {
          url: '/capstone/filter-data',
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['CapstoneFilter'],
    }),
    getMyCapstones: builder.query<any, any>({
      query: (params) => {
        return {
          url: '/enrollments',
          params: params,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['MyCapstone'],
    }),
    updateEnrollments: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: '/enrollments',
          method: 'POST',
          body: params,
        };
      },
      transformResponse: (res: any) => res.data,
      invalidatesTags: ['AllCapstones', 'MyCapstone', 'CurrentCapstone'],
    }),
    updateSubmissions: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: '/enrollments/capstone/submission',
          method: 'POST',
          body: params,
        };
      },
      transformResponse: (res: any) => res.data,
      invalidatesTags: ['MyCapstone', 'CurrentCapstone', 'Capstone'],
    }),
    addFeedback: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: '/enrollments/feedback',
          method: 'PUT',
          body: params,
        };
      },
      transformResponse: (res: any) => res.data,
      invalidatesTags: ['CurrentCapstone'],
    }),
  }),
});

export const {
  useGetCapstoneQuery,
  useGetCapstonesQuery,
  useGetFilterQuery,
  useGetMyCapstonesQuery,
  useUpdateEnrollmentsMutation,
  useUpdateSubmissionsMutation,
  useAddFeedbackMutation,
} = extendedApiSlice;
