import { FaPencilRuler, FaRegClock, FaTasks } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Info = () => {
  return (
    <div className='px-2 md:px-4 border-b border-gray-400 bg-gradient-to-t from-gray-100 to-primary-lightest'>
      <div className='flex justify-between '>
        <div>
          <div className='flex text text-sm mt-4'>
            <div>
              <Link to='/' className=' hover:text-blue-700'>
                Home
              </Link>
            </div>
            <div>
              <span className=' mx-2'>{`>`}</span>
            </div>
          </div>
          <div className='mt-4'>
            <div className='font-semibold md:text-xl lg:text-3xl'>
              EV Registration Analysis
            </div>
          </div>
          <div className='mt-4 flex '>
            <div>
              <div className='flex font-semibold text-gray-600'>
                <FaRegClock className='mt-1 mr-2' />
                <p> 3 hours</p>
              </div>
              <div className='flex font-semibold text-gray-600 mt-1'>
                <FaPencilRuler className='mt-1 mr-2' />
                <p> 4 Skills </p>
              </div>
            </div>
            <div className='ml-8'>
              <div className='flex font-semibold text-gray-600'>
                <FaTasks className='mt-1 mr-2' />
                <p> 2 Tasks </p>
              </div>
              <div className='inline-flex font-semibold space-x-1  mt-1'>
                <img
                  src='https://res.cloudinary.com/belong/image/upload/v1661953024/capstone/image_285_cyfenz.png'
                  alt='basic'
                />
                <p className='text-gray-600'> Intermediate </p>
              </div>
            </div>
          </div>
        </div>
        <div className='grid grid-flow-col  '>
          <div>
            <div className='text-center text-primary md:font-semibold my-14 md:mr-10 bg-white border border-primary px-2 sm:px-4 py-1 rounded-2xl'>
              Data science
            </div>
          </div>

          <img
            src='https://res.cloudinary.com/belong/image/upload/v1663165873/uploaded_resources/Numadic_nd9rjf.jpg'
            alt='company'
            className='mx-auto mt-4 h-20 md:mr-8 mb-8 hidden sm:block'
          />
        </div>
      </div>
    </div>
  );
};

export default Info;
