import { LockClosedIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Question from './Question';

const Category = ({
  heading,
  questions,
  locked,
  userId,
}: {
  heading: string;
  questions: any;
  locked: boolean;
  userId: string;
}) => {
  return (
    <div className='mb-8'>
      {locked && (
        <div className='mb-4 text-center'>
          <p className='bg-red-100 py-2 px-4 text-red-600 rounded inline-flex items-center'>
            <LockClosedIcon className='inline-block mr-4 w-6' />
            {heading} Level Questions Locked
          </p>
        </div>
      )}
      <p className='text-lg font-semibold mb-4'>{heading}</p>
      <div className={classNames('space-y-6', { 'blur-sm': locked })}>
        {questions.map(
          (abc: { question: string; number: number }, index: number) => {
            return (
              <>
                <div className='shadow p-4 text-gray-900 text-sm flex justify-between'>
                  <div className='inline-block'>
                    {index + 1}. {abc.question}
                  </div>
                  <Question number={abc.number} userId={userId} />
                </div>
              </>
            );
          }
        )}
      </div>
    </div>
  );
};

export default Category;
