import React, { useState } from 'react';
// import QueryCard from './QueryCard';
import QueryReply from './QueryReply';

const AllQueries = () => {
  const [isReplySelected, SetIsReplySelected] = useState(null);
  // const openTicket = data?.filter((ticket: any) => {
  //   return ticket.status === 'open';
  // });

  // const closedTicket = data?.filter((ticket: any) => {
  //   return ticket.status === 'closed';
  // });
  return (
    <>
      {isReplySelected ? (
        <QueryReply
          SetIsReplySelected={SetIsReplySelected}
          ticket={isReplySelected}
        />
      ) : (
        <>
          <div className='mt-4 md:mt-8 mb-4 grid  grid-cols-6 lg:grid-cols-12 gap-8 text-center text-primary font-semibold '>
            <div className='col-span-6'>
              <div className='p-1 border-r-2 text-center  border rounded mb-2'>
                <p>Ongoing</p>
              </div>{' '}
              {/* {data.length === 0 && (
                <p className='font-normal'>No queries to show</p>
              )} */}
              {/* <QueryCard
                SetIsReplySelected={SetIsReplySelected}
                data={openTicket}
              /> */}
            </div>
            <div className='col-span-6'>
              <div className='p-1 border-r-2 text-center border rounded mb-2'>
                <p>Resolved</p>
              </div>{' '}
              {/* {data.length === 0 && (
                <p className='font-normal'>No queries to show</p>
              )} */}
              {/* <QueryCard
                SetIsReplySelected={SetIsReplySelected}
                data={closedTicket}
              /> */}
            </div>
          </div>
          {/* 
          <QueryCard SetIsReplySelected={SetIsReplySelected} data={data} /> */}
        </>
      )}
    </>
  );
};

export default AllQueries;
