import { useGetUserQuery } from 'components/profile/userSlice';
// import { useState, Fragment } from 'react';
import { FaLock } from 'react-icons/fa';
// import CapstoneCertificate from './CapstoneCertificate';
// import { Dialog, Transition } from '@headlessui/react';
// import Feedback from 'components/capstone/feedback';
// import BootcampCertificate from './BootcampCertificate';

interface allProps {
  setShowQuiz: any;
  enrollData: any;
  capstoneData: any;
}

// function diff_hours(dt2: any, dt1: any) {
//   let diff = (dt2.getTime() - dt1.getTime()) / 1000;
//   diff /= 60 * 60;
//   return Math.round(diff);
// }

const QuizStatus = (props: allProps) => {
  const enrollData = props.enrollData;
  const capstoneData = props.capstoneData;

  const { data: userData } = useGetUserQuery();
  const userId = userData?._id;
  const userQuizData = enrollData?.quiz_submission?.find(
    (quiz: any) => quiz.user_id === userId
  );

  function checkQuizConditions() {
    const isLocked =
      !enrollData ||
      capstoneData?.tasks?.length > (enrollData?.task_submissions?.length || 0);
    const isQuizDone = userQuizData?.finish_time;

    // const totalAttempts = enrollData?.totalAttempt || 0;

    if (isLocked) {
      return (
        <>
          <button
            disabled
            className='rounded font-semibold border text-white bg-primary px-10 py-2'
          >
            <FaLock className='text-lg' />
          </button>
          <p className='text-sm text-primary mt-1'>
            Unlocks post task submissions
          </p>
        </>
      );
    }
    if (!isQuizDone) {
      return (
        <>
          <button
            onClick={() => props.setShowQuiz(true)}
            className='rounded  font-semibold border text-white bg-primary  px-10 py-1'
          >
            {`Start Quiz`}
          </button>
          {/* <div className='text-xs mt-1'>
            Total Attempts: {totalAttempts + 1} / 2
          </div> */}
        </>
      );
    }

    if (isQuizDone) {
      return (
        <button
          onClick={() => {}}
          className='text-white bg-primary px-8 py-1 my-4 rounded cursor-pointer'
        >
          Score : {userQuizData?.percentage} %
        </button>
      );
    }
  }

  return <>{checkQuizConditions()}</>;
};

export default QuizStatus;
